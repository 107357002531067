import '@/styles/pages/Portfolio/DetailsPreview.scss';

import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import BackButton from '@/components/common/Button/BackButton';
import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import * as Page from '@/components/common/Page';
import {
    MainQuestions, Player, PreviewHeader, Publish, SideQuestions, WorksModule, WorksPreviewImage, ActionHeader
} from '@/components/common/Portfolio/';
import { PortfolioPDFDownloadButton } from '@/components/common/Portfolio/PDFDownloadButton';
import { PreviewFooter } from '@/components/common/Preview/Footer';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import * as AdminDataLayerProvider from '@/componentsDirect/common/DataLayerProviderContainer';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { usePortfolioUser } from '@/hooks/usePortfolioUser';
import Error from '@/pages/Error';
import { Password } from '@/pages/Portfolio';
import { PortfolioItem, PortfolioPage } from '@/utils/api-client';

interface RouteParams {
  matchbox_id: string;
}

function DetailsPreview(props: {
  presentation?: boolean;
  direct?: boolean;
  admin?: boolean;
  demoId?: string;
}): React.ReactElement {
  const { matchbox_id } = useParams<RouteParams>();
  const type: 'direct' | 'admin' | 'public' = props.direct
    ? 'direct'
    : props.admin
    ? 'admin'
    : 'public';
  const params: { user_id: string; work_id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const imgRef = useRef<HTMLDivElement>(null);
  const embedRef = useRef<HTMLDivElement>(null);
  const [otherPortfolio, setOtherPortfolio] = useState<PortfolioItem[]>();
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const { userId, portfolio, setPortfolio, errorCode, productPath, getPortfolioWithPassword } = usePortfolioUser(
    props.presentation,
    type
  );
  const [imageData, setImageData] = useState<PortfolioPage>();
  const mq = useMBXMediaQuery();
  const { push: adminPush } = useContext(AdminDataLayerProvider.DataLayerContext);
  const { push } = useContext(DataLayerContext);
  const linkToEdit = () => history.push(`/mypage/portfolio/${params.user_id}/${params.work_id}/edit`);

  useEffect(() => {
    if (portfolio) {
      findPortfolio(portfolio?.items);
      otherWorks(portfolio?.items);
    }
  }, [portfolio, params.work_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.work_id]);

  const findPortfolio = (data: Set<PortfolioItem> | undefined) => {
    if (data) {
      const p = Array.from(data.values()).find((item) => item.i_id === Number(params.work_id));
      if (p?.i_pages) {
        setImageData(
          Array.from(p?.i_pages.values()).filter((p) => p.p_page_layout !== 'questions')[0]
        );
      }
    }
  };

  const portfolioItem = useMemo(() => {
    if (!portfolio?.items) return;
    const p = Array.from(portfolio.items.values()).find(
      (item) => item.i_id === Number(params.work_id)
    );
    return p;
  }, [portfolio, params]);

  const publish = useMemo(() => {
    return portfolio?.publish ? true : false;
  }, [portfolio]);

  const otherWorks = (data: Set<PortfolioItem> | undefined) => {
    if (data) {
      const pf = Array.from(data.values()).filter((item) => item.i_id !== Number(params.work_id));
      if (props.direct || props.admin) return setOtherPortfolio(pf);
      return setOtherPortfolio(pf.filter((p) => p.i_visible));
    }
  };

  const height = useMemo(() => {
    if (imgRef.current) {
      const { clientWidth } = imgRef.current;
      if (clientWidth > 0) {
        return (clientWidth / 16) * 9;
      }
    }
    return ((mq.sm ? window.innerWidth : 960) / 16) * 9;
  }, [portfolio, params.work_id, imgRef]);

  const embedWidth = useMemo(() => {
    if (embedRef.current) {
      const { clientWidth } = embedRef.current;
      return clientWidth;
    }
    return mq.sm ? window.innerWidth : 960;
  }, [portfolio, params.work_id, embedRef, mq]);

  const embedHeight = useMemo(() => {
    if (embedRef.current) {
      const { clientWidth } = embedRef.current;
      if (clientWidth > 0) {
        return (clientWidth / 16) * 9;
      }
    }
    return ((mq.sm ? window.innerWidth : 960) / 16) * 9;
  }, [portfolio, params.work_id, embedRef, mq]);

  const linkToDetail = (id: number) => {
    if (!userId) return;
    let link = `/portfolio/${userId}/${id}/`;
    if (!props.presentation) {
      link = `/mypage/portfolio/${userId}/${id}/`;
    }
    if (props.direct) {
      link = `/direct/portfolio/${userId}/${id}/${props.demoId}/${location.search}`;
    }
    if (props.admin) {
      link = `/admin/portfolio/${userId}/${id}/`;
    }
    history.push(link);
  };

  useEffect(() => {
    if (type === 'public') {
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      });
    } else {
      adminPush({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      });
    }
  }, [params]);

  const previewHeader = useMemo(() => {
    if (props.direct) return <Link to={props.demoId? `${productPath}/${props.demoId}`: productPath}><h3>MATCHBOX ID：{userId}</h3></Link>;
    if (location.pathname.includes(`/admin/direct/portfolio/`)) {
      return <Link to={productPath}><h3>MATCHBOX ID：{matchbox_id}</h3></Link>;
    }
    if (portfolio?.page_title_logo?.f_url) {
      return <Link to={props.demoId? `${productPath}/${props.demoId}`: productPath}><img src={portfolio.page_title_logo.f_url} alt="" /></Link>;
    }
    if (portfolio?.page_title && portfolio?.page_title?.length !== 0) {
      return <Link to={props.demoId? `${productPath}/${props.demoId}`: productPath}><h3>{portfolio?.page_title}</h3></Link>;
    }
    return '';
  }, [portfolio]);

  // パスワード入力画面
  if (!portfolio?.items && errorCode === '3002' && props.presentation) {
    return <Password getPortfolio={getPortfolioWithPassword} />;
  }

  if (!portfolio?.items) {
    return <></>;
  }

  // 非公開時のビジュアル
  if (errorCode === '3001' || (portfolio?.status === 'closed' && !portfolio.items)) {
    return <Error {...props} />;
  }
  if (portfolio && !portfolioItem) {
    return <Error {...props} />;
  }

  //エラーコードが確定するまで画面は表示しない（不要なdataLayerが送信されるため）
  if (errorCode === null) {
    return <></>;
  }

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="details-preview">
      <Page.Wrapper
        presentation={props.presentation}
        disableDataLayerPush
        header={
          <>
            <div className="details-preview-header sticky top-0 z-10">
              {!props.presentation && (
                <>
                  <HeaderTitle title="プレビュー">
                    <div className="details-preview-wrapper" style={style}>
                      {portfolio?.updated_at && (
                        <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                      )}
                      <div className="ml-16 space-x-24 flex items-center sp_none">
                        <PortfolioPDFDownloadButton />
                        <div className="w-140 sm:w-88 flex">
                          <BaseButton
                            onClick={linkToEdit}
                            className="mbx-top-preview-edit-button w-full"
                            theme="secondary"
                            size="40"
                          >
                            編集する
                          </BaseButton>
                        </div>
                        <div className={cn(!publish ? 'w-186' : 'w-199', "sm:w-88 flex")}>
                          <BaseButton
                            iconName={"Publish"}
                            className="filter drop-shadow-floatingButton"
                            onClick={() => setPublishModal(true)}
                            size="40"
                            theme="primary"
                          >
                            {!publish ? 'Webページをつくる' : 'Webページを更新する'}
                          </BaseButton>
                        </div>
                      </div>
                    </div>
                  </HeaderTitle>
                  <div className="sp_only">
                    <ActionHeader>
                      <>
                        <div className="children">
                          <PortfolioPDFDownloadButton />
                        </div>
                        <div className="children">
                          <div className="w-140 flex">
                            <BaseButton
                              onClick={linkToEdit}
                              className="mbx-top-preview-edit-button w-full"
                              theme="secondary"
                              size="40"
                            >
                              編集する
                            </BaseButton>
                          </div>
                        </div>
                      </>
                    </ActionHeader>
                  </div>
                </>
              )}
            </div>
            <PreviewHeader>{previewHeader}</PreviewHeader>
          </>
        }
        footer={
          <>
            {!props.presentation && (
              <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area sm:mb-144">
                <BaseButton
                  theme="primary"
                  iconName={"Publish"}
                  size={'m-for-sp'}
                  onClick={() => setPublishModal(true)}
                >
                  {!publish ? 'Webページをつくる' : 'Webページを更新する'}
                </BaseButton>
              </section>
            )}
          </>
        }
      >
        <>
          <section className="border-b border-black">
            {portfolioItem?.i_embedlink && (
              <div
                ref={embedRef}
                style={{ height: embedHeight }}
                className="sm:-mx-24 img-main embed mkt_mask_items"
              >
                <Player
                  {...{ width: embedWidth, height: embedHeight, src: portfolioItem?.i_embedlink }}
                />
              </div>
            )}
            <div ref={imgRef} style={{ height }} className="sm:-mx-24 img-main mkt_mask_items">
              <img
                src={portfolioItem?.i_image.f_url}
                alt=""
                className="w-full h-full object-cover bg-gray-50"
              />
            </div>
            <div className="overview mt-32 mb-24 sm:mt-24 sm:mb-16">
              <p
                className={cn(
                  'overview-tag',
                  mq.sm ? 'text-12_18' : 'text-14_21',
                  'text-gray-600',
                  'mkt_mask_items'
                )}
              >
                {portfolioItem?.i_tags?.join(' / ')}
              </p>
              <p
                className={cn(
                  'overview-title',
                  'mkt_mask_items',
                  mq.sm ? 'mbx-typography--heading_3' : 'mbx-typography--heading_1',
                  'mt-4'
                )}
              >
                {portfolioItem?.i_title}
              </p>
              <ButtonTextLink
                href={portfolioItem?.i_url}
                className="overview-link mbx-typography--caption_1 mt-4 mkt_mask_items"
              >
                {portfolioItem?.i_url}
              </ButtonTextLink>
            </div>
          </section>
          <Page.Column
            main={<>{portfolioItem && <MainQuestions portfolioItem={portfolioItem} />}</>}
            menu={<>{portfolioItem && <SideQuestions portfolioItem={portfolioItem} />}</>}
          />
          <section className="preview-img w-624 mb-48 sm:w-full">
            {imageData && (
              <div className="mb-48 mt-48 preview-img_item">
                <WorksPreviewImage page={imageData} />
              </div>
            )}
            <div className="py-20 pdf-none">
              <BackButton href={props.demoId? `${productPath}/${props.demoId}${location.search}`: `${productPath}${location.search}`}>TOP</BackButton>
            </div>
          </section>
          <section className="others pdf-none">
            <p className="mbx-typography--heading_4 mt-48">他の作品を見る</p>
            <div className="others-field mt-24 mb-48">
              {otherPortfolio?.map((item, index) => {
                return (
                  <div className="item" key={index} onClick={() => linkToDetail(item.i_id)}>
                    <WorksModule
                      thumbnail={item.i_image.f_thumbnail}
                      title={item.i_title}
                      category={item.i_description}
                      editMode={false}
                      visible
                    />
                  </div>
                );
              })}
            </div>
            <div className="py-20 pdf-none">
              <BackButton href={props.demoId? `${productPath}/${props.demoId}${location.search}`: `${productPath}${location.search}`}>TOP</BackButton>
            </div>
          </section>
          <PreviewFooter className="folio mt-42" />
          <Publish
            {...{
              portfolio,
              userId,
              publishModal,
              published: publish,
              onClose: () => setPublishModal(false),
              updatePortfolio: (data) => {
                if (data) dispatch(setPortfolio(data));
              },
            }}
          />
        </>
      </Page.Wrapper>
    </div>
  );
}

export default DetailsPreview;

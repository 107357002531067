import React, { ReactElement } from 'react';
import '@/styles/common/Portfolio/PreviewHeader.scss';

type Props = {
  children: ReactElement | string;
};

function PreviewHeader(props: Props): React.ReactElement {
  return (
    <section className="mbx-preview-header">
      <div className="mbx-preview-header_inner mbx-typography--heading_3 mkt_mask_items">{props.children}</div>
    </section>
  );
}

export default PreviewHeader;

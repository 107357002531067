import '@/styles/pages/Top/Top.scss';
import '@/styles/pages/Event/Event.scss';

import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ImgUserIcon from '@/assets/images/profile/circle-placeholder.svg';
import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import Header from '@/components/common/Header';
import { Footer } from '@/components/common/Top/Footer';
import { ConfigContext } from '@/utils/config';
import { State } from '@/redux/state';
import { eventList } from '@/definition/EVENT_LIST';

import { Ed1 } from '@/components/Event/Ed1';
import { Ed2 } from '@/components/Event/Ed2';
import { Ed3 } from '@/components/Event/Ed3';
import { Ed4 } from '@/components/Event/Ed4';
import { Ed5 } from '@/components/Event/Ed5';
import { Ed6 } from '@/components/Event/Ed6';
import { Ed7 } from '@/components/Event/Ed7';
import { Ed8 } from '@/components/Event/Ed8';
import { Ed9 } from '@/components/Event/Ed9';
import { Ed10 } from '@/components/Event/Ed10';
import { Ed11 } from '@/components/Event/Ed11';
import { Ed12 } from '@/components/Event/Ed12';
import { Ed13 } from '@/components/Event/Ed13';
import { Ed14 } from '@/components/Event/Ed14';
import { Ed15 } from '@/components/Event/Ed15';
import { Ed16 } from '@/components/Event/Ed16';
import { Ed17 } from '@/components/Event/Ed17';
import { Ed18 } from '@/components/Event/Ed18';
import { Ed19 } from '@/components/Event/Ed19';
import { Ed20 } from '@/components/Event/Ed20';
import { Ed21 } from '@/components/Event/Ed21';
import { Ed22 } from '@/components/Event/Ed22';
import { Ed23 } from '@/components/Event/Ed23';
import { Ed24 } from '@/components/Event/Ed24';
import { Ed25 } from '@/components/Event/Ed25';
import { Ed26 } from '@/components/Event/Ed26';
import { Ed27 } from '@/components/Event/Ed27';
import { Ed28 } from '@/components/Event/Ed28';
import { Ed29 } from '@/components/Event/Ed29';
import { Ed30 } from '@/components/Event/Ed30';
import { Ed31 } from '@/components/Event/Ed31';
import { Ed32 } from '@/components/Event/Ed32';
import { Ed33 } from '@/components/Event/Ed33';
import { Ed34 } from '@/components/Event/Ed34';
import { Ed35 } from '@/components/Event/Ed35';
import { Ed36 } from '@/components/Event/Ed36';
import { Ed37 } from '@/components/Event/Ed37';
import { Ed38 } from '@/components/Event/Ed38';
import { Ed39 } from '@/components/Event/Ed39';
import { Ed40 } from '@/components/Event/Ed40';
import { Ed41 } from '@/components/Event/Ed41';
import { Ed42 } from '@/components/Event/Ed42';
import { Ed43 } from '@/components/Event/Ed43';
import { Ed44 } from '@/components/Event/Ed44';
import { Ed45 } from '@/components/Event/Ed45';
import { Ed46 } from '@/components/Event/Ed46';
import { Ed47 } from '@/components/Event/Ed47';
import { Ed48 } from '@/components/Event/Ed48';
import { Ed49 } from '@/components/Event/Ed49';
import { Ed50 } from '@/components/Event/Ed50';
import { Ed51 } from '@/components/Event/Ed51';
import { Ed52 } from '@/components/Event/Ed52';
import { Ed53 } from '@/components/Event/Ed53';
import { Ed54 } from '@/components/Event/Ed54';
import { Ed55 } from '@/components/Event/Ed55';
import { Ed56 } from '@/components/Event/Ed56';
import { Ed57 } from '@/components/Event/Ed57';
import { Ed58 } from '@/components/Event/Ed58';

export const EventDetail = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const components = [
    {
      id: '0001',
      component: <Ed1 />,
    },
    {
      id: '0002',
      component: <Ed2 />,
    },
    {
      id: '0003',
      component: <Ed3 />,
    },
    {
      id: '0004',
      component: <Ed4 />,
    },
    {
      id: '0005',
      component: <Ed5 />,
    },
    {
      id: '0006',
      component: <Ed6 />,
    },
    {
      id: '0007',
      component: <Ed7 />,
    },
    {
      id: '0008',
      component: <Ed8 />,
    },
    {
      id: '0009',
      component: <Ed9 />,
    },
    {
      id: '0010',
      component: <Ed10 />,
    },
    {
      id: '0011',
      component: <Ed11 />,
    },
    {
      id: '0012',
      component: <Ed12 />,
    },
    {
      id: '0013',
      component: <Ed13 />,
    },
    {
      id: '0014',
      component: <Ed14 />,
    },
    {
      id: '0015',
      component: <Ed15 />,
    },
    {
      id: '0016',
      component: <Ed16 />,
    },
    {
      id: '0017',
      component: <Ed17 />,
    },
    {
      id: '0018',
      component: <Ed18 />,
    },
    {
      id: '0019',
      component: <Ed19 />,
    },
    {
      id: '0020',
      component: <Ed20 />,
    },
    {
      id: '0021',
      component: <Ed21 />,
    },
    {
      id: '0022',
      component: <Ed22 />,
    },
    {
      id: '0023',
      component: <Ed23 />,
    },
    {
      id: '0024',
      component: <Ed24 />,
    },
    {
      id: '0025',
      component: <Ed25 />,
    },
    {
      id: '0026',
      component: <Ed26 />,
    },
    {
      id: '0027',
      component: <Ed27 />,
    },
    {
      id: '0028',
      component: <Ed28 />,
    },
    {
      id: '0029',
      component: <Ed29 />,
    },
    {
      id: '0030',
      component: <Ed30 />,
    },
    {
      id: '0031',
      component: <Ed31 />,
    },
    {
      id: '0032',
      component: <Ed32 />,
    },
    {
      id: '0033',
      component: <Ed33 />,
    },
    {
      id: '0034',
      component: <Ed34 />,
    },
    {
      id: '0035',
      component: <Ed35 />,
    },
    {
      id: '0036',
      component: <Ed36 />,
    },
    {
      id: '0037',
      component: <Ed37 />,
    },
    {
      id: '0038',
      component: <Ed38 />,
    },
    {
      id: '0039',
      component: <Ed39 />,
    },
    {
      id: '0040',
      component: <Ed40 />,
    },
    {
      id: '0041',
      component: <Ed41 />,
    },
    {
      id: '0042',
      component: <Ed42 />,
    },
    {
      id: '0043',
      component: <Ed43 />,
    },
    {
      id: '0044',
      component: <Ed44 />,
    },
    {
      id: '0045',
      component: <Ed45 />,
    },
    {
      id: '0046',
      component: <Ed46 />,
    },
    {
      id: '0047',
      component: <Ed47 />,
    },
    {
      id: '0048',
      component: <Ed48 />,
    },
    {
      id: '0049',
      component: <Ed49 />,
    },
    {
      id: '0050',
      component: <Ed50 />,
    },
    {
      id: '0051',
      component: <Ed51 />,
    },
    {
      id: '0052',
      component: <Ed52 />,
    },
    {
      id: '0053',
      component: <Ed53 />,
    },
    {
      id: '0054',
      component: <Ed54 />,
    },
    {
      id: '0055',
      component: <Ed55 />,
    },
    {
      id: '0056',
      component: <Ed56 />,
    },
    {
      id: '0057',
      component: <Ed57 />,
    },
    {
      id: '0058',
      component: <Ed58 />,
    },
  ];

  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);

  const config = useContext(ConfigContext);

  const menu = useMemo(() => {
    if (isAuth) {
      return config.menu;
    } else {
      return [
        {
          title: 'ログイン',
          url: '/login',
        },
      ];
    }
  }, [isAuth]);

  const headerMenu = useMemo(() => {
    if (isAuth) {
      return config.headerMenu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const userIcon = useMemo(() => {
    if (!user) return ImgUserIcon;
    return user?.icon;
  }, [user]);

  const badge = useMemo(() => {
    if (isAuth) {
      return true;
    } else {
      return false;
    }
  }, [isAuth]);

  const children = useMemo(() => {
    if (!isAuth) {
      return <BaseButton theme="primary" size="40" className="header-btn" href="/entry">
        MATCHBOXをはじめる
      </BaseButton>;
    }
  }, [isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.id]);

  useEffect(() => {
    const event = eventList.find((e) => e.id === params.id);
    if (!event) return;
    const dateFormat = (date: string) => {
      const splitDate = date.split(' ');
      const joinDate = splitDate.join('T') + '-09:00';
      return joinDate;
    }
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = `{
      "@context": "https://schema.org",
      "@type": "Event",
      "name": "${event.title.filter((t) => t !== '<br />').join('')}",
      "startDate": "${dateFormat(event.openingDate)}",
      "endDate": "${dateFormat(event.deadlineDate)}",
      "eventStatus": "https://schema.org/EventScheduled",
      "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
      "location": {
        "@type": "VirtualLocation",
        "url": "https://zoom.us"
        },
      "image": [
        "https://matchbox.work/images/Top/event/event_${event.id}_mv.png"
       ],
      "description": "${event.description}",
      "offers": {
        "@type": "Offer",
        "url": "https://matchbox.work/event/${event.id}",
        "price": "0",
        "priceCurrency": "JPY",
        "availability": "https://schema.org/InStock",
        "validFrom": "${dateFormat(event.releaseDate)}"
      },
      "performer": {
        "@type": "${event.performerType}",
        "name": "${event.performerName}"
      },
      "organizer": {
        "@type": "Organization",
        "name": "株式会社マイナビワークス マッチボックスセミナー事務局",
        "url": "https://matchbox.work/"
      }
    }`;
    if (body) body.appendChild(script);
  }, []);

  return (
    <section className={`event ${isAuth ? "event-auth" : ""}`}>
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'page_view',
        }}
      >
        <Header
          menu={menu}
          headerMenu={headerMenu}
          userIcon={userIcon}
          badge={badge}
          isAuth={isAuth}
        >
          {children}
        </Header>
        <section className={`max-w-980 m-auto md:px-16 md:mt-80 sm:px-16 mb-120 sm:mb-64 sm:mt-55 ${!isAuth ? "mt-80" : ""}`}>
          {components.find((c) => c.id === params.id)?.component}
        </section>
        <Footer />
      </DataLayerPushContainer>
    </section>
  );
};

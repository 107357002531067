import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';
import cn from 'classnames';
import '@/styles/common/Portfolio/works_module.scss';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

type Props = {
  thumbnail: string;
  title: string;
  category: string;
  editMode?: boolean;
  moveClass?: string;
  visible?: boolean;
  removeClick?: MouseEventHandler<HTMLDivElement>;
  onVisible?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
  message?: string;
};

function WorksModule(props: Props): React.ReactElement {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [visible, setVisible] = useState<boolean | undefined>(undefined);
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
  } = useSortable({ id: props.id || "" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  useEffect(() => {
    if (sectionRef.current) {
      const { clientWidth } = sectionRef.current;
      if (clientWidth > 0) {
        setHeight((clientWidth / 16) * 9);
      }
    }
  }, []);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const onVisible = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setVisible(!visible);
    if (props.onVisible) props.onVisible(e);
  };
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLDivElement).classList.contains('visible-button')) return;
    if ((e.target as HTMLDivElement).classList.contains('mbx-icon-Visible')) return;
    if ((e.target as HTMLDivElement).classList.contains('mbx-icon-Invisible')) return;
    if (props.onClick) props.onClick(e);
  };
  return (
    <section className="portfolio-card" style={style} ref={setNodeRef}>
      <div className="portfolio-card_inner" ref={sectionRef}>
        <div className={props.editMode ? 'edit-area' : 'no-edit'}>
          <div className={props.moveClass} {...listeners} {...attributes}>
            <ButtonIconButton size="default" type="gray" iconName="Sortable" />
          </div>
          <div className="delete" onClick={props.removeClick}>
            <ButtonIconButton size="default" type="gray" iconName="Clear" />
          </div>
        </div>
        {props.message? (
          <TooltipMessageMatchbou
            className="-ml-14 -mr-14"
            message={props.message}
            position={['0', '-100px']}
          >
            <div className='border-4 border-red-700 border-dotted py-10 px-10 -mt-14'>
              <div
                className={cn('thumbnail-holder', 'mkt_mask_items', { 'is-disable': !visible })}
                style={{ backgroundImage: `url(${props.thumbnail})`, height }}
                onClick={onClick}
              ></div>
            </div>
          </TooltipMessageMatchbou>
        ) : (
          <div
            className={cn('thumbnail-holder', 'mkt_mask_items', { 'is-disable': !visible })}
            style={{ backgroundImage: `url(${props.thumbnail})`, height }}
            onClick={onClick}
          ></div>
        )}
        <div className="title-area" onClick={onClick}>
          <p
            className={cn('mkt_mask_items', {
              'is-disable': !visible,
              'title-long': !props.editMode,
            })}
          >
            {props.title}
          </p>
          <div
            className={cn('visible-button', {
              disable: props.editMode,
              ['no-edit']: !props.editMode,
            })}
            onClick={onVisible}
          >
            <i className={cn('mbx-icon', visible ? 'mbx-icon-Visible' : 'mbx-icon-Invisible')} />
          </div>
        </div>
        <div className={cn('categoly-area', 'mkt_mask_items', { 'is-disable': !visible })}>
          <p>{props.category}</p>
        </div>
      </div>
    </section>
  );
}

export default WorksModule;

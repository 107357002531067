import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { RootState } from '@/reduxAdmin';
import { setPathAfterLogin } from '@/reduxAdmin/modules/utils';

type TProps = {
  path: string;
  exact?: boolean;
  publicRoute?: boolean;
  component: React.ComponentType;
};

/**
 * ログイン状態でuser.user_typeがadminの場合に管理者ページを表示
 * 未ログインの場合はログインページへ
 * またログインページはサーバー側でIP制限されているので一般ユーザーには表示されない。
 *
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#tag/user_info_model
 */
export function AdminRoute({
  path,
  exact = false,
  publicRoute = false,
  component,
}: TProps): React.ReactElement {
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.admin_auth.isLogin);

  // 未ログインでパスがloginページ以外の場合は遷移元のパスを保持
  // ログイン成功後に保持した遷移先に移動
  if (isLogin) {
    dispatch(setPathAfterLogin(undefined));
  } else {
    if (path !== '/admin/login') {
      dispatch(setPathAfterLogin(path));
    }
  }

  // publicの場合
  // 未ログイン : そのままページを表示
  // ログイン済み : ログインルートにリダイレクト
  if (publicRoute) {
    return (
      <Route path={path} exact={exact} component={component}>
        {isLogin && <Redirect to="/admin/search" />}
      </Route>
    );
  }

  // ページ遷移時にページトップに戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Route path={path} exact={exact} component={component}>
      {/* 未ログインの場合はログインページに */}
      {!isLogin && <Redirect to="/admin/login" />}
    </Route>
  );
}

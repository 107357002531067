import '@/styles/common/Account/RegisterSection.scss';

import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';

type IFormInputs = {
  email: string;
  name: string;
  furigana: string;
  yyyy: string;
  mm: string;
  dd: string;
  tel: string;
  mc_preferred_change_date: string;
};

type Props = {
  disabled: boolean;
  setRegisterInput: React.Dispatch<React.SetStateAction<IFormInputs>>;
  onClick: SubmitHandler<IFormInputs>;
};

function RegisterSection(props: Props): React.ReactElement {
  const schema = yup.object().shape({
    email: YUP_VALIDATION.email_required,
    name: YUP_VALIDATION.required,
    furigana: YUP_VALIDATION.furigana,
    yyyy: YUP_VALIDATION.required,
    mm: YUP_VALIDATION.required,
    dd: YUP_VALIDATION.required,
    tel: YUP_VALIDATION.tel_required,
    mc_preferred_change_date: YUP_VALIDATION.required,
  });
  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      name: '',
      furigana: '',
      yyyy: '',
      mm: '',
      dd: '',
      tel: '',
      mc_preferred_change_date: '',
    },
  });

  const { handleSubmit, watch } = methods;

  const values: IFormInputs = {
    name: watch('name'),
    email: watch('email'),
    furigana: watch('furigana'),
    yyyy: watch('yyyy'),
    mm: watch('mm'),
    dd: watch('dd'),
    mc_preferred_change_date: watch('mc_preferred_change_date'),
    tel: watch('tel'),
  };
  useEffect(() => {
    props.setRegisterInput(values);
  }, Object.values(values));
  return (
    <>
      <section className="register-form w-308 mt-54 m-auto sm:w-full sm:mt-30">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(props.onClick)}>
            <div className="mbx-utils-stack-v--16">
              <FormLayoutFieldset>
                <FormLabel label="メールアドレス" type="small"></FormLabel>
                <FormContainerTextfield
                  type={'email'}
                  name="email"
                  placeholder="例）example@matchbox.com"
                ></FormContainerTextfield>
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="氏名" type="small"></FormLabel>
                <FormContainerTextfield
                  name="name"
                  placeholder="例）町田　太郎"
                ></FormContainerTextfield>
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="ふりがな" type="small"></FormLabel>
                <FormContainerTextfield
                  name="furigana"
                  placeholder="例）まちだ　たろう"
                ></FormContainerTextfield>
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="生年月日" type="small"></FormLabel>
                <div className="flex mbx-utils-stack-h--8 flex-auto">
                  <FormContainerTextfield
                    name="yyyy"
                    placeholder="YYYY"
                    className="w-dateForm"
                    pattern="\d*"
                  ></FormContainerTextfield>
                  <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
                  <FormContainerTextfield
                    name="mm"
                    placeholder="MM"
                    className="w-dateForm"
                    pattern="\d*"
                  ></FormContainerTextfield>
                  <FormLayoutUnitDivider>月</FormLayoutUnitDivider>
                  <FormContainerTextfield
                    name="dd"
                    placeholder="DD"
                    className="w-dateForm"
                    pattern="\d*"
                  ></FormContainerTextfield>
                  <FormLayoutUnitDivider>日</FormLayoutUnitDivider>
                </div>
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="電話番号" type="small"></FormLabel>
                <FormContainerTextfield
                  name="tel"
                  type="number"
                  placeholder="例）09012345678"
                ></FormContainerTextfield>
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="転職希望時期" type="small"></FormLabel>
                <FormContainerDropdown
                  className="select mkt_mask_items"
                  name="mc_preferred_change_date"
                  placeholder="選択してください"
                  selectList={FORM_OPTIONS.change_date}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <p className="mbx-typography--caption_1 mt-8">*転職希望時期はいつでも変更できます</p>
            <section className="flex justify-center mt-56 sm:justify-center">
              <div className="mr-16 w-120 h-40 sm:w-70">
                <a href="https://privacymark.jp/" target="_blank" rel="noreferrer">
                  <img src="/images/p-mark.png" alt="" />
                </a>
              </div>
              <p className="text-11_16 text-left sm:w-270">
                <ButtonTextLink href={window.location.protocol + '/terms_of_use'}>
                  利用規約 / 個人情報の取り扱い
                </ButtonTextLink>
                に同意した上で、アカウント登録にお進みください。
                <br />
                個人情報の送信はすべてSSL通信で暗号化され、保護されます。
              </p>
            </section>
            <section className="register-btn w-308 m-auto mt-48 mb-96 sm:w-full sm:mb-64">
              <BaseButton size={'m'} theme={'primary'} className={'btn'} disabled={props.disabled}>
                利用規約に同意してアカウント登録する
              </BaseButton>
              <BaseButton size={'m'} theme={'link'} className={'btn mt-24 sm:mb-148'} href={'/login'}>
                MATCHBOXのアカウントをお持ちの方はこちら
              </BaseButton>
            </section>
          </form>
        </FormProvider>
      </section>
    </>
  );
}

export default RegisterSection;

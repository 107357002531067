/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { usePolling } from '@/hooks/usePolling';
import { toggleLoading } from '@/redux/index';
import { State } from '@/redux/state';
import {
    ADMINApi, Career, CareerHistory, CareerHistoryApi, CareerHistorySkills, DirectApi, Project,
    Qualification, QualificationQTypeEnum, Status, UserInfo
} from '@/utils/api-client/api';

/**
 * 履歴書から職務経歴書にアンカーリンクで遷移させるためのグローバルな変数
 * リンクコンポーネント /src/components/common/Button/TextLink.tsx
 * issue : https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1085
 */
declare global {
  interface Window {
    completedCareerHistory: boolean;
  }
}

window.completedCareerHistory = false;

const setDefaultValue = (fetchData: (CareerHistory & Status & UserInfo) | undefined) => {
  return {
    advantage: fetchData ? fetchData.advantage : '',
    description: fetchData ? fetchData.description : '',
    job: fetchData ? fetchData.job : '',
    latest_update: fetchData ? fetchData.latest_update : '',
    pr: fetchData ? fetchData.pr : '',
    //職務経歴
    careers: fetchData?.careers?.length
      ? fetchData.careers.map((c: Career) => {
          const join_date = c.c_join_date ? c.c_join_date : dayjs().format('YYYY-M');
          const leave_date = c.c_leave_date ? c.c_leave_date : dayjs().format('YYYY-M');
          return {
            ...c,
            c_join_date_year: parseInt(join_date.split('-')[0]),
            c_join_date_month: parseInt(join_date.split('-')[1]),
            c_leave_date_year: parseInt(leave_date.split('-')[0]),
            c_leave_date_month: parseInt(leave_date.split('-')[1]),
            c_projects: c.c_projects?.length
              ? c.c_projects.map((p: Project) => {
                  return {
                    ...p,
                    start_date_year: p.start_date ? parseInt(p.start_date.split('-')[0]) : '',
                    start_date_month: p.start_date ? parseInt(p.start_date.split('-')[1]) : '',
                    end_date_year: p.end_date ? parseInt(p.end_date.split('-')[0]) : '',
                    end_date_month: p.end_date ? parseInt(p.end_date.split('-')[1]) : '',
                  };
                })
              : [],
          };
        })
      : [],
    //スキル
    skills: fetchData ? fetchData.skills : [],
    //資格
    qualifications: fetchData?.qualifications?.length
      ? fetchData.qualifications.map((q) => {
          const date = q.q_date ? q.q_date : dayjs().format('YYYY-M');
          return {
            ...q,
            q_date_year: date.split('-')[0],
            q_date_month: parseInt(date.split('-')[1]),
          };
        })
      : [],
  };
};

const setEmptyCareer = async (userId: string) => {
  const career = {
    c_id: '',
    c_company_name: '',
    c_join_date: dayjs().format('YYYY-M'),
    c_employment_status: 0,
    c_section: '',
    c_capital: 0,
    c_employee_count: 0,
    c_leave_reason: '',
    c_leave_flag: false,
    c_post: '',
    c_visible: true,
    c_projects: [
      {
        name: '',
        detail: '',
        tools: '',
        start_date: null,
        end_date: null,
        structure: '',
      },
    ],
  };
  return await new CareerHistoryApi().postCareerHistoryUserIdCareers(userId, career);
};

const setEmptyQualification = async (userId: string) => {
  const toeic = {
    q_id: '',
    q_type: QualificationQTypeEnum.Toeic,
    q_title: 'TOEIC',
    q_date: dayjs().format('YYYY-M'),
    q_score: '0',
    q_visible: true,
  };
  const toefl = {
    q_id: '',
    q_type: QualificationQTypeEnum.Toefl,
    q_title: 'TOEFL',
    q_date: dayjs().format('YYYY-M'),
    q_score: '0',
    q_visible: true,
  };
  const res1: AxiosResponse = await new CareerHistoryApi().postCareerHistoryUserIdQualifications(
    userId,
    toeic
  );
  const res2: AxiosResponse = await new CareerHistoryApi().postCareerHistoryUserIdQualifications(
    userId,
    toefl
  );
  return { res1, res2 };
};

const setEmptySkills = async (userId: string) => {
  const skills: CareerHistorySkills[] = [
    {
      name: '',
      experience: 0,
      level: 0,
    },
  ];
  return await new CareerHistoryApi().patchCareerHistory(userId, { skills });
};

//*_year *_monthを合成する
const modifyProjectDate = (project: any): Project => {
  const genDate = (project: any): { start_date: string | null; end_date: string | null } => {
    let start_date = project.start_date;
    let end_date = project.end_date;

    if (project.start_date_year && project.start_date_month) {
      start_date = `${project.start_date_year}-${project.start_date_month}`;
    } else if (!project.start_date_year && !project.start_date_month) {
      start_date = null;
    }

    if (project.end_date_year && project.end_date_month) {
      end_date = `${project.end_date_year}-${project.end_date_month}`;
    } else if (!project.end_date_year && !project.end_date_month) {
      end_date = null;
    }

    return { start_date, end_date };
  };
  const newProd = {
    ...project,
    ...genDate(project),
  };
  delete newProd.end_date_year;
  delete newProd.end_date_month;
  delete newProd.start_date_year;
  delete newProd.start_date_month;
  return newProd as Project;
};

//職務経歴書全般にかかわる処理
export const careerHistory = (
  pageInit: boolean,
  type: 'public' | 'direct' | 'admin' = 'public'
) => {
  const location = useLocation();
  const loginUser: UserInfo | null = useSelector((state: State) => state.user);
  const [targetUser, setTargetUser] = useState<UserInfo | null>(null);
  const [matchboxId, setMatchboxId] = useState<string>();
  const [data, setData] = useState<CareerHistory & Status & UserInfo>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [param, setParam, chResponse, setFrag, setBeforeUnload] = usePolling(
    CareerHistoryApi,
    'patchCareerHistory',
    undefined,
    2000
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paramData, setCareerParam, careersResponse] = usePolling(
    CareerHistoryApi,
    'patchCareerHistoryUserIdCareers',
    undefined,
    2000
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [qualificationsParamData, setQualificationsParam, qualificationsResponse] = usePolling(
    CareerHistoryApi,
    'patchCareerHistoryUserIdQualifications',
    undefined,
    2000
  );
  const dispatch = useDispatch();

  const user = useMemo<UserInfo | null>(() => {
    switch (type) {
      case 'admin':
        return targetUser;
      case 'direct':
        return targetUser;
      default:
        return loginUser;
    }
  }, [loginUser, type, targetUser]);

  const userId = useMemo(() => {
    if (!user) return;
    return user?.user_id;
  }, [user]);

  useEffect(() => {
    const id = location.pathname.split('/')[3];
    switch (type) {
      case 'direct':
        setMatchboxId(id);
        break;
      case 'admin':
        setMatchboxId(id);
        break;
      default:
        break;
    }
  }, [type, location]);

  useEffect(() => {
    if (!matchboxId) return;
    (async () => {
      let userResponse: AxiosResponse;
      let response: AxiosResponse;
      switch (type) {
        case 'admin':
          userResponse = await new ADMINApi().getAdminUserId(matchboxId);
          setTargetUser(userResponse.data.user_info);
          break;
        case 'direct':
          userResponse = await new DirectApi().getDirectUserMatchboxId(matchboxId);
          response = await new DirectApi().getDirectCareerHistory(matchboxId);
          setTargetUser(userResponse.data.user_info);
          setData(response.data);
          break;
        default:
          break;
      }
    })();
  }, [matchboxId]);

  useEffect(() => {
    (async () => {
      if (!pageInit) return;
      if (!userId) return;
      let response: AxiosResponse;
      switch (type) {
        case 'admin':
          response = await new ADMINApi().getAdminCareerHistory(userId);
          break;
        default:
          response = await new CareerHistoryApi().getCareerHistory(userId);
          /**
           * 履歴書から職務経歴書にアンカーリンクで遷移させたいのだが画面描画が終わらないと正しい位置に移動しないので
           * 無理やりだがAPI通信が終わったらグローバルな値にフラグを代入、リンク側ではそれを監視して描画完了を判断
           * リンクコンポーネント /src/components/common/Button/TextLink.tsx
           * issue : https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1085
           */
          window.completedCareerHistory = true;
          break;
      }

      //TOEFL/TOEICがまだ無い場合、空のものを入れる
      if (
        response.data.qualifications?.filter(
          (q: Qualification) =>
            q.q_type === QualificationQTypeEnum.Toefl || q.q_type === QualificationQTypeEnum.Toeic
        ).length === 0
      ) {
        if (type !== 'direct' && type !== 'admin') {
          const { res1, res2 } = await setEmptyQualification(userId);
          response.data.qualifications.push(res1.data.qualifications[0]);
          response.data.qualifications.push(res2.data.qualifications[0]);
        }
      }
      //職歴がまだない場合、空のものを入れる
      if (response.data.careers?.length === 0) {
        if (type !== 'direct' && type !== 'admin') {
          const res: AxiosResponse = await setEmptyCareer(userId);
          response.data.careers.push(res.data.careers[0]);
        }
      }
      //スキルがまだ無い場合、空のものを入れる
      if (response.data.skills?.length === 0) {
        if (type !== 'direct' && type !== 'admin') {
          const res: AxiosResponse = await setEmptySkills(userId);
          response.data.skills = res.data.skills;
        }
      }
      setData(response.data);
    })();
  }, [user, matchboxId]);

  //即時PATCH処理
  const patch = async (param: any, uiUpdate: boolean) => {
    if (!user?.user_id) return;
    const res: AxiosResponse = await new CareerHistoryApi().patchCareerHistory(
      user?.user_id,
      param
    );
    if (uiUpdate) {
      setData(res.data);
    }
  };

  //ポーリングPATCH処理
  const patchCareerHistory = async (param: any) => {
    if (!user?.user_id) return;

    setParam({
      userId: user?.user_id,
      param,
    });
  };

  const postSkill = async (param: { skills: CareerHistorySkills[] }) => {
    if (!user?.user_id) return;
    const res: AxiosResponse<CareerHistory & Status & UserInfo> =
      await new CareerHistoryApi().patchCareerHistory(user?.user_id, param);

    const newSkill: CareerHistorySkills | undefined = res.data.skills?.find(
      (skill) => !data?.skills?.map((s) => s.id).includes(skill.id)
    );
    const skills: CareerHistorySkills[] | undefined = data?.skills;
    if (skills && newSkill) {
      skills.push(newSkill);
    }

    const res2 = await new CareerHistoryApi().patchCareerHistory(user?.user_id, { skills });
    setData(res2.data);
  };

  const deleteSkill = async (id: number) => {
    await new CareerHistoryApi().deleteCareerHistorySkill(`${id}`);
  };

  const postQualification = async () => {
    if (!user?.user_id) return;
    const param = {
      q_type: QualificationQTypeEnum.Text,
      q_title: '',
      q_date: dayjs().format('YYYY-M'),
      q_score: '',
      q_id: '',
      q_visible: true,
    };
    const res: AxiosResponse = await new CareerHistoryApi().postCareerHistoryUserIdQualifications(
      user?.user_id,
      param
    );
    if (!data?.qualifications) return;
    const newData = {
      ...data,
      qualifications: [...data?.qualifications, res.data.qualifications[0]],
    };
    setData(newData);
  };

  const patchQualificationImmediately = async (param: any) => {
    if (!user?.user_id) return;
    const res: AxiosResponse = await new CareerHistoryApi().patchCareerHistoryUserIdQualifications(
      user?.user_id,
      {
        ...param,
        q_date: `${param.q_date_year}-${param.q_date_month}`,
      }
    );

    setData((prevData) => {
      if (!prevData?.qualifications) return prevData;
      const newQ = res.data.qualifications[0] as Qualification;
      const newData = {
        ...prevData,
        qualifications: prevData?.qualifications.map((q) => {
          if (q.q_id === newQ.q_id) {
            return newQ;
          } else {
            return q;
          }
        }),
      };
      return newData;
    });
  };
  const patchQualification = async (param: any) => {
    if (!user?.user_id) return;
    setQualificationsParam({
      userId: user?.user_id,
      qualifications: {
        ...param,
        q_date: `${param.q_date_year}-${param.q_date_month}`,
      },
    });
  };

  const deleteQualification = async (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res: AxiosResponse = await new CareerHistoryApi().deleteCareerHistoryQualifications(id);

    setData((prevData) => {
      if (!prevData?.qualifications) return prevData;
      const newData = {
        ...prevData,
        qualifications: prevData?.qualifications.filter((q) => q.q_id !== id),
      };
      return newData;
    });
  };

  const postCareer = async (param: any) => {
    if (!user?.user_id) return;
    dispatch(toggleLoading(true));
    const res: AxiosResponse = await new CareerHistoryApi().postCareerHistoryUserIdCareers(
      user?.user_id,
      param
    );
    dispatch(toggleLoading(false));
    if (!data?.careers) return;
    const newData = {
      ...data,
      careers: [...data?.careers, res.data.careers[0]],
    };
    setData(newData);
  };

  const patchCareerImmediately = async (param: Career): Promise<Career | undefined> => {
    if (!user?.user_id) return undefined;
    const career: Career = {
      ...param,
      c_projects: param.c_projects?.map((project: any) => modifyProjectDate(project)),
    };

    const res: AxiosResponse = await new CareerHistoryApi().patchCareerHistoryUserIdCareers(
      user?.user_id,
      career
    );
    const newCareer: Career = res.data.careers[0];

    // 関数を渡して最新のprevDataを得る
    setData((prevData) => {
      const newData = prevData;
      if (!newData) return prevData;
      newData.careers = newData.careers?.map((oldCareer) =>
        oldCareer.c_id === newCareer.c_id ? newCareer : oldCareer
      );
      return newData;
    });
    return newCareer;
  };

  const patchCareer = async (param: Career): Promise<Career | undefined> => {
    if (!user?.user_id) return undefined;

    if (!param.c_employment_status) {
      param.c_employment_status = 0;
    }
    const career: Career = {
      ...param,
      c_projects: param.c_projects
        ?.filter((project) => project.id === 0 || !!project.id)
        .map((project) => modifyProjectDate(project)),
    };

    setCareerParam({ userId: user?.user_id, career });
    if (!data?.careers) return undefined;
  };

  //setCareerParamした結果をstate(=UI)に保存
  useEffect(() => {
    if (!careersResponse?.data) return;
    const newCareer: Career = careersResponse.data.careers[0];
    setData((prevData) => {
      const newData = prevData;
      if (!newData) return prevData;
      newData.careers = newData.careers?.map((oldCareer) =>
        oldCareer.c_id === newCareer.c_id ? newCareer : oldCareer
      );
      return newData;
    });
  }, [careersResponse]);

  const removeCareer = async (id: string) => {
    dispatch(toggleLoading(true));
    await new CareerHistoryApi().deleteCareerHistoryCareers(id);
    dispatch(toggleLoading(false));
    if (!data?.careers) return;

    const newData = {
      ...data,
      careers: data?.careers.filter((career) => career.c_id !== id),
    };
    setData(newData);
  };

  return {
    data,
    user,
    setParam,
    setDefaultValue,
    patch,
    patchCareerHistory,
    postSkill,
    deleteSkill,
    postQualification,
    patchQualification,
    patchQualificationImmediately,
    deleteQualification,
    postCareer,
    patchCareer,
    patchCareerImmediately,
    removeCareer,
    setPatchCareerParam,
    setBeforeUnload,
  };
};

//資格にかかわる処理
export const qualification = () => {
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const [data, setData] = useState<any>();

  const patch = async (param: any, uiUpdate: boolean) => {
    if (!user?.user_id) return;
    const res: AxiosResponse = await new CareerHistoryApi().patchCareerHistoryUserIdQualifications(
      user?.user_id,
      {
        ...param,
        q_date: `${param.q_date_year}-${param.q_date_month}`,
      }
    );

    if (uiUpdate) {
      setData(res.data);
    }
  };

  const remove = async (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res: AxiosResponse = await new CareerHistoryApi().deleteCareerHistoryQualifications(id);
  };

  return { data, patch, remove };
};

const setPatchCareerParam = (
  parameter: Career & {
    c_leave_date_year?: string;
    c_leave_date_month?: string;
    c_join_date_year?: string;
    c_join_date_month?: string;
  }
): Career => {
  const param = { ...parameter };

  //離職していない場合leave_dateは削除
  if (!param.c_leave_flag) {
    delete param.c_leave_date;
  } else {
    //離職していて日付が未入力の場合埋める
    if (!param.c_leave_date_year || !param.c_leave_date_month) {
      param.c_leave_date = dayjs().format('YYYY-M');
    } else {
      param.c_leave_date = `${param.c_leave_date_year}-${param.c_leave_date_month}`;
    }
  }
  delete param.c_leave_date_year;
  delete param.c_leave_date_month;

  if (param.c_join_date_year && param.c_join_date_month) {
    param.c_join_date = `${param.c_join_date_year}-${param.c_join_date_month}`;
  }
  delete param.c_join_date_year;
  delete param.c_join_date_month;

  return param;
};

//職歴にかかわる処理
export const career = () => {
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const [data, setData] = useState<any>();

  const post = async (param: any) => {
    if (!user?.user_id) return;
    const res: AxiosResponse = await new CareerHistoryApi().postCareerHistoryUserIdCareers(
      user?.user_id,
      param
    );
    setData(res.data);
  };

  const patch = async (param: any) => {
    if (!user?.user_id) return;

    const newParam = setPatchCareerParam(param);

    if (!newParam) return;

    const res: AxiosResponse = await new CareerHistoryApi().patchCareerHistoryUserIdCareers(
      user?.user_id,
      newParam
    );
    setData(res.data);
  };

  const remove = async (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res: AxiosResponse = await new CareerHistoryApi().deleteCareerHistoryCareers(id);
  };

  const removeProject = async (id: number) => {
    await new CareerHistoryApi().deleteCareerHistoryProjects(`${id}`);
  };

  return { data, post, patch, remove, removeProject };
};

import React, { useEffect } from 'react';
import cn from 'classnames';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';

function Faq(): React.ReactElement {
  const faqList = [
    {
      q: 'MATCHBOXを利用するには、料金はかかりますか？',
      a: 'MATCHBOXは無料で使えます。<br />今後、有料でさらに便利にお使いいただけるプランも予定しています。',
    },
    {
      q: '今すぐ転職するつもりはないのですが、MATCHBOXのサービスを利用できますか？',
      a: 'はい、MATCHBOXは転職を考えていなくても、利用できます。<br />転職を検討しはじめた際には、ぜひMATCHBOX内にある「オファー機能」に登録してみましょう。<br />あなたにぴったりのお仕事とのマッチングをお手伝いさせていただきます。',
    },
    {
      q: '学生なのですがMATCHBOXのサービスを利用できますか？',
      a: 'はい、MATCHBOXは学生でも利用できます。<br />実際に、学生の方の利用実績も多数ありますので、ぜひ在学中からMATCHBOXを使って、少しずつポートフォリオを作ってみてください。<br />※なお、「オファー機能」は転職を前提としたものとなります。予めご了承ください。',
    },
    {
      q: 'アカウント登録をしましたが、連絡がこないです。',
      a: 'アカウント登録時に入力いただいたメールアドレスに誤りがあるか、MATCHBOXからのメールが迷惑メールフォルダなどに紛れている可能性があります。<br />以下のいずれかをお試しください。<br /><br />【メールアドレスに誤りがある場合】<br />・アカウント登録より、再度登録をお試しください。<br /><br />【MATCHBOXからのメールが届かない場合】<br />・「mynavi.jp」を受信できるよう、ドメイン受信設定を行ってください。<br />・ パスワード再設定をお試しください。<br /><br />上記で解決できない場合は、お問い合わせフォームよりご連絡ください。',
    },
    {
      q: 'パスワードを忘れてしまいました。',
      a: '<a href="/login/reminder" class="text-blue-700 underline">パスワード再設定</a>より、登録済みのメールアドレスを入力し、パスワードの再設定をお願いします。',
    },
    {
      q: 'MATCHBOXに登録してからしばらく経ってしまいました。今からでも使えますか？',
      a: '前回のご利用から時間が経っていても、MATCHBOXは問題なく利用できます。<br />すでにお持ちのログインID（メールアドレス）とパスワードでログインしてお使いください。',
    },
    {
      q: 'アカウント作成の後は何をすればよいですか？',
      a: 'まずは、履歴書から入力してみてください。<br />ポートフォリオも１つずつで構いませんので、まずはお気軽に登録してください。<br />後で、修正・編集することも可能です。',
    },
    {
      q: '作成したポートフォリオに、パスワードをかけることは可能でしょうか？',
      a: '可能です。<br/>・公開（パスワードなし）<br/>・限定公開（パスワードつき）<br/>・非公開（本人のみ閲覧可能）<br/>よりお選びください。',
    },
    {
      q: '履歴書や職務経歴書もWeb上に公開されてしまいますか？',
      a: 'いいえ、履歴書や職務経歴書は作成者のみしか閲覧できませんので、ご安心ください。<br/>※ただし、「オファー機能」登録中は、職務経歴書内の、氏名、生年月日、連絡先といった個人情報を除く経歴がMATCHBOX利用企業に開示されます。<br/>※「オファー機能」の登録は、自由に変更できます。',
    },
    {
      q: '「オファー機能」とはなんですか？',
      a: 'MATCHBOXで作成したあなたの経歴やポートフォリオを、MATCHBOX利用企業に直接見てもらい、オファーをもらえる機能です。<br />マッチングの可能性を広げるためにも、職務経歴書やポートフォリオの準備ができたら、ぜひ登録してみましょう。',
    },
    {
      q: 'ポートフォリオをつくらないと「オファー機能」は使えませんか？',
      a: 'いいえ、オファー機能は、職務経歴書のみでも使えます。<br />プロデューサーやディレクター、プランナー、営業など、ビジュアルのアウトプットがない職種の方も、ぜひご利用ください。',
    },
    {
      q: '「オファー機能」を利用する際、閲覧されたなくない企業があるのですが、除外できますか？',
      a: 'はい、閲覧されたくない企業はブロックの設定ができますので、ご安心ください。',
    },
    {
      q: '「オファー機能」を登録しましたが、オファーが来ません',
      a: '職務経歴書やポートフォリオ、希望条件の項目を見直してみましょう。<br />入力された情報が多いほど、登録企業からのオファーが届きやすくなります。',
    },
    {
      q: '転職活動が終了したので、「オファー機能」を解除したいです。可能でしょうか？',
      a: 'はい、「オファー機能」の登録・解除は、ご自身の好きなタイミングで可能です。',
    },
    {
      q: '推奨環境はありますか？',
      a: 'MATCHBOXを快適にご利用いただくために、以下ブラウザを推奨いたします。<br /><br />Google Chrome（最新版）<br/ >Safari（最新版）<br /><br />※推奨ブラウザをご利用でも、OSとブラウザの組み合わせ、ブラウザの設定状況によっては表示、機能に不具合が起こる可能性がございます。予めご了承ください。',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page.Wrapper header={<HeaderTitle title={'よくあるご質問'} />}>
      <section className="w-624 m-auto mt-64 mb-120 sm:w-auto sm:mt-48 sm:mb-233">
        {faqList.map((list, index) => {
          return (
            <div
              key={index}
              className={cn('pb-40', 'border-b', 'border-gray-300', {
                ['mt-40']: index !== 0,
              })}
            >
              <p className="mbx-typography--subhead_1">
                Q
                {(index + 1).toString().replace(/[A-Za-z0-9]/g, (s) => {
                  return String.fromCharCode(s.charCodeAt(0) + 65248);
                })}
                ：{list.q}
              </p>
              <p
                className="mbx-typography--body_1 mt-16"
                dangerouslySetInnerHTML={{ __html: list.a }}
              ></p>
            </div>
          );
        })}
      </section>
    </Page.Wrapper>
  );
}

export default Faq;

import React from 'react';

import {
    FormCombinationsFromToTextfield
} from '@/componentsDirect/Parts/Form/Combinations/FromToTextfield';
import { FormCombinationsFromToDatepicker }from '@/componentsDirect/Parts/Form/Combinations/FromToDatepicker';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import {
    FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

/**
 * 検索条件フォーム オファー状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormSectionOffer = (): React.ReactElement => {
  return (
    <UserCondition title="オファー状況">
      <>
        <div className="flex flex-auto mb-22">
          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="選考ステータス" type="small"></FormLabel>
            <FormContainerDropdownMultiple
              name="offer_status"
              placeholder="選択してください（複数選択可）"
              selectList={DIRECT_FORM_OPTIONS.offer_status}
            ></FormContainerDropdownMultiple>
          </FormLayoutFieldset>

          <FormCombinationsFromToDatepicker
            className="pl-24"
            name="offer_update_at"
            label="選考ステータス最終更新日"
            fieldWidthClass="w-126"
            placeholder="yyyy/mm/dd"
            minYesr={2018}
          />
        </div>

        <div className="flex flex-auto mb-22">
          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="管理タグ（旧確認ステータス）" type="small"></FormLabel>
            <FormContainerDropdownMultiple
              name="user_admin_tags"
              placeholder="選択してください（複数選択可）"
              selectList={DIRECT_FORM_OPTIONS.userAdminTags}
            ></FormContainerDropdownMultiple>
          </FormLayoutFieldset>

          <FormLayoutFieldset className="ml-24 w-176">
            <FormLabel label="対応状況" type="small"></FormLabel>
            <FormContainerDropdown
              name="mw_status"
              placeholder="選択してください"
              selectList={DIRECT_FORM_OPTIONS.mwStatus}
            ></FormContainerDropdown>
          </FormLayoutFieldset>
        </div>

        <div className="flex flex-auto mb-22">
          <FormCombinationsFromToTextfield
            className="pl-24"
            name="offer_amount"
            label="オファー数"
            type="text"
            fieldWidthClass="w-64"
            placeholder="1"
            placeholder2="10"
            unit="件"
          />
          <FormCombinationsFromToTextfield
            className="pl-50"
            name="offer_pickup_amount"
            label="ピックアップ数"
            type="text"
            fieldWidthClass="w-64"
            placeholder="1"
            placeholder2="10"
            unit="件"
          />
        </div>
      </>
    </UserCondition>
  );
};

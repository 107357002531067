import React from 'react';
import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';

type TProps = {
  title: string;
  subTitle?: string;
  message?: React.ReactNode;
  updated?: string;
};

/**
 * 管理画面用ヘッダー要素
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=4694%3A63903
 */
export const HeaderTitle = ({ title, subTitle, message, updated }: TProps): React.ReactElement => {
  return (
    <section className="bg-gray-200 border-b border-gray-300 shadow-headerTitle">
      <div className="h-56 flex mx-auto w-960 items-center">
        {message? (
          <TooltipMessageMatchbou
            className="-ml-12"
            message={message}
            position={['0', '48px']}
          >
            <h1 className='text-text font-bold border-4 border-red-700 border-dotted py-4 px-10'>{title}</h1>
          </TooltipMessageMatchbou>
        ) : (
          <h1 className='text-text font-bold'>{title}</h1>
        )}
        {subTitle && (
          <h2 className="border border-black bg-white text-10_12 py-4 px-8 font-bold ml-16">
            {subTitle}
          </h2>
        )}
        {updated && <h2 className="text-10_12 py-4 px-8 text-gray-700 ml-16">{updated}</h2>}
      </div>
    </section>
  );
};

import '@/styles/pages/Portfolio/SettingEdit.scss';

import { AxiosError } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { FormCheckboxItem } from '@/components/common/Form/CheckboxItem';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { FormRadioButtonItem } from '@/components/common/Form/RadioButtonItem';
import * as Page from '@/components/common/Page';
import HeaderImage from '@/components/common/Portfolio/HeaderImage';
import PortfolioNavigation from '@/components/common/Portfolio/Navigation';
import PortfolioHeaderEdit from '@/components/common/Portfolio/PortfolioHeaderEdit';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { MESSAGE } from '@/definition/MESSAGE';
import { usePolling } from '@/hooks/usePolling';
import { usePortfolioUser } from '@/hooks/usePortfolioUser';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { notificationError, toggleLoading, userInfoForceUpdate } from '@/redux';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioApi, PortfolioStatusEnum, ResponseError, UserApi, UserPatchRequest
} from '@/utils/api-client';

const defaultValues: Portfolio = {
  status: PortfolioStatusEnum.Closed,
  password: '',
  background_color: '',
  font: '',
  page_title: '',
  first_visit: 0,
  publish: 0,
  privacy: 0,
  page_title_logo: {
    f_id: '',
    f_thumbnail: '',
    f_url: '',
  },
  profile: {
    user_id: '',
    icon: '',
    name: '',
    introduction: '',
    experiences: [],
    skills: [],
    main_image: {
      f_id: '',
      f_thumbnail: '',
      f_url: '',
    },
  },
};

export function SettingEdit(): React.ReactElement {
  const user = useSelector((state: State) => state.user);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paramData, setParam, map, saveFrag, setBeforeUnload] = usePolling(
    PortfolioApi,
    'patchPortfolioEdit',
    undefined,
    2000
  );

  const { userId, portfolio } = usePortfolioUser(false);
  const [pageTitleLogo, setPageTitleLogo] = useState<string | undefined>();
  const [mainImage, setMainImage] = useState<string | undefined>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const { push } = useContext(DataLayerContext);
  const mq = useMBXMediaQuery();

  const methods = useForm<Portfolio>({
    defaultValues,
  });

  useEffect(() => {
    if (!portfolio) return;
    methods.setValue('status', portfolio.status);
    methods.setValue('privacy', portfolio.privacy);
    methods.setValue('page_title', portfolio.page_title);
    methods.setValue('page_title_logo', portfolio.page_title_logo);
    if (portfolio.profile)
      methods.setValue('profile', {
        ...portfolio.profile,
        user_id: portfolio.profile?.user_id || '',
      });
    setPageTitleLogo(portfolio.page_title_logo?.f_thumbnail);
    setMainImage(portfolio.profile?.main_image.f_url);
    if (portfolio.status === 'password') {
      setPasswordVisible(true);
      methods.setValue('password', portfolio.password);
    }
  }, [portfolio]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!userId) return;
      setPasswordVisible(value.status === 'password');
      if (name === 'page_title' && type === 'change') {
        //キー入力では保存せず、onBlur時に保存を行う
        return;
      }
      setParam({
        userId: userId,
        params: value,
      });
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, userId]);

  const onBlur = () => {
    setBeforeUnload(false);
    const value = methods.getValues();

    setParam({
      userId: userId,
      params: value,
    });
  };

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  // ロゴ画像変更
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    dispatch(toggleLoading(true));
    const imageFile = e.target.files[0];
    try {
      const f = await new PortfolioApi().postFiles(imageFile);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      methods.setValue('page_title_logo', f.data);
      setPageTitleLogo(f.data.f_url);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // バナー画像変更
  const bannerImageUpdate = async (file: string) => {
    try {
      dispatch(toggleLoading(true));
      const f = await new PortfolioApi().postFiles(file);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      const profile = methods.getValues('profile');
      if (profile) {
        profile.main_image = f.data;
        methods.setValue('profile', profile);
        setMainImage(f.data.f_url);
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // バナー画像削除
  const bannerImageDelete = async () => {
    try {
      dispatch(toggleLoading(true));
      const profile = methods.getValues('profile');
      if(!profile?.main_image?.f_id) return;
      await new PortfolioApi().deleteFiles(profile.main_image.f_id);
      profile.main_image = {} as any;
      methods.setValue('profile', profile);
      setMainImage(undefined);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  //ユーザー画像変更
  const userImageUpdate = async (src: string | null) => {
    if (!user) return;

    // ユーザー情報編集APIの必須項目対応で便宜上ここでは不要な情報もリクエスト
    const data: UserPatchRequest = {
      name: user.name,
      furigana: user.furigana,
      birth_year: user.birth_year,
      birth_month: user.birth_month,
      birth_day: user.birth_day,
      icon: src as any,
      matchbox_id: user.matchbox_id as string,
    };

    dispatch(toggleLoading(true));
    try {
      const user = await new UserApi().patchUser(data);
      if (user.data.user_info) {
        dispatch(userInfoForceUpdate(user.data.user_info));
        push({
          event: 'fileUpload',
          actionType: 'file_upload',
          actionName: '画像',
        });
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const copyURL: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(`https://matchbox.work/portfolio/${userId}`);
  };
  const onPrivacy = () => {
    if (methods.watch('privacy') === 0) {
      methods.setValue('privacy', 1);
    } else {
      methods.setValue('privacy', 0);
    }
  };

  const logoClear = async () => {
    if (!pageTitleLogo) return;
    dispatch(toggleLoading(true));
    try {
      const id = methods?.watch('page_title_logo')?.f_id;
      if (!id) return;
      await new PortfolioApi().deleteFiles(id);
      setPageTitleLogo(undefined);
      methods.setValue('page_title_logo', undefined);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      console.error(e.message);
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="mbx-setting-edit">
      <Page.Wrapper
        header={
          <>
            <div className={cn({"sticky top-0 z-20": !mq.sm})}>
              <HeaderTitle title={'ポートフォリオ'}>
                <div className="mbx-setting-edit-preview-wrapper" style={style}>
                  {portfolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                  )}
                  <div className="ml-16 w-140 sp_none">
                    <BaseButton
                      theme="secondary"
                      size="40"
                      href={`/mypage/portfolio/${userId}/profile`}
                      className="w-full whitespace-nowrap"
                    >
                      プレビュー
                    </BaseButton>
                  </div>
                </div>
              </HeaderTitle>
            </div>
            <div className="sm:hidden">
              <HeaderImage
                cameraIcon={true}
                imageSrc={mainImage ? mainImage : '/images/header-img_default.png'}
                onChange={bannerImageUpdate}
                onClick={bannerImageDelete}
              />
            </div>
          </>
        }
        footer={
          <>
            {user && (
              <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area">
                <BaseButton
                  theme={'secondary'}
                  size={'m-for-sp'}
                  href={`/mypage/portfolio/${user.user_id}/profile`}
                >
                  プレビューする
                </BaseButton>
              </section>
            )}
          </>
        }
      >
        <>
          <div className="sm:hidden">
            <PortfolioHeaderEdit
              imageSrc={user?.icon ? user.icon : '/images/circle-placeholder.png'}
              onChange={userImageUpdate}
              portFolio={portfolio}
            />
          </div>

          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${userId}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={2}
          />

          <Page.Column
            main={
              <FormProvider {...methods}>
                <form>
                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <div className="flex items-center mb-24">
                      <h2 className="title mbx-typography--heading_4">ポートフォリオのタイトル</h2>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="オファー機能ご利用時、「ポートフォリオのタイトル」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>

                    <p className="text-body_1">
                      文字のタイトル、画像ロゴ、どちらも設定した場合は画像ロゴが表示されます。
                    </p>

                    <div className="space-y-8 my-22">
                      <FormLabel label="文字のタイトル" type="small" />
                      <FormContainerTextfield
                        className="mkt_mask_items"
                        name="page_title"
                        placeholder="例）Ichiro Suzuki"
                        onFocus={() => setBeforeUnload(true)}
                        onBlur={() => onBlur()}
                      />
                    </div>

                    <div className="space-y-8 my-22">
                      <FormLabel label="画像ロゴ" type="small" />
                      <div className="lg:flex md:flex items-center lg:space-x-8 md:space-x-8">
                        <BaseButton theme="tertiary" size="m" onClick={onClick}>
                          画像をアップロード
                        </BaseButton>
                        <div className="w-272 h-40 mbx-setting-edit_bg-alpha sm:mb-16">
                          <div
                            className="w-272 h-40 mkt_mask_items"
                            style={{ backgroundImage: `url(${pageTitleLogo})` }}
                          />
                          <div className="pt-8 text-left">
                            <p className="text-caption_2 text-gray-700">横40px × 縦280px推奨、最大5MB</p>
                          </div>
                        </div>
                        <ButtonIconButton
                          iconName="Circle-large_Clear"
                          type="gray"
                          onClick={logoClear}
                        />
                      </div>
                      <input type="file" accept=".jpg, .jpeg, .png, .gif" ref={inputRef} onChange={onChange} />
                    </div>
                  </div>

                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <div className="flex items-center mb-24">
                      <h2 className="title mbx-typography--heading_4">
                        ポートフォリオWebページのURL
                      </h2>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="オファー機能ご利用時、「ポートフォリオWebページのURL」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    <FormLayoutFieldset>
                      <></>
                      <div className="md:flex lg:flex md:space-x-8 lg:space-x-8">
                        <div className="flex flex-1 space-x-8">
                          <FormLayoutUnitDivider>
                            https://matchbox.work/portfolio/
                          </FormLayoutUnitDivider>

                          <FormContainerTextfield
                            className="flex-1 mkt_mask_items"
                            name="profile.user_id"
                            placeholder=""
                            disabled
                          />
                        </div>

                        <BaseButton
                          theme="tertiary"
                          size="m"
                          className="mbx-setting-edit_copy-url"
                          onClick={copyURL}
                        >
                          URLをコピー
                        </BaseButton>
                      </div>
                    </FormLayoutFieldset>
                  </div>

                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <h2 className="title mbx-typography--heading_4 mb-24">プライバシー</h2>
                    <div className="flex items-center">
                      <FormRadioButtonItem
                        label="パスワードなしで公開"
                        value="public"
                        name="status"
                      />
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="URLを知っている人は誰でも見ることができます"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    <div className="flex items-center">
                      <FormRadioButtonItem
                        label="パスワードを設定して公開"
                        value="password"
                        name="status"
                      />
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="URLとパスワードを知っている人は誰でも見ることができます"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    {passwordVisible && (
                      <>
                        <FormContainerTextfield
                          name="password"
                          type="password"
                          placeholder="パスワードを入力してください"
                          className="w-300 ml-32"
                        />
                        <p className="mbx-typography--caption_1 mt-8 mb-16 ml-32">
                          {MESSAGE.password_specifications}
                        </p>
                      </>
                    )}
                    <FormRadioButtonItem
                      label="公開しない"
                      value="closed"
                      name="status"
                    />
                  </div>
                  <div className={cn("sm:mb-212", mq.sm ? "my-32" : "my-48")}>
                    <h2 className="title mbx-typography--heading_4 mb-24">
                      コンプライアンスについて
                    </h2>

                    <p className="text-body_1 my-24">
                      登録するポートフォリオ内の掲載作品に関して、下記事項に同意ください。
                      <br />
                      チェックを入れていただくとWebページがつくられます。
                    </p>

                    <FormCheckboxItem
                      label="第三者の著作権・肖像権、その他知的財産権を侵害していません。"
                      name="privacy"
                      onChange={onPrivacy}
                    />
                  </div>
                </form>
              </FormProvider>
            }
            menu={<></>}
          />
        </>
      </Page.Wrapper>
    </div>
  );
}

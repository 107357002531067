import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { TFilterValues } from '@/pagesDirect/CompanyUsers/Index';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
  CompanyUser,
  DirectApi,
  DirectCompanyUserParam,
  InlineObject9,
  ResponseError,
} from '@/utils/api-client/api';

type TReturn = {
  companyUsers: Array<CompanyUser> | undefined;
  getCompanyUsers: (request: TFilterValues) => Promise<void>;
  patchCompanyUsers: (request: InlineObject9) => Promise<void>;
  patchCompanyUserId: (idPathParam: string, request: DirectCompanyUserParam) => void;
  companyUserIdResponse: AxiosResponse | undefined;
  postCompanyUser: (request: { users: Array<string> }) => Promise<void>;
  deleteCompanyUserId: (idPathParam: string) => Promise<void>;
};

/**
 * 企業ユーザー関連処理
 */
export const useCompanyUsers = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();
  const [companyUsers, setCompanyUsers] = useState<Array<CompanyUser>>();
  const [companyUserIdResponse, setCompanyUserIdResponse] = useState<AxiosResponse>();

  /**
   * ユーザー情報取得
   * ログアウト時などにエラーになってしまうのでエラー通知はせずに成功時のみ処理
   */
  const getCompanyUsers = ({
    user_name = '',
    mail = '',
    group = '',
    user_type = undefined,
  }: TFilterValues) => {
    dispatch(toggleLoading(true));
    return new Promise<void>(() => {
      setCompanyUsers([]);
      dispatch(toggleLoading(false));
    });
    // return API.getDirectCompanyUsers(1000, 0, 'display_order', user_name, mail, group, user_type)
    //   .then((res) => {
    //     setCompanyUsers(res.data.users);
    //   })
    //   .catch((error: AxiosError<ResponseError>) => {
    //     if (error.response) dispatch(notificationError(error.response.data.error_message));
    //   })
    //   .finally(() => {
    //     dispatch(toggleLoading(false));
    //   });
  };

  /**
   * ユーザー情報並び替え
   */
  const patchCompanyUsers = (request: InlineObject9) => {
    dispatch(toggleLoading(true));
    return API.patchDirectCompanyUsers(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_profile));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * ユーザー情報編集
   */
  const patchCompanyUserId = (idPathParam: string, request: DirectCompanyUserParam) => {
    dispatch(toggleLoading(true));
    API.patchDirectCompanyUserId(idPathParam, request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_profile));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          setCompanyUserIdResponse(error.response);
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * ユーザー追加
   */
  const postCompanyUser = (request: { users: Array<string> }) => {
    dispatch(toggleLoading(true));
    return API.postDirectInvite(request)
      .then((res) => {
        console.log(res);
        // getCompanyUsers();
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  /**
   * ユーザー削除
   */
  const deleteCompanyUserId = (idPathParam: string) => {
    dispatch(toggleLoading(true));
    return API.deleteDirectCompanyUserId(idPathParam)
      .then((res) => {
        console.log(res);
        dispatch(notificationSuccess(MESSAGE.deleted_user));
        // getCompanyUsers();
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  return {
    companyUsers,
    getCompanyUsers,
    patchCompanyUsers,
    patchCompanyUserId,
    companyUserIdResponse,
    postCompanyUser,
    deleteCompanyUserId,
  };
};

import '@/styles/common/Mypage/text.scss';

import React, { useEffect, useState, useContext } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import BaseButton from '@/components/common/Button/BaseButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { useUser } from '@/hooks/useUser';
import { State } from '@/redux/state';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type TFormProps = {
  current_email: string;
  email: string;
};

export const SectionMail = (): React.ReactElement => {
  const user = useSelector((state: State) => state.user);
  const loading = useSelector((state: State) => state.loading);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { matchboxUser, postEmailChange, emailChangeResponse } = useUser();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (matchboxUser && matchboxUser.email) methods.setValue('current_email', matchboxUser.email);
  }, [matchboxUser]);

  useEffect(() => {
    if(emailChangeResponse && emailChangeResponse.status === 200){
      methods.setValue('email', '');
      setIsSubmitted(true);
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        virtualPageName: 'mail_step_2',
      });
    }
  }, [emailChangeResponse]);

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    email: YUP_VALIDATION.email_required,
  });

  const methods = useForm<TFormProps>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control } = methods;

  const { isValid } = useFormState({
    control,
  });

  const onSubmit: SubmitHandler<TFormProps> = (data) => {
    (async () => {
      await postEmailChange({
        email: data.email,
      });
    })();
  };

  return (
    <section>
      <p className="mbx-typography--heading_4 sm:mbx-typography--heading_3 text-center">
        メール アドレスの変更
      </p>
      <p className="mbx-typography--body_1 mbx-mypage--text text-center mt-24">
        {isSubmitted
          ? ' メールアドレス再設定用のリンクを送信しました。 メールをご確認ください。 なおリンクの有効期限は発行後、24時間です。'
          : '新しいメールアドレスへお送りするリンクをクリックして、 変更を完了してください。'}
      </p>
      {!isSubmitted && (
        <>
          <p className="mt-22 text-12_16 font-bold">現在のメールアドレス</p>
          <p className="mt-20 text-14_20 mkt_mask_items">{user?.email}</p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-30 sm:mt-30 mbx-utils-stack-v--16">
                <FormLayoutFieldset>
                  <FormLabel label="新しいメールアドレス" type="small"></FormLabel>
                  <FormContainerTextfield
                    name="email"
                    className="mkt_mask_items"
                    placeholder="例）machida.tarou@matchbox.com"
                  />
                </FormLayoutFieldset>
              </div>
              <section className="register-btn m-auto mt-24 sm:w-full">
                <BaseButton
                  size="m"
                  theme="primary"
                  className="btn"
                  type="submit"
                  disabled={loading || !isValid}
                >
                  メールアドレスを変更する
                </BaseButton>
              </section>
            </form>
          </FormProvider>
        </>
      )}
    </section>
  );
};

import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import {
    FormCheckboxItem, FormContainerTextfield, FormRadioButtonItem
} from '@/components/common/Form/';
import Modal from '@/components/common/Modal';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { MESSAGE } from '@/definition/MESSAGE';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import { notificationError, toggleLoading } from '@/redux/index';
import {
    Portfolio, PortfolioApi, PortfolioStatusEnum, ResponseError, Status
} from '@/utils/api-client/api';
import { Image } from '@/utils/api-client/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataLayerPushContainer } from '../DataLayerPushContainer';

type PortfolioInputs = {
  status: PortfolioStatusEnum;
  password: string;
  background_color: string;
  font: string;
  page_title: string;
  first_visit: number;
  publish: number;
  privacy: number;
  page_title_logo: Image;
};

const defaultValues: Portfolio = {
  status: PortfolioStatusEnum.Closed,
  password: '',
  background_color: '',
  font: '',
  page_title: '',
  first_visit: 0,
  publish: 0,
  privacy: 0,
  page_title_logo: {
    f_id: '',
    f_thumbnail: '',
    f_url: '',
  },
};

const schema = yup.object().shape({
  password: yup.string().when(['status'], {
    is: (status: PortfolioStatusEnum) => status === PortfolioStatusEnum.Password,
    then: YUP_VALIDATION.password,
  }),
});

export const Publish: React.VFC<{
  portfolio: (Portfolio & Status) | undefined;
  updatePortfolio?(data: (Portfolio & Status) | undefined): void;
  userId: string | undefined;
  onClose(): void;
  publishModal: boolean;
  published: boolean;
}> = ({ portfolio, userId, onClose, updatePortfolio, publishModal, published }) => {
  const dispatch = useDispatch();
  const [disablePublish, setDisablePublish] = useState<boolean>(true);
  const [privacy, setPrivacy] = useState<boolean>(true);
  const [publishCompleteModal, setPublishCompleteModal] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const methods = useForm<PortfolioInputs>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { downloadPDF } = useDownloadPDF();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (!portfolio) return;
    methods.setValue('status', portfolio.status);
    methods.setValue('privacy', portfolio.privacy);
    setDisablePublish(!portfolio.privacy);
    setPrivacy(!portfolio.privacy);
    if (portfolio.status === 'password') {
      setPasswordVisible(true);
      methods.setValue('password', portfolio.password);
    }
  }, [portfolio]);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      setPasswordVisible(value.status === 'password');
      setDisablePublish(!value['privacy']);
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const submit: SubmitHandler<Portfolio> = (data) => {
    if (!userId) return;
    (async () => {
      dispatch(toggleLoading(true));
      try {

        const copy = cloneDeep(portfolio);
        if (copy) {
          copy.status = data.status;
          copy.privacy = data.privacy;
          copy.password = data.password;
          copy.publish = 1;
        }
        const res: AxiosResponse<Portfolio & Status> = await new PortfolioApi().patchPortfolioEdit(
          userId,
          copy
        );
        if (!res) {
          dispatch(notificationError('error'));
          return;
        }
        await new PortfolioApi().postPortfolioPublish(userId);
        const portfolioResponse = await new PortfolioApi().getPortfolioEdit(userId);
        if (updatePortfolio) updatePortfolio(portfolioResponse.data);
        onClose();
        setPublishCompleteModal(true);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        dispatch(toggleLoading(false));
      }
    })();
  };

  useEffect(() => {
    if (!publishModal) return;
    if (published && !privacy && portfolio) {
      onClose();
      submit(portfolio);
    }
  }, [publishModal]);

  const isPublish = useMemo<boolean>(() => {
    if(published) {
      return true;
    } else {
      return methods.watch('status') !== 'closed';
    }
  }, [methods.watch('status'), published]);

  const publishMessage = useMemo<string>(() => {
    if(published) {
      return '更新されました！';
    } else {
      if(methods.watch('status') !== 'closed') {
        return '公開されました！';
      } else {
        return '保存されました。';
      }
    }
  }, [methods.watch('status'), published]);

  return (
    <>
      {/* 公開設定モーダル */}
      <Modal
        isOpen={publishModal}
        isClose={onClose}
        className={'modal-contents_add w-784 sm:pb-80 lg:px-80 md:px-80 sm:px-24'}
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'publish-portfolio-setting_step_1',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <h1 className="mbx-typography--heading_2 mt-80 sm:mt-48">ポートフォリオの公開設定</h1>

              <p className="mt-24 mbx-typography--body_1 sm:pl-24 sm:pr-24">
                この設定はポートフォリオの「<ButtonTextLink href="/mypage/portfolio/setting">設定</ButtonTextLink>」ページからいつでも変更できます。
              </p>

              <div className="my-64 text-left">
                <h2 className="title mbx-typography--heading_4 mb-24">プライバシー</h2>
                <div className="flex items-center">
                  <FormRadioButtonItem
                    label="パスワードなしで公開"
                    value="public"
                    name="status"
                  />
                  <TooltipMessageMatchbou
                    className="ml-4"
                    message="URLを知っている人は誰でも見ることができます"
                    position={['0', '-94px']}
                  >
                    <ButtonIconButton
                      hitArea="mini"
                      iconName="Info"
                      type="gray"
                      focus={false}
                    />
                  </TooltipMessageMatchbou>
                </div>
                <div className="flex items-center">
                  <FormRadioButtonItem
                    label="パスワードを設定して公開"
                    value="password"
                    name="status"
                  />
                  <TooltipMessageMatchbou
                    className="ml-4"
                    message="URLとパスワードを知っている人は誰でも見ることができます"
                    position={['0', '-94px']}
                  >
                    <ButtonIconButton
                      hitArea="mini"
                      iconName="Info"
                      type="gray"
                      focus={false}
                    />
                  </TooltipMessageMatchbou>
                </div>
                {passwordVisible && (
                  <>
                    <FormContainerTextfield
                      name="password"
                      type="password"
                      placeholder="パスワードを入力してください"
                      className="w-300 ml-32"
                    />
                    <p className="mbx-typography--caption_1 mt-8 mb-16 ml-32">
                      {MESSAGE.password_specifications}
                    </p>
                  </>
                )}
                <FormRadioButtonItem
                  label="公開しない"
                  value="closed"
                  name="status"
                />
              </div>

              <div className="my-64 text-left">
                <h2 className="title mbx-typography--heading_4 mb-24">コンプライアンスについて</h2>

                <p className="text-body_1 my-24">
                  登録するポートフォリオ内の掲載作品に関して、下記事項に同意ください。チェックを入れていただくとWebページがつくられます。
                </p>

                <FormCheckboxItem
                  label="第三者の著作権・肖像権、その他知的財産権を侵害していません。"
                  name="privacy"
                  onChange={(e) => {
                    methods.setValue('privacy', e.target.checked ? 1 : 0);
                  }}
                />
              </div>

              <div className="my-64">
                <BaseButton
                  theme={'primary'}
                  size={'m'}
                  iconName="Publish"
                  disabled={disablePublish}
                >
                  <div className="sm:w-270 md:w-300 lg:w-300">
                    この設定で更新する
                  </div>
                </BaseButton>
              </div>
            </form>
            <div className="w-624 mb-80 m-auto text-left sm:w-full sm:mt-48">
              <div className="p-40 bg-gray-50 text-left">
                <p className="mbx-typography--subhead_1">注意事項</p>
                <ul className="mt-16 mbx-typography--body_2">
                  <li className="flex">
                    <span> オファー機能に「登録中」の場合、ブライバシー設定にかかわらずMATCHBOXスカウトサービス参画企業があなたのポートフォリオを閲覧できます（ご自身でブロックした企業は除く）。現在の登録状況は、<ButtonTextLink href="/mypage/setting">各種設定</ButtonTextLink>で確認できます。</span>
                  </li>
                </ul>
              </div>
            </div>
          </FormProvider>
        </DataLayerPushContainer>
      </Modal>

      {/* 公開完了モーダル */}
      <Modal
        isOpen={publishCompleteModal}
        isClose={() => setPublishCompleteModal(false)}
        className={'modal-contents_add w-624'}
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'publish-portfolio-setting_step_2',
          }}
        >
          <FormProvider {...methods}>
            <div
              className="sm:pb-80 lg:p-80 md:p-80 sm:py-32 sm:px-24 bg-cover bg-no-repeat bg-center"
              style={{ backgroundImage: isPublish ? `url(/images/lively_bg.png)` : 'none' }}
            >
              <form onSubmit={methods.handleSubmit(submit)}>
                <h1 className="mbx-typography--heading_2 mb-80 sm:mb-24">
                  {publishMessage}
                </h1>

                <div className="space-y-24">
                  {published ? (
                    <ButtonTextLink href="/mypage/portfolio/setting">
                      公開設定を確認
                    </ButtonTextLink>
                  ) : (
                    <ButtonTextLink href={`https://${location.host}/portfolio/${userId}`}>
                      Webページで確認する
                    </ButtonTextLink>
                  )}
                  <div className="button-area">
                    <BaseButton
                      theme="secondary"
                      size={'m-for-sp'}
                      iconName="Download"
                      download={`pf-preview.pdf`}
                      forceExternal={true}
                      onClick={() => {
                        push({
                          event: 'fileDownload',
                          actionType: 'file_download',
                          actionName: 'ポートフォリオ',
                        });
                        downloadPDF();
                      }}
                      href={'/api/v2/portfolio/download-as-one'}
                    >
                      <div>PDFをダウンロード</div>
                    </BaseButton>
                    {published &&
                      <BaseButton
                        theme="primary"
                        size={'m-for-sp'}
                        forceExternal={true}
                        href={`https://${location.host}/portfolio/${userId}`}
                      >
                        <div>Webページで確認する</div>
                      </BaseButton>
                    }
                  </div>

                  <BaseButton
                    theme="link"
                    size={'m'}
                    onClick={() => setPublishCompleteModal(false)}
                  >
                    <div className="sm:w-270 md:w-300 lg:w-300">閉じる</div>
                  </BaseButton>
                </div>
              </form>
            </div>
          </FormProvider>
        </DataLayerPushContainer>
      </Modal>
    </>
  );
};

import { TDictionary } from '@/types/Util';

export const MESSAGE: TDictionary<string> = {
  saving_completed: '保存しました。',
  saving_completed_for_user_profile: 'ユーザー情報を保存しました。',
  saving_completed_for_user_group: 'グループ情報を保存しました。',
  saving_completed_for_password_change: 'パスワードを変更しました。',
  saving_completed_for_email_change: '新しいメールアドレスにメールを送信しました。',
  saving_completed_for_email_change_validate_token: '新しいメールアドレスに変更しました。',
  password_specifications:
    'アルファベットの大文字、小文字、数字、記号（ドット[ . ]、ハイフン[ - ]、アンダーバー[ _ ]のみ）から3種類以上を組み合わせて、8文字以上で入力してください。',
  before_unload: '保存が完了していない内容があります、本当にページ遷移しますか？',
};

import '@/styles/pages/Top/Top.scss';

import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ImgUserIcon from '@/assets/images/profile/circle-placeholder.svg';
import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import { InterviewList } from '@/components/common/Top/InterviewList';
import { Id1 } from '@/components/Interview/Id1';
import { Id2 } from '@/components/Interview/Id2';
import { Id3 } from '@/components/Interview/Id3';
import { items } from '@/utils/top';
import { ConfigContext } from '@/utils/config';
import { State } from '@/redux/state';

export const Interview = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const components = [
    {
      id: '0001',
      component: <Id1 />,
    },
    {
      id: '0002',
      component: <Id2 />,
    },
    {
      id: '0003',
      component: <Id3 />,
    },
  ];

  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);

  const config = useContext(ConfigContext);

  const menu = useMemo(() => {
    if (isAuth) {
      return config.menu;
    } else {
      return [
        {
          title: 'ログイン',
          url: '/login',
        },
      ];
    }
  }, [isAuth]);

  const headerMenu = useMemo(() => {
    if (isAuth) {
      return config.headerMenu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const userIcon = useMemo(() => {
    if (!user) return ImgUserIcon;
    return user?.icon;
  }, [user]);

  const badge = useMemo(() => {
    if (isAuth) {
      return true;
    } else {
      return false;
    }
  }, [isAuth]);

  const children = useMemo(() => {
    if (!isAuth){
      return <BaseButton theme="primary" size="40" className="header-btn" href="/entry">
      MATCHBOXをはじめる
    </BaseButton>;
    }
  }, [isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <section className={`interview page ${isAuth? "interview-auth":""}`}>
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'page_view',
        }}
      >
        <Header
          menu={menu}
          headerMenu={headerMenu}
          userIcon={userIcon}
          badge={badge}
          isAuth={isAuth}
        >
          { children }
        </Header>
        <section className={`max-w-980 m-auto md:px-16 sm:px-16 sm:mt-55 ${!isAuth? "mt-80":""}`}>
          {components.find((c) => c.id === params.id)?.component}
          <section className="mt-36 text-right">
            <p className="text-16_28 sm:text-14_14">サポートエージェント：マイナビクリエイター</p>
          </section>
        </section>
        <InterviewList list={items.careerInterview} title="キャリアインタビュー" />
        <Footer logo={false} />
      </DataLayerPushContainer>
    </section>
  );
};

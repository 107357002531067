import '@/styles/common/Portfolio/ImagePage/type_select.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormContainerTextArea, FormLabel } from '@/components/common/Form/';
import {
    AddButton, AddImage, AddLinkRegister, EmbeddedLink, ImageGeneral, ImageMobiles, ImageResponsive,
    TypeSelect
} from '@/components/common/Portfolio';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { Embed, Image, Mockup, PortfolioPagePPageLayoutEnum } from '@/utils/api-client/index';

import { ModalEditable, ModalEditableBody } from '../../Modal/Editable';

type Props = {
  size?: string;
  name?: string;
  captionName?: string;
  recommendedSize: string;
  onLoadImage?(result: File): void;
  onClickLink?: React.MouseEventHandler<HTMLButtonElement>;
  postImage?: (data: File) => void;
  onChangeType?: (type: 'image' | 'mock') => void;
  layout?: PortfolioPagePPageLayoutEnum;
  setUpdateType: React.Dispatch<React.SetStateAction<'file' | 'change' | undefined>>;
  onBlurCaption?(): void;
  setBeforeUnload(flag: boolean): void;
};

export function Organizer(props: Props): React.ReactElement {
  const [step, setStep] = useState<
    'index' | 'spIndex' | 'imageIndex' | 'singleImage' | 'mockImage' | 'mobileImage' | 'embed'
  >('index');
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [embed, setEmbed] = useState<Embed>();
  const [image, setImage] = useState<Image>();
  const [mock, setMock] = useState<Mockup>();
  const inputRef = useRef<HTMLInputElement>(null);
  const methods = useFormContext();
  const mq = useMBXMediaQuery();
  const onClick = () => {
    if (!props.postImage) {
      inputRef.current?.click();
    } else {
      setStep('imageIndex');
    }
  };

  const onClickLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickLink) {
      props.onClickLink(e);
    } else {
      setStep('embed');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (file: any) => {
    props.onLoadImage && props.onLoadImage(file);
  };

  const selectImageType = (index: number) => {
    switch (index) {
      case 0:
        setStep('singleImage');
        if (props.onChangeType) props.onChangeType('image');
        break;
      case 1:
        setStep('mockImage');
        if (props.onChangeType) props.onChangeType('mock');
        break;
      case 2:
        setStep('mobileImage');
        if (props.onChangeType) props.onChangeType('mock');
        break;
    }
  };

  useEffect(() => {
    if (!methods) return;
    const subscription = methods.watch((value) => {
      const index = props.name?.split('.')[1];
      if (!index) return;
      const content = value.p_contents[parseInt(index)];
      if (content?.embed !== undefined) {
        setStep('embed');
        setEmbed(content as Embed);
      }
      if (content?.f_id !== undefined) {
        if (content?.f_id === '') {
          if (content?.caption === '') {
            setStep('index');
          }
        } else {
          setStep('singleImage');
          setImage(content as Image);
        }
      }
      if (content?.m_type === 'sp') {
        setStep('mobileImage');
        setMock(content as Mockup);
      }
      if (content?.m_type === 'all') {
        setStep('mockImage');
        setMock(content as Mockup);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, props.name]);

  const onUploadedResponsiveImages = (images: (Image | null)[]) => {
    const index = props.name?.split('.')[1];
    if (!index) return;
    props.setUpdateType('file');
    const value = methods.getValues();
    let content = value.p_contents[parseInt(index)];
    if (content.m_type !== undefined && content.f_id === undefined) {
      content.m_contents = images;
      content.m_type = 'all';
    } else {
      content = {
        m_contents: images,
        m_description: content.caption,
        m_type: 'all',
      };
    }
    if (props.name) methods.setValue(props.name, content as Mockup);
  };

  return (
    <>
      {mq.sm ? ( //SP
        <>
          {step === 'index' && (
            <AddButton
              layout={props.layout}
              onClick={() => {
                if (props.layout === PortfolioPagePPageLayoutEnum.StyleV2) {
                  setStep('index');
                } else {
                  setStep('singleImage');
                }
                setShowEditor(true);
              }}
            />
          )}
          {step === 'embed' && (
            <EmbeddedLink
              src={embed?.embed}
              thumbnail={embed?.image?.f_thumbnail}
              editable={false}
              onClick={() => setShowEditor(true)}
            />
          )}
          {step === 'singleImage' && (
            <ImageGeneral
              name={props.name}
              src={image?.f_url}
              editable={false}
              layout={props.layout}
              onClick={() => {
                setShowEditor(true);
              }}
            />
          )}
          {step === 'mockImage' && (
            <ImageResponsive
              name={props.name}
              editable={false}
              onBack={() => setStep('imageIndex')}
              onClick={() => setShowEditor(true)}
              onUploaded={onUploadedResponsiveImages}
            />
          )}
          {step === 'mobileImage' && (
            <ImageMobiles
              name={props.name}
              onBack={() => setStep('imageIndex')}
              editable={false}
              onClick={() => setShowEditor(true)}
              setUpdateType={props.setUpdateType}
            />
          )}

          {showEditor && (
            <ModalEditable>
              <ModalEditableBody
                onClose={() => {
                  setShowEditor(false);
                  if (!image && !embed && !mock) {
                    setStep('index');
                  }
                }}
                bgGray
              >
                <div className="p-24 space-y-24">
                  <div className="">
                    {step === 'index' && (
                      <div className="p-24 bg-white">
                        <TypeSelect size={props.size} onClick={onClick} onClickLink={onClickLink} />
                      </div>
                    )}
                    {step === 'imageIndex' && (
                      <div className="flex justify-center items-center bg-white w-full h-full p-24">
                        <AddImage onBack={() => setStep('index')} onSelect={selectImageType} />
                      </div>
                    )}
                    {step === 'embed' && (
                      <AddLinkRegister
                        name={props.name}
                        onSend={() => {
                          /** */
                        }}
                        onCancel={() => setStep('index')}
                        setUpdateType={props.setUpdateType}
                      />
                    )}
                    {step === 'singleImage' && (
                      <ImageGeneral
                        name={props.name}
                        onBack={() => setStep('imageIndex')}
                        onLoadImage={onChange}
                        switch={props.layout === PortfolioPagePPageLayoutEnum.StyleV2}
                        layout={props.layout}
                        caption={props.recommendedSize}
                        editable
                      />
                    )}
                    {step === 'mockImage' && (
                      <div className="flex-column justify-center items-center w-full h-full">
                        <ImageResponsive
                          name={props.name}
                          onBack={() => setStep('imageIndex')}
                          editable
                          onUploaded={onUploadedResponsiveImages}
                        />
                      </div>
                    )}
                    {step === 'mobileImage' && (
                      <div className="flex-column justify-center items-center w-full h-full">
                        <ImageMobiles
                          name={props.name}
                          onBack={() => setStep('imageIndex')}
                          editable
                          setUpdateType={props.setUpdateType}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <FormLabel label="説明文" type="small" />
                    <FormContainerTextArea
                      className=""
                      rows={3}
                      maxLength={130}
                      name={props.captionName || ''}
                      placeholder={`例）サイトリニューアル前後のデザイン。直感的に必要な情報を選択できるよう構成、配色にこだわった。（最大${130}文字）`}
                      onBlur={() => {
                        if (props.onBlurCaption) props.onBlurCaption();
                      }}
                      onFocus={() => props.setBeforeUnload(true)}
                    />
                  </div>
                </div>
              </ModalEditableBody>
            </ModalEditable>
          )}
        </>
      ) : (
        //PC
        <>
          {step === 'index' && (
            <div className="flex justify-center items-center bg-white w-full h-full">
              <section className="mbx-type_select">
                <input
                  className="mbx-type_select_input-file"
                  type="file"
                  ref={inputRef}
                  title="file"
                  name=""
                  id=""
                  onChange={onChange}
                />
                <TypeSelect
                  size={props.size}
                  onClick={onClick}
                  onClickLink={onClickLink}
                  caption="Youtube、Vimeo、SoundCloud、Slideshare、Sketchfabに対応"
                />
              </section>
            </div>
          )}
          {step === 'imageIndex' && (
            <div className="flex justify-center items-center bg-white w-full h-full">
              <AddImage onBack={() => setStep('index')} onSelect={selectImageType} />
            </div>
          )}
          {step === 'embed' && (
            <AddLinkRegister
              name={props.name}
              onSend={() => {
                /** */
              }}
              onCancel={() => setStep('index')}
              setUpdateType={props.setUpdateType}
            />
          )}
          {step === 'singleImage' && (
            <ImageGeneral
              name={props.name}
              onBack={() => setStep('imageIndex')}
              onLoadImage={onChange}
              switch
              caption={props.recommendedSize}
              editable
            />
          )}
          {step === 'mockImage' && (
            <div className="flex-column justify-center items-center w-full h-full">
              <ImageResponsive
                name={props.name}
                onBack={() => setStep('imageIndex')}
                onUploaded={onUploadedResponsiveImages}
                editable
              />
            </div>
          )}
          {step === 'mobileImage' && (
            <div className="flex-column justify-center items-center w-full h-full">
              <ImageMobiles
                name={props.name}
                onBack={() => setStep('imageIndex')}
                editable
                setUpdateType={props.setUpdateType}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

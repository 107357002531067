import '@/styles/pages/Portfolio/TopPreview.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseButton from '@/components/common/Button/BaseButton';
import * as Page from '@/components/common/Page';
import {
    HeaderImage, Introduction, Navigation, ActionHeader, PreviewHeader, Publish, Skills, UserThumbNameJob,
    WorksModule
} from '@/components/common/Portfolio/';
import { PortfolioPDFDownloadButton } from '@/components/common/Portfolio/PDFDownloadButton';
import { PreviewFooter } from '@/components/common/Preview/Footer';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { usePortfolioUser } from '@/hooks/usePortfolioUser';
import Error from '@/pages/Error';
import { Password } from '@/pages/Portfolio';
import { PortfolioItem } from '@/utils/api-client/api';

function TopPreview(props: {
  presentation?: boolean;
  direct?: boolean;
  admin?: boolean;
  demoId?: string;
}): React.ReactElement {
  const type = props.direct ? 'direct' : props.admin ? 'admin' : 'public';
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    userId,
    portfolio,
    errorCode,
    productPath,
    profilePath,
    setPortfolio,
    getPortfolioWithPassword,
    experiences,
    career,
  } = usePortfolioUser(props.presentation, type);
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const linkToEdit = () => history.push(`/mypage/portfolio/edit`);

  const linkToDetail = (id: number) => {
    if (!userId) return;
    let link = `/portfolio/${userId}/${id}/`;
    if (!props.presentation) {
      link = `/mypage/portfolio/${userId}/${id}/`;
    }
    if (props.direct) {
      link = `/direct/portfolio/${userId}/${id}/${props.demoId}${location.search}`;
    }
    if (props.admin) {
      link = `/admin/portfolio/${userId}/${id}/`;
    }
    history.push(link);
  };
  const previewHeader = useMemo(() => {
    if (props.direct) return <h3>MATCHBOX ID：{userId}</h3>;
    if (portfolio?.page_title_logo?.f_url) {
      return <img src={portfolio.page_title_logo.f_url} alt="" />;
    }
    if (portfolio?.page_title && portfolio?.page_title?.length !== 0) {
      return <h3>{portfolio?.page_title}</h3>;
    }
    return '';
  }, [portfolio]);

  const pfItems = useMemo(() => {
    if (!portfolio || !portfolio.items) return [];
    if (props.direct || props.admin) return Array.from(portfolio.items.values());
    return Array.from(portfolio.items.values()).filter((p) => p.i_visible);
  }, [portfolio]);

  const publish = useMemo(() => {
    return portfolio?.publish ? true : false;
  }, [portfolio]);

  // 非公開時のビジュアル
  if (errorCode === '3001' || (portfolio?.status === 'closed' && !portfolio.items)) {
    return <Error {...props} />;
  }

  // パスワード入力画面
  if (!portfolio?.items && errorCode === '3002' && props.presentation) {
    return <Password getPortfolio={getPortfolioWithPassword} />;
  }

  //エラーコードが確定するまで画面は表示しない（不要なdataLayerが送信されるため）
  if (errorCode === null) {
    return <></>;
  }

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <Page.Wrapper
      forPrint
      presentation={props.presentation}
      header={
        <>
          <div className="mbx-top-preview-header sticky top-0 z-10">
            {!props.presentation && (
              <>
                <HeaderTitle title="プレビュー">
                  <div className="mbx-top-preview-wrapper" style={style}>
                    {portfolio?.updated_at && (
                      <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                    )}
                    <div className="ml-16 space-x-24 flex items-center sp_none">
                      <PortfolioPDFDownloadButton />
                      <div className="w-140 sm:w-88 flex">
                        <BaseButton
                          onClick={linkToEdit}
                          className="mbx-top-preview-edit-button w-full"
                          theme="secondary"
                          size="40"
                        >
                          編集する
                        </BaseButton>
                      </div>
                      <div className={cn(!publish ? 'w-186' : 'w-199', "sm:w-88 flex")}>
                        <BaseButton
                          iconName={"Publish"}
                          className="filter drop-shadow-floatingButton"
                          onClick={() => setPublishModal(true)}
                          size="40"
                          theme="primary"
                        >
                          {!publish ? 'Webページをつくる' : 'Webページを更新する'}
                        </BaseButton>
                      </div>
                    </div>
                  </div>
                </HeaderTitle>
                <div className="sp_only">
                  <ActionHeader>
                    <>
                      <div className="children">
                        <PortfolioPDFDownloadButton />
                      </div>
                      <div className="children">
                        <div className="w-140 flex">
                          <BaseButton
                            onClick={linkToEdit}
                            className="mbx-top-preview-edit-button w-full"
                            theme="secondary"
                            size="40"
                          >
                            編集する
                          </BaseButton>
                        </div>
                      </div>
                    </>
                  </ActionHeader>
                </div>
              </>
            )}
          </div>
          <PreviewHeader>{previewHeader}</PreviewHeader>
          <HeaderImage
            imageSrc={
              portfolio?.profile?.main_image.f_url
                ? portfolio?.profile?.main_image.f_url
                : '/images/header-img_default.png'
            }
          />
        </>
      }
      footer={
        <>
          {!props.presentation && (
            <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area sm:mb-144">
              <BaseButton
                theme="primary"
                iconName={"Publish"}
                size={'m-for-sp'}
                onClick={() => setPublishModal(true)}
              >
                {!publish ? 'Webページをつくる' : 'Webページを更新する'}
              </BaseButton>
            </section>
          )}
        </>
      }
    >
      <div className={cn('mbx-top-preview', { 'pb-80': props.presentation })}>
        <UserThumbNameJob profile={portfolio?.profile} direct={props.direct} />

        <Navigation
          menu={[
            { title: '作品', url: props.demoId? `${productPath}/${props.demoId}${location.search}`: `${productPath}${location.search}` },
            { title: 'プロフィール', url: props.demoId? `${profilePath}/${props.demoId}${location.search}`: `${profilePath}${location.search}`, message: 'クリックすると、求職者のプロフィールを見ることができます。' },
          ]}
          activeIndex={0}
        />

        <Page.Column
          main={<Introduction profile={portfolio?.profile} layout="b" />}
          menu={<Skills skills={career?.skills} experiences={experiences} />}
        />

        <ul className="mbx-top-preview-works">
          {pfItems.map((item: PortfolioItem, index: number) => {
            return (
              <li key={index}>
                <WorksModule
                  thumbnail={item.i_image.f_thumbnail}
                  title={item.i_title}
                  category={item.i_tags ? item.i_tags.join(', ') : ''}
                  moveClass={'dragHandleSelector'}
                  onClick={() => linkToDetail(item.i_id)}
                  visible
                  removeClick={() => {
                    console.log('remove');
                  }}
                />
              </li>
            );
          })}
        </ul>

        <PreviewFooter className="folio mt-42" />

        <Publish
          {...{
            portfolio,
            userId,
            publishModal,
            published: publish,
            onClose: () => setPublishModal(false),
            updatePortfolio: (data) => {
              if (data) dispatch(setPortfolio(data));
            },
          }}
        />
      </div>
    </Page.Wrapper>
  );
}

export default TopPreview;

import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ImgIcon from '@/assets/images/profile/company-placeholder.png';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { UserItem } from '@/componentsDirect/Parts/Navigation/User/Item';
import { RootState } from '@/reduxAdmin';
import { isLoggedOut, setLogin } from '@/reduxAdmin/modules/directAuth';
import { notificationError, setPathAfterLogin, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi } from '@/utils/api-client';
import { ResponseError } from '@/utils/api-client/';

type TProps = {
  nodeRef: React.MutableRefObject<null>;
};

/**
 * TODO : メニューのリンク設定
 */
export const AdminMenu = ({ nodeRef }: TProps): React.ReactElement => {
  const API = new DirectApi();
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const history = useHistory();
  const userInfo = useSelector((state: RootState) => state.direct_auth.userInfo);
  const { push } = React.useContext(DataLayerContext);

  const menu = [
    { title: '企業情報設定', url: '/direct/company' },
    { title: 'グループ管理', url: '/direct/user_groups' },
    { title: '社内ユーザー管理', url: '/direct/users' },
    { title: 'ポジション管理', url: '/direct/offer_positions' },
    { title: '個人設定', url: '/direct/user/profile' },
    { title: 'メール配信設定', url: '/direct/conditions' },
  ];

  const onLogout: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    history.push(location.pathname);
    dispatch(toggleLoading(true));
    API.getDirectLogout()
      .then(() => {
        dispatch(isLoggedOut());
        dispatch(setLogin(false));
        dispatch(setPathAfterLogin(undefined));
        push({
          event: 'logout',
          actionType: 'logout',
          actionName: 'logout',
        });
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  // 未ログインでユーザー情報を取得してなければ何も表示しない
  if (!isLogin && !userInfo) {
    return <></>;
  }

  return (
    <div className="mbx-admin-menu" ref={nodeRef}>
      <section className="flex items-center h-112 pl-24 border-b border-gray-300">
        <div className="w-64 h-64 mkt_mask_items">
          <img
            className="rounded-full"
            src={ImgIcon}
            alt='田中 花子'
          />
        </div>
        <div className="ml-12">
          <p className="mbx-typography--body_2 mkt_mask_items">
            {userInfo?.user_info.post ? userInfo?.user_info.post : ''}
          </p>
          <p className="mbx-typography--heading_4 mkt_mask_items">田中 花子</p>
        </div>
      </section>
      {menu?.map((m, index) => (
        <UserItem key={index} to={m.url} disabled>
          {m.title}
        </UserItem>
      ))}
      <UserItem onClick={onLogout} disabled>ログアウト</UserItem>
    </div>
  );
};

import '@/styles/common/Account/PasswordForm.scss';

import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import BaseButton from '@/components/common/Button/BaseButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { MESSAGE } from '@/definition/MESSAGE';

type IFormInputs = {
  password: string;
  passwordAgain: string;
};

type Props = {
  children: ReactElement | string;
  buttonText?: string;
  disabled?: boolean;
  setPassword: React.Dispatch<React.SetStateAction<IFormInputs>>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

function PasswordForm(props: Props): ReactElement {
  const methods = useForm<IFormInputs>({
    defaultValues: {
      password: '',
      passwordAgain: '',
    },
  });

  const { handleSubmit, watch } = methods;

  useEffect(() => {
    const value: IFormInputs = {
      password: watch('password'),
      passwordAgain: watch('passwordAgain'),
    };
    props.setPassword(value);
  }, [watch('password'), watch('passwordAgain')]);

  return (
    <section className="password-change text-center">
      <p className="mbx-typography--body_1 mt-48">{props.children}</p>
      <div className="form w-308 m-auto sm:w-full sm:mb-64">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => console.log(data))}>
            <FormLayoutFieldset className={'mt-24'}>
              <FormLabel label="パスワード" type="small"></FormLabel>
              <FormContainerTextfield
                type={'password'}
                name="password"
                placeholder="パスワードを入力してください"
                className="mkt_mask_items"
              />
            </FormLayoutFieldset>
            <p className="mbx-typography--caption_1 mt-8 mb-16">
              {MESSAGE.password_specifications}
            </p>
            <FormContainerTextfield
              type={'password'}
              name="passwordAgain"
              placeholder="確認のためもう1度入力してください"
              className="mkt_mask_items"
            />
          </form>
        </FormProvider>
        <BaseButton
          size={'m'}
          theme={'primary'}
          className={'btn mt-48 sm:mb-148'}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.buttonText}
        </BaseButton>
      </div>
    </section>
  );
}

export default PasswordForm;

import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormCombinationsFromToTextfield } from '@/componentsDirect/Parts/Form/Combinations/FromToTextfield';
import { FormCombinationsFromToDatepicker }from '@/componentsDirect/Parts/Form/Combinations/FromToDatepicker';
import {
  FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useSearchCondition } from '@/hooksAdmin/useSearchCondition';
import { AdminSearchConditionResponse } from '@/utils/api-client';

type TProps = {
  searchConditionResult?: AdminSearchConditionResponse | undefined;
};

export type TFormValues = {
  mc_experienced_job: {
    job: number;
    job_year: number[];
  }[];
};

/**
 * 検索条件フォーム 基本情報
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */

export const AdminSearchFormBasicInfo = ({ searchConditionResult }: TProps): React.ReactElement => {
  const location = useLocation();
  const { searchCondition } = useSearchCondition();
  const methods = useFormContext<TFormValues>();
  const { control } = methods;

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'mc_experienced_job',
  });

  const experiencedJobBase = {
    job: undefined,
    job_year: [],
  };

  /**
   * 再編集の際はmc_experienced_jobに保持しているデータを反映
   */
  useEffect(() => {
    if (/reedit$/.test(location.search)) {
      if (searchCondition && searchCondition.mc_experienced_job) {
        replace(searchCondition.mc_experienced_job);
      }
    }
  }, []);

  /**
   * 編集の際はAPIからデータを取得するため呼び出し元から取得データを送ってもらいmc_experienced_jobに保持しているデータを反映
   */
  useEffect(() => {
    if (/admin\/conditions\/\d+$/.test(location.pathname)) {
      if (searchConditionResult && searchConditionResult.mc_experienced_job) {
        replace(searchConditionResult.mc_experienced_job);
      }
    }
  }, [searchConditionResult]);

  /**
   * 職種と経験年数 追加処理
   */
  const onAddExperiencedJob = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    append(experiencedJobBase);
  };

  /**
   * 職種と経験年数 削除処理
   */
  const onDeleteExperiencedJob = (index: number) => {
    remove(index);
  };
  return (
    <UserCondition title="基本情報">
      <>
        <div className="mbx-utils-stack-v--22">
          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="MATCHBOX ID" type="small"></FormLabel>
              <FormContainerTextfield
                name="matchbox_id"
                placeholder="例）C12345678"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="広告効果測定用ID" type="small"></FormLabel>
              <FormContainerTextfield
                name="user_entry_id"
                placeholder="例）190328175218151"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormCombinationsFromToDatepicker
              className="pl-24"
              name="registration_date"
              label="アカウント開設日"
              fieldWidthClass="w-126"
              placeholder="yyyy/mm/dd"
              minYesr={2018}
            />

            <FormCombinationsFromToDatepicker
              className="pl-24"
              name="last_login"
              label="最終ログイン日"
              fieldWidthClass="w-126"
              placeholder="yyyy/mm/dd"
              minYesr={2018}
            />
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-376">
              <FormLabel label="ポートフォリオURL" type="small"></FormLabel>
              <FormContainerTextfield
                name="portfolio_url"
                className="mkt_mask_items"
                placeholder="例） matchbox.work/works/match-taro"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="オファー機能への登録" type="small"></FormLabel>
              <FormContainerDropdown
                name="connected_to_mbdirect"
                placeholder="選択してください"
                selectList={DIRECT_FORM_OPTIONS.genericRegistrationStatus}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="氏名" type="small"></FormLabel>
              <FormContainerTextfield
                name="name"
                className="mkt_mask_items"
                placeholder="例） 町田太郎"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="ふりがな" type="small"></FormLabel>
              <FormContainerTextfield
                name="furigana"
                className="mkt_mask_items"
                placeholder="まちだたろう"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-376">
              <FormLabel label="メールアドレス" type="small"></FormLabel>
              <FormContainerTextfield
                name="email"
                className="mkt_mask_items"
                placeholder="例） match.taro@matchbox.com"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="電話番号" type="small"></FormLabel>
              <FormContainerTextfield
                name="tel"
                className="mkt_mask_items"
                placeholder="例） 09012345678"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-376">
              <FormLabel label="都道府県" type="small"></FormLabel>
              <FormContainerDropdown
                name="prefecture"
                className="mkt_mask_items"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.prefecture}
              ></FormContainerDropdown>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="市町村区" type="small"></FormLabel>
              <FormContainerTextfield
                name="address"
                className="mkt_mask_items"
                placeholder="例） 目黒区"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="性別" type="small"></FormLabel>
              <FormContainerDropdown
                name="gender"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.gender}
              ></FormContainerDropdown>
            </FormLayoutFieldset>

            <FormCombinationsFromToTextfield
              className="pl-24 mkt_mask_items"
              name="age"
              label="年齢"
              type="text"
              fieldWidthClass="w-64"
              placeholder="yy"
              unit="歳"
            />

            <FormCombinationsFromToDatepicker
              className="pl-24 mkt_mask_items"
              name="birth_date"
              label="生年月日"
              fieldWidthClass="w-126"
              placeholder="yyyy/mm/dd"
              minYesr={1958}
            />
          </div>
        </div>
        <div className="mt-22">
          <FormLayoutFieldset className="ml-24 w-full">
            <div className="flex items-center pr-36">
              <FormLabel label="職種と経験年数" type="small" className="w-308"></FormLabel>
              <p className="text-12_16 text-gray-600 pb-6">例：「3年以上」で検索したい場合、「3年・4年・5年以上」を全て選択</p>
            </div>
            <div className="mbx-utils-stack-v--8">
              {fields.map((item, index) => {
                return (
                  <div key={item.id} className="flex">
                    <FormContainerDropdown
                      className="w-308 flex flex-col"
                      defaultValue={item.job}
                      name={`mc_experienced_job.${index}.job`}
                      placeholder="職種を選択"
                      selectList={FORM_OPTIONS.preferred_job_type}
                      type="double-l"
                    ></FormContainerDropdown>
                    <FormContainerDropdownMultiple
                      name={`mc_experienced_job.${index}.job_year`}
                      placeholder="経験年数を選択（複数選択可）"
                      selectList={FORM_OPTIONS.experienced_year}
                      className="mkt_non_mask_items flex-1"
                      type="double-r"
                    ></FormContainerDropdownMultiple>

                    <div className="w-36 flex items-center justify-center">
                      <ButtonIconButton
                        type="gray"
                        hitArea="mini"
                        iconName="Clear"
                        focus={false}
                        disabled={fields.length === 1}
                        onClick={() => {
                          if (fields.length !== 1) onDeleteExperiencedJob(index);
                        }}
                      ></ButtonIconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </FormLayoutFieldset>

          <BaseButton
            iconName="Add"
            type="button"
            size="ss"
            theme="link"
            className="ml-24 mt-14"
            onClick={onAddExperiencedJob}
          >
            職種と経験年数を追加
          </BaseButton>
        </div>
        <FormLayoutFieldset className="ml-24 mt-22">
          <FormLabel label="転職希望時期" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_preferred_change_date"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.change_date}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>
      </>
    </UserCondition>
  );
};

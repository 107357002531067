import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { usePagerIndex } from '@/hooks/common/usePagerIndex';

type Props = {
  pageLength: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
  pageList: number[];
  setPageList: React.Dispatch<React.SetStateAction<number[]>> | ((page: number[]) => void);
};

export const Pager = (props: Props): React.ReactElement => {
  const [limit, setLimit] = useState<number>(5);
  const [cener, setCener] = useState<number>(3);
  const mq = useMBXMediaQuery();
  const pagerIndex = usePagerIndex();

  useEffect(() => {
    if (mq.sm) {
      setLimit(4);
      setCener(2);
    }
    onDefaultPageList();
  }, [props.pageLength]);

  const onDefaultPageList = () => {
    const pageBox = [];
    for (let i = 0; i < props.pageLength; i++) {
      pageBox.push(i);
    }
    props.setPageList(pageBox);
  };

  const onPageClick = (number: number) => {
    if (props.page === 0) return;
    if (number > cener) {
      props.setPageList(pagerIndex.onPage(number, cener, limit, props.pageList));
    } else {
      onDefaultPageList();
    }
    props.setPage(number);
  };

  const onPrevClick = () => {
    if (props.page === 0) return;
    if (props.pageList.length - 1 !== limit) {
      if (props.page < cener) {
        onDefaultPageList();
        props.setPage(props.page - 1);
        window.scrollTo(0, 0);
        return;
      }
    }
    props.setPageList(pagerIndex.onPrev(props.page, cener, props.pageList, limit));
    props.setPage(props.page - 1);
  };

  const onNextClick = () => {
    if (props.page === props.pageLength - 1) return;
    const box = props.pageList;
    if(box.length - 1 !== limit) {
      if (props.page + 1 > cener) {
        props.setPageList(pagerIndex.onNext(props.page, cener, props.pageList, limit));
      } else {
        onDefaultPageList();
      }
    }
    props.setPage(props.page + 1);
  };

  const onFirstClick = () => {
    if (props.page === 0) return;
    onDefaultPageList();
    props.setPage(0);
  };

  const onLastClick = () => {
    if (props.pageLength === 0) return;
    if (props.page === props.pageLength - 1) return;
    if (props.pageLength - 1 > limit) {
      const i = (limit + 1) * -1;
      const box = props.pageList.slice(i);
      props.setPageList(box);
    } else {
      onDefaultPageList();
    }
    props.setPage(props.pageLength - 1);
  };
  return (
    <section className="pager">
      <ul className="flex items-center text-12_40 text-blue-700">
        <li
          className={cn(
            'flex',
            'items-center',
            'mr-10',
            'cursor-pointer',
            props.page === 0 && 'opacity-20'
          )}
          onClick={onFirstClick}
        >
          <i className="mbx-icon mbx-icon-First_Page text-10_12"></i>
        </li>
        <li
          className={cn(
            'flex',
            'items-center',
            'mr-20',
            'cursor-pointer',
            props.page === 0 && 'opacity-20'
          )}
          onClick={onPrevClick}
        >
          <i className="mbx-icon mbx-icon-Arrow_Left text-20_20"></i>
        </li>
        {props.pageList.map((p, index) => {
          if (index <= limit) {
            return (
              <li
                key={index}
                className={cn(
                  'mr-24',
                  'underline',
                  'cursor-pointer',
                  props.page === p && 'opacity-20'
                )}
                onClick={() => onPageClick(p)}
              >
                {p + 1}
              </li>
            );
          }
        })}
        <li
          className={cn(
            'flex',
            'items-center',
            'mr-10',
            'cursor-pointer',
            props.page === props.pageLength - 1 && 'opacity-20',
            props.pageLength === 0 && 'opacity-20'
          )}
          onClick={onNextClick}
        >
          <i className="mbx-icon mbx-icon-Arrow_Right text-20_20"></i>
        </li>
        <li
          className={cn(
            'flex',
            'items-center',
            'cursor-pointer',
            props.page === props.pageLength - 1 && 'opacity-20',
            props.pageLength === 0 && 'opacity-20'
          )}
          onClick={onLastClick}
        >
          <i className="mbx-icon mbx-icon-Last_Page text-10_12"></i>
        </li>
      </ul>
    </section>
  );
};

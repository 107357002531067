import { AxiosError } from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  FormCheckboxItem,
  FormContainerDropdown,
  FormContainerDropdownMultiple,
  FormContainerTextArea,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/';
import { FORM_OPTIONS, TFormOption } from '@/definition/FORM_OPTIONS';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { VALIDATION_MESSAGE } from '@/definition/VALIDATION_MESSAGE';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';
import { useCompanyUsers } from '@/hooksDirect/useCompanyUsers';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { RootState } from '@/reduxAdmin';
import { notificationError } from '@/reduxAdmin/modules/utils';
import {
  DirectApi,
  DirectUserResponse,
  Offer,
  ReactionPatchParam,
  ReactionPatchParamNotifyTypeEnum,
  ReactionPostParam,
  ReactionPostParamNotifyTypeEnum,
  ReactionsResponseAllOfReactions,
  ReactionStatusEnum,
  ResponseError,
} from '@/utils/api-client';
import { noDataText } from '@/utils/string';
import { yupResolver } from '@hookform/resolvers/yup';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TInput = {
  rate: string;
  reaction_position: string;
  status: string;
  comment: string;
  notify_all: boolean;
  notify_member: Array<string>;
  notify_group: Array<string>;
};

type Props = {
  isOpen: boolean;
  offer: Offer;
  onClose: Dispatch<SetStateAction<boolean>>;
  asyncCallback?: () => Promise<void>;
  doneOffer: (offerId: number) => void;
};

/**
 * 求職者詳細ページで利用してるピックアップモーダル
 * 求職者詳細ページで利用してるものと一覧で利用してるものは分けている
 */
export const PickUpPatchModal = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { offerPositions, getOfferPositions } = useOfferPositions();
  const { companyUserGroups, getCompanyUserGroups } = useCompanyUserGroups();
  const { companyUsers, getCompanyUsers } = useCompanyUsers();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [initialStatus, setInitialStatus] = useState<ReactionStatusEnum>();
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    rate: YUP_VALIDATION.required,
    status: YUP_VALIDATION.required.test(
      'action',
      VALIDATION_MESSAGE.required_new_value,
      (value) => value !== initialStatus?.toString()
    ),
  });
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      rate: '',
      reaction_position: '',
      status: '',
      comment: '',
      notify_all: false,
      notify_member: [],
      notify_group: [],
    },
  });
  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [reactionId, setReactionId] = useState<number>();
  const [offerPositionsOPTIONS, setOfferPositionsOPTIONS] = useState<TFormOption[]>([]);

  // ====================================================================================================
  //
  // EVENTS
  //
  // ====================================================================================================
  const onPatch = async (data: TInput) => {
    const offerPatchParam = {
      id: props.offer.id?.toString() as string,
      reaction_position: Number(data.reaction_position),
      // rate: Number(data.rate),
    };
    if (reactionId) {
      const reactionParams: ReactionPatchParam = {
        id: reactionId,
        comment: data.comment,
        status: Number(data.status),
        notify_type: 'offer' as ReactionPatchParamNotifyTypeEnum,
        notify_all: data.notify_all,
        notify_member: data.notify_member,
        notify_group: data.notify_group,
      };
      try {
        await new DirectApi().patchDirectOffer(offerPatchParam);
        await new DirectApi().patchDirectReaction(reactionParams);
        if (props.asyncCallback) await props.asyncCallback();
        props.onClose(false);
        if (props.offer.id) props.doneOffer(props.offer?.id);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      }
    } else {
      if (!props.offer.matchbox_id || !props.offer.id) return;
      const reactionPostParam: ReactionPostParam = {
        matchbox_id: props.offer.matchbox_id,
        offer_id: props.offer?.id,
        content: data.comment,
        status: Number(data.status),
        notify_type: 'offer' as ReactionPostParamNotifyTypeEnum,
        notify_all: data.notify_all,
        notify_member: data.notify_member,
        notify_group: data.notify_group,
      };
      try {
        await new DirectApi().patchDirectOffer(offerPatchParam);
        await new DirectApi().postDirectReaction(reactionPostParam);
        if (props.asyncCallback) await props.asyncCallback();
        props.onClose(false);
        props.doneOffer(props.offer?.id);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      }
    }
  };

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const setValues = async () => {
    if (!props.offer.id) return;
    const res = await new DirectApi().getDirectReactions(props.offer.id.toString());
    const reaction = (res.data.reactions as ReactionsResponseAllOfReactions[]).find(
      (r) => r.user?.matchbox_id === user?.user_info.matchbox_id
    );
    setReactionId(reaction?.reaction?.id);
    if (reaction) {
      if (props.offer.rate) {
        methods.setValue('rate', props.offer.rate?.toString());
      }
      if (reaction.reaction?.status) {
        methods.setValue('status', reaction.reaction.status.toString());
        setInitialStatus(reaction.reaction?.status);
      }
      if (reaction.reaction?.comment) {
        methods.setValue('comment', reaction.reaction.comment);
      }
    }
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getCompanyUserGroups();
    getCompanyUsers({});
    if (props.isOpen && !initialized) {
      setValues();
      setInitialized(true);
    } else {
      methods.setValue('rate', '');
      methods.setValue('status', '');
      methods.setValue('comment', '');
      methods.setValue('notify_all', false);
      methods.setValue('notify_member', []);
      methods.setValue('notify_group', []);
    }
  }, [props.isOpen, props.offer, initialized]);

  useEffect(() => {
    // ポジション一覧をOPTIONに変換
    if (offerPositions && offerPositions.length > 0) {
      // activeなポジションのみ
      const activeOfferPositions = offerPositions.filter((position) => position.active);

      const reactionOptions = activeOfferPositions.map((position) => ({
        value: position.id + '',
        children: position.name,
      }));

      setOfferPositionsOPTIONS(reactionOptions);
    }
  }, [offerPositions]);

  useEffect(() => {
    // 一覧取得
    getOfferPositions();
  }, []);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const userGroupOptions = useMemo(() => {
    if (!companyUserGroups) return [];
    const options = companyUserGroups?.map((v) => {
      return {
        value: v.id.toString(),
        children: v.name,
      };
    });

    return options as TFormOption[];
  }, [companyUserGroups]);

  const userOptions = useMemo(() => {
    if (!companyUsers) return [];
    const options = companyUsers?.map((v) => {
      // 自分と同じユーザーは除外
      if (v.matchbox_id === user?.user_info.matchbox_id) return {};
      return {
        value: v.matchbox_id,
        children: `${noDataText(v.name)} (${v.email})`,
      };
    });

    // optionが空のものを除外
    return options?.filter((v) => v.value) as TFormOption[];
  }, [companyUsers]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault
      isOpen={props.isOpen}
      onClose={() => props.onClose(false)}
      className="mbx-direct-users-detail-modal"
    >
      <div className="w-624 p-80 text-left">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onPatch(data))}>
            <h2 className="text-center text-16_24 font-bold mb-30">求職者をピックアップ</h2>

            <div className="mb-38">
              <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">
                マッチ度を設定
                {/* <span className="text-9_16 font-normal">(任意)</span> */}
                <i className="mbx-icon mbx-icon-Info" />
                <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                  オファー時、マッチ度は求職者（候補者）に開示されません。
                </div>
              </h3>

              <FormContainerDropdown
                className="w-160"
                name="rate"
                placeholder="マッチ度を選択"
                selectList={FORM_OPTIONS.offer_rate}
              />
            </div>

            <div className="mb-32">
              <h3 className="text-12_16 font-bold mbx-status_clients__title">
                意見を投稿
                {/* <span className="text-9_16 font-normal">(任意)</span> */}
                <i className="mbx-icon mbx-icon-Info" />
                <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                  オファー時、あなたの意見の投稿は求職者（候補者）に開示されません。
                </div>
              </h3>

              <p className="my-8 text-11_20">この求職者に対するあなたの意見を投稿しましょう。</p>

              <FormContainerDropdown
                className="w-240 my-8"
                name="status"
                placeholder="希望するアクションを選択"
                selectList={FORM_OPTIONS.reaction}
              />

              <p className="my-8 text-11_20">
                社内メンバーに共有したいことを投稿しましょう。最終的に求職者（候補者）へオファーするかどうか判断材料になります。
              </p>

              <FormContainerTextArea
                placeholder="例）経験豊富な候補者です。オファーし、実際に会って話を聞いたみたいと思いますが、いかがでしょうか？"
                maxLength={300}
                name="comment"
                className="my-8"
              />
            </div>

            <div className="mb-32">
              <p className="mbx-typography--caption_1 text-left">
                <span>
                  紹介するポジションを選択してください。オファー時、候補者に開示されます。
                </span>
              </p>
              <FormContainerDropdown
                name="reaction_position"
                className="select mt-8"
                placeholder="ポジションを選択"
                selectList={offerPositionsOPTIONS}
              />
            </div>

            <div className="mb-48">
              <FormCheckboxItem
                label="社内メンバー全員に通知する"
                name="notify_all"
                className="mb-32 w-fit"
                onChange={(e) => {
                  methods.setValue('notify_all', e.target.checked);
                }}
              />

              {!methods.watch('notify_all') && (
                <>
                  <FormLayoutFieldset className="mb-32">
                    <FormLabel label="通知ユーザーを選択" type="small" />
                    <FormContainerDropdownMultiple
                      name="notify_member"
                      placeholder="選択してください（複数選択可）"
                      selectList={userOptions}
                    />
                  </FormLayoutFieldset>

                  <FormLayoutFieldset>
                    <FormLabel label="通知グループを選択" type="small" />
                    <FormContainerDropdownMultiple
                      name="notify_group"
                      placeholder="選択してください（複数選択可）"
                      selectList={userGroupOptions}
                    />
                  </FormLayoutFieldset>
                </>
              )}
            </div>

            <div className="flex justify-between space-x-16">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => props.onClose(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton className="flex-1" size="m" theme="primary" type="submit">
                この内容でピックアップ
              </BaseButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </ModalDefault>
  );
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { PDFDownLoadModal } from '@/componentsAdmin/Portfolio/PDFDownLoadModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { setPortfolioMatchboxId } from '@/reduxAdmin/modules/portfolio';

type TProps = {
  type: 'result' | 'detail'; // 利用シーンを指定 result 検索一覧 detail 詳細ページ
  matchboxId: string;
};

/**
 * ポートフォリオのPDFダウンロードボタン制御用
 */
export const PortfolioPDFDownloadButton = ({ matchboxId, type }: TProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [pdfModal, setPdfModal] = useState(false);

  dispatch(setPortfolioMatchboxId(matchboxId));

  return (
    <>
      {type === 'detail' && (
        <BaseButton
          className="w-full"
          theme="secondary"
          size="s"
          iconName="Download"
          onClick={() => {
            setPdfModal(true);
          }}
        >
          PDFをダウンロード
        </BaseButton>
      )}

      {type === 'result' && (
        <BaseButton
          size="ss"
          theme="link"
          iconName="External_link"
          iconLayout="right"
          onClick={(e) => {
            e.stopPropagation();
            setPdfModal(true);
          }}
        >
          PDF
        </BaseButton>
      )}

      <PDFDownLoadModal
        isOpen={pdfModal}
        onClose={() => setPdfModal(false)}
        onClickCancel={(e) => {
          e.stopPropagation();
          setPdfModal(false);
        }}
      ></PDFDownLoadModal>
    </>
  );
};

import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import {
    SaveSearchConditionsModal
} from '@/components/common/Admin/SearchResult/SaveSearchConditionsModal';
import { SearchConditions } from '@/components/common/Admin/SearchResult/SearchConditions';
import { UserModule } from '@/components/common/Admin/SearchResult/UserModule';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { PickUpModal } from '@/componentsDirect/Users/Detail/PickUpModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { conditionFromQuery } from '@/hooksDirect/common/useDataLayer';
import { useSearchCondition } from '@/hooksDirect/useSearchCondition';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { CandidateData, SearchCondition } from '@/types/DataLayerType';
import {
    DirectApi, DirectSearchConditionModel, DirectUserSearchModel, Offer, ResponseError
} from '@/utils/api-client';
import { genCandidateDataFromDirectUserSearchModel, getQueryParams } from '@/utils/utils';

import { getSortData } from '../../utils/search';

function SearchResult(): React.ReactElement {
  const PAGE_LIMIT = 10;
  const API = new DirectApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const {
    searchCondition,
    fetchSearchUsers,
    searchUsersResult,
    postDirectSearchCondition,
    getSearchCondition,
    searchConditionResult,
    saveSearchCondition,
    // setTestSearchResultData
  } = useSearchCondition();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [pageLength, setPageLength] = useState<number>(0);
  const [numberStart, setNumberStart] = useState<number>(0);
  const [numberEnd, setNumberEnd] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pickupModalOpen, setPickupModalOpen] = useState<boolean>(false);
  const [saveSearchConditionsTitle, setSaveSearchConditionsTitle] = useState<string>('');
  const [searchConditionsSaved, setSearchConditionsSaved] = useState<boolean>(false);
  const [selectedMatchboxId, setSelectedMatchboxId] = useState<string>('');
  const [users, setUsers] = useState<DirectUserSearchModel[]>([]);
  const [savedSearchCondition, setSavedSearchCondition] =
    useState<DirectSearchConditionModel | null>(null);
  const [offerId, setOfferId] = useState<number | undefined>(undefined);
  const [doneIsOpen, setDoneIsOpen] = useState<boolean>(false);
  const { push } = React.useContext(DataLayerContext);
  const params: { demo_id: string } = useParams();

  const offset = useMemo<number>(() => page * PAGE_LIMIT, [page]);

  interface IFormInputs {
    sort_by: string;
  }

  const methods = useForm<IFormInputs>({
    defaultValues: {
      sort_by: 'registration_date-desc',
    },
  });

  const { watch } = methods;

  const query = {
    limit: PAGE_LIMIT,
    offset,
    ...getSortData(methods.getValues('sort_by')),
  };

  useEffect(() => {
    const { search_condition_id, recommended } = getQueryParams(search);

    if (!searchCondition && !search_condition_id) {
      // 検索条件が存在しない場合は検索条件入力ページにリダイレクト
      history.replace(`/direct/search/${params.demo_id}`);
    } else if (search_condition_id) {
      // 保存された検索結果のidから検索結果を取得
      getSearchCondition(search_condition_id, recommended);
    } else if (searchCondition) {
      // 遷移元で一時保存された検索条件から検索
      setSavedSearchCondition(searchCondition);
    }
  }, []);

  useEffect(() => {
    if (searchUsersResult?.users) {
      setUsers(searchUsersResult.users);
      const candidates = searchUsersResult.users.map((user) =>
        genCandidateDataFromDirectUserSearchModel(user)
      );

      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        pagination: `${page + 1}`,
        candidates: candidates.filter(
          (candidate: CandidateData | undefined) => candidate !== undefined
        ) as CandidateData[],
      });
    }
  }, [searchUsersResult]);

  useEffect(() => {
    // 保存された検索条件がfetchされた
    if (searchConditionResult) {
      onSearch(searchConditionResult as DirectSearchConditionModel);
      setSaveSearchConditionsTitle(searchConditionResult.search_condition_name);
      setSavedSearchCondition(searchConditionResult as DirectSearchConditionModel);
      setSearchConditionsSaved(true);
    }
  }, [searchConditionResult]);

  /**
   * ソートが変更されたら再検索
   */
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'sort_by') {
        setPage(0);
        getSearchUsers(0);
        setPageList([]);
        setPageLength(0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (searchUsersResult) {
      const total = Number(searchUsersResult.total);
      setTotal(total);
      setPageLength(Math.ceil(total / PAGE_LIMIT));
    }
  }, [searchUsersResult]);

  useEffect(() => {
    if (searchUsersResult) {
      setNumberStart(page * PAGE_LIMIT + 1);
      setNumberEnd(page * PAGE_LIMIT + searchUsersResult.users.length);
    }
  }, [page, searchUsersResult]);

  useEffect(() => {
    if (searchCondition) {
      const offsetNum = page * PAGE_LIMIT;
      getSearchUsers(offsetNum);
    }
  }, [page]);

  const doneOffer = (offerId: number) => {
    setOfferId(offerId);
    setDoneIsOpen(true);
  }

  const dataLayerSearchCondition = useMemo<SearchCondition>(() => {
    const condition = conditionFromQuery(query, searchCondition as DirectSearchConditionModel);
    condition.srCount = `${total}` || `0`;
    return condition;
  }, [query, searchCondition, total]);

  /**
   * Pagination, DirectSearchUsersResponseから
   * 検索条件などのデータを生成してdataLayerにpush
   */

  const pushSearchResultEvent = (resTotal: number | undefined) => {
    let actionName;
    if (window.location.search === '') {
      //条件入力からの検索
      actionName = `any_conditions_${resTotal ? 'multiple' : '0'}_results`;
    } else {
      //保存した条件からの検索
      actionName = `saved_conditions_${resTotal ? 'multiple' : '0'}_results`;
    }
    push({
      event: 'searchResult',
      actionType: 'search_result',
      actionName,
      ...dataLayerSearchCondition,
    });
  };

  useEffect(() => {
    if (searchCondition) {
      (async () => {
        try {
          const res = await fetchSearchUsers(query);
          pushSearchResultEvent(res?.data.total);
        } catch (e) {
          //
        }
      })();
    }
  }, [searchCondition]);

  const onSetPage = (page: number) => {
    setPage(page);
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      pagination: `${page + 1}`,
    });
  };

  const onSearch = (data: DirectSearchConditionModel) => {
    saveSearchCondition(data);
  };

  /**
   * ユーザー検索条件取得
   */
  const getSearchUsers = (offsetNum: number) => {
    // setTestSearchResultData();
    fetchSearchUsers({
      limit: PAGE_LIMIT,
      offset: offsetNum,
      ...getSortData(methods.getValues('sort_by')),
    });
  };

  const onClickUserModule = async (matchbox_id: string, is_read: boolean) => {
    if (is_read === false) {
      try {
        await API.postDirectSearchUsersRead({ matchbox_id });
      } catch (e) {
        const error = e as AxiosError<ResponseError>;
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      }
    }

    window.open(`/direct/users/${matchbox_id}/${params.demo_id}${location.search}`);
  };

  const onClickOfferPickup = (matchbox_id: string) => {
    setSelectedMatchboxId(matchbox_id);
    setPickupModalOpen(true);
  };

  const onClickChangeSearchConditions = () => {
    return;
    if (searchConditionResult) {
      saveSearchCondition(searchConditionResult as DirectSearchConditionModel);
    }

    history.push(`/direct/search/${params.demo_id}?reedit`);
  };

  const onClickSaveSearchConditions = async () => {
    push({
      event: 'saveConditions',
      actionType: 'save_conditions',
      actionName: 'save_conditions',
      ...dataLayerSearchCondition,
    });
    await postDirectSearchCondition(saveSearchConditionsTitle);
    setSearchConditionsSaved(true);
    setModalOpen(false);
  };

  const pickupCallback = async () => {
    dispatch(toggleLoading(true));
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await new DirectApi().getDirectUserMatchboxId(selectedMatchboxId);
      const offer: Offer[] = res.data.user_admin_offers;
      const copyData = cloneDeep(users);
      const user = copyData.find((c) => c.matchbox_id === selectedMatchboxId);
      if (user?.offers) user.offers = offer;
      setUsers(copyData);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      if (error.response) {
        dispatch(notificationError(error.response.data.error_message));
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const condition = useMemo(() => {
    const condition = cloneDeep(savedSearchCondition);
    if (condition?.offer_status?.includes(6 || 7)) {
      const status = condition.offer_status.filter((s) => {
        if (s !== 6 && s !== 7) {
          return true;
        }
      });
      condition.offer_status = status;
    }
    if (condition?.offer_status?.includes(5)) {
      const status = condition.offer_status.filter((s) => {
        if (s !== 5) {
          return true;
        }
      });
      condition.offer_status = status;
    }

    return condition;
  }, [savedSearchCondition]);

  const selectedCandidate = useMemo<CandidateData | undefined>(() => {
    const user = users.find((user) => user.matchbox_id === selectedMatchboxId);
    if (!user) return;
    return genCandidateDataFromDirectUserSearchModel(user);
  }, [users, selectedMatchboxId]);

  return (
    <AdminPage
      header={<HeaderTitle title="求職者検索  結果一覧" message="このページでは、検索した求職者を一覧で見ることができます。現在見えている求職者情報は全てダミーの情報です。" />}
      disableDataLayerPush
      demoId={params.demo_id}
    >
      <>
        {searchUsersResult && (
          <div className="py-24">
            <div className="flex justify-end">
              <p className="font-bold opacity-20 text-12_14 mb-24 pointer-events-none cursor-auto">
                保存した条件を見る
              </p>
            </div>
            <SearchConditions
              saved={searchConditionsSaved}
              title={saveSearchConditionsTitle}
              conditions={condition}
              onClickChange={onClickChangeSearchConditions}
              onClickSave={() => setModalOpen(false)}
              disabled
            />
            <div className="flex items-center justify-between mt-46">
              <div className="flex items-center justify-start">
                  {searchUsersResult.users.length > 0 && (
                    <div className="text-12_20 mr-40">
                      {numberStart}〜{numberEnd} / {total}件
                    </div>
                  )}
                  {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
                  {searchUsersResult.users.length > 0 && pageLength > 0 && (
                    <Pager page={page} pageLength={pageLength} setPage={onSetPage} setPageList={setPageList} pageList={pageList} />
                  )}
                </div>
              <FormProvider {...methods}>
                <FormContainerDropdown
                  name="sort_by"
                  placeholder="選択してください"
                  className="w-250"
                  selectList={FORM_OPTIONS.search_users_sort_type}
                  disabled
                />
              </FormProvider>
            </div>
            <div className="my-14">
              {users.length > 0 &&
                users.map((item, i) => {
                  return (
                    <>
                      {i === 0 ? (
                        <TooltipMessageMatchbou
                          className="-ml-14 -mr-14"
                          message="このエリアをクリックするとさらに細かい求職者情報ページに遷移します。"
                          position={['0', '-100px']}
                        >
                          <div className='border-4 border-red-700 border-dotted py-4 px-10'>
                            <UserModule
                              type="direct"
                              item={item}
                              key={item.matchbox_id}
                              onClick={() => onClickUserModule(item.matchbox_id, Boolean(item.is_read))}
                              onClickOfferPickup={() => onClickOfferPickup(item.matchbox_id)}
                              demoId={params.demo_id}
                            />
                          </div>
                        </TooltipMessageMatchbou>
                      ) : (
                        <UserModule
                          type="direct"
                          item={item}
                          key={item.matchbox_id}
                          onClick={() => onClickUserModule(item.matchbox_id, Boolean(item.is_read))}
                          onClickOfferPickup={() => onClickOfferPickup(item.matchbox_id)}
                          isDisable
                          demoId={params.demo_id}
                        />
                      )}
                    </>
                  );
                })}
              {searchUsersResult.users.length <= 0 && <BoxEmpty message="検索結果がありません。" />}
            </div>
            <div className="flex items-center justify-start">
              {searchUsersResult.users.length > 0 && (
                <div className="text-12_20 mr-40">
                  {numberStart}〜{numberEnd} / {total}件
                </div>
              )}
              {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
              {searchUsersResult.users.length > 0 && pageLength > 0 && (
                <div className="flex items-center justify-center">
                  <Pager page={page} pageLength={pageLength} setPage={onSetPage} setPageList={setPageList} pageList={pageList} />
                </div>
              )}
            </div>
          </div>
        )}
        <SaveSearchConditionsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          searchConditions={condition}
          onClickSave={onClickSaveSearchConditions}
          onClickCancel={() => setModalOpen(false)}
          setSaveSearchConditionsTitle={setSaveSearchConditionsTitle}
          page={page + 1}
        />
        <PickUpModal
          modalIsOpen={pickupModalOpen}
          setModalIsOpen={setPickupModalOpen}
          matchboxId={selectedMatchboxId}
          asyncCallback={pickupCallback}
          additionalData={selectedCandidate}
          doneOffer={doneOffer}
        />
        <ModalDefault
          isOpen={doneIsOpen}
          onClose={() => setDoneIsOpen(false)}
          className="mbx-direct-users-detail-modal"
        >
          <div className="w-624 py-80">
            <p className="text-16_28 font-bold">ピックアップが完了しました。</p>
            <p className="mt-8 text-12_20">続けてアクションする場合は、このまま候補者管理ページへお進みください。</p>
            <p className="mt-2 text-12_20">※アクションとは「求職者へのオファー」や「マイナビワークスへの相談」などができる機能です。</p>

            <div className="flex justify-between space-x-16 mt-24 px-140">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => setDoneIsOpen(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton
                className="flex-1"
                size="m"
                theme="primary"
                type="button"
                onClick={() => history.push(`/direct/candidates/${offerId}`)}
              >
                候補者管理ページへ
              </BaseButton>
            </div>
          </div>
        </ModalDefault>
      </>
    </AdminPage>
  );
}

export default SearchResult;

import cn from 'classnames';
import React from 'react';

import CompanyPlaceholder from '@/assets/images/common/company_placeholder.svg';
import { convertLineBreak } from '@/utils/string';

type Props = {
  icon?: string;
  comment?: string;
  className?: string;
};

export const MessageFromCompany = (props: Props): React.ReactElement => {
  return (
    <section className={cn(props.className, 'z-20', 'bg-white')}>
      <div className="w-440 border border-blue-700 rounded-4 p-16">
        <div className="flex">
          <div className="w-56 h-56 rounded-full overflow-hidden">
            <img src={props.icon ? props.icon : CompanyPlaceholder} alt="" />
          </div>
          <div className="w-full ml-16 mbx-typography--caption_2">
            <p className="font-bold">マイナビワークスへのメッセージ：</p>
            <p
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: convertLineBreak(props.comment) }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

import '@/styles/pages/page_preview.scss';
import '@/styles/pages/page_columns.scss';

import cn from 'classnames';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';

import ImgUserIcon from '@/assets/images/profile/circle-placeholder.svg';
import Footer from '@/components/common/Footer';
import Header from '@/components/common/Header';
import Modal from '@/components/common/Modal';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { GlobalLoading } from '@/components/Global/Loading';
import { GlobalNotificationErrorPortal } from '@/components/Global/NotificationErrorPortal';
import { GlobalNotificationSavedPortal } from '@/components/Global/NotificationSavedPortal';
import { GlobalNotificationSuccessPortal } from '@/components/Global/NotificationSuccessPortal';
import { GlobalPDFDownloadModalPortal } from '@/components/Global/PDFDownloadModalPortal';
import { GlobalPDFDownloadSnackBarPortal } from '@/components/Global/PDFDownloadSnackBarPortal';
import { MESSAGE } from '@/definition/MESSAGE';
import { useCropper } from '@/hooks/useCropper';
import { setPrepareFrag } from '@/redux/index';
import { State } from '@/redux/state';
import { DataLayerType } from '@/types/DataLayerType';
import { ConfigContext } from '@/utils/config';

import { DataLayerContext } from './DataLayerProviderContainer';

type WrapperProps = {
  children: ReactElement;
  header?: ReactElement;
  footer?: ReactElement;
  type?: 'preview';
  presentation?: boolean;
  forPrint?: boolean;
  isErrorPage?: boolean;
  disableDataLayerPush?: boolean;
};

/**
 * ページ共有の大元のWrapper
 *
 * グローバルなヘッダー及びフッターが挿入され、props の header, footer はページ固有の要素がある場合に指定可能
 * 基本的に全てのページ要素はこのコンポーネントを潜らせる
 */
function Wrapper({
  children,
  header,
  footer,
  type,
  presentation,
  forPrint,
  isErrorPage,
  disableDataLayerPush,
}: WrapperProps): JSX.Element {
  const dispatch = useDispatch();
  const imageCropper = useSelector((state: State) => state.imageCropper);
  const prepareFrag: boolean = useSelector((state: State) => state.prepareFrag);

  const [setCropImage, postImage] = useCropper();
  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);

  const config = useContext(ConfigContext);
  // DataLayerContextはユーザー画面用のため、別のWrapperでContextをprovideしている管理画面からはこのContextを利用できない(pushできない)
  const { push } = useContext(DataLayerContext);
  const [setImage] = useCropper();

  const menu = useMemo(() => {
    if (isAuth) {
      return config.menu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const headerMenu = useMemo(() => {
    if (isAuth) {
      return config.headerMenu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const userIcon = useMemo(() => {
    if (!user) return ImgUserIcon;
    return user?.icon;
  }, [user]);

  const closeModal = () => {
    setImage(null);
  };

  useEffect(() => {
    const event: DataLayerType = {
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      virtualPageName: 'N/A',
    };
    const error: DataLayerType = isErrorPage
      ? {
          errorStatus: 'error',
          pageTitle: 'Error Page',
          pageCategory: 'システム出力',
        }
      : {};

    if (!disableDataLayerPush) {
      push({ ...event, ...error });
    }
    return () => {
      if (prepareFrag) {
        dispatch(setPrepareFrag(false));
      }
    };
  }, []);

  return (
    <section
      className={cn('page', {
        preview: type === 'preview',
        print: forPrint,
      })}
    >
      {!presentation && (
        <>
          <Prompt when={prepareFrag} message={MESSAGE.before_unload} />
          <Header menu={menu} headerMenu={headerMenu} userIcon={userIcon} badge={true} isAuth={isAuth} />
        </>
      )}
      {header}
      <div
        className={cn('mb-contents', 'sm:px-24', 'sms:px-16', 'md:w-624', 'lg:w-960', 'mx-auto')}
      >
        {children}
      </div>
      <GlobalLoading />
      <GlobalPDFDownloadModalPortal />
      <GlobalNotificationErrorPortal />
      <GlobalNotificationSuccessPortal />
      <GlobalNotificationSavedPortal />
      {/* 画像トリミングモーダル */}
      {!presentation && (
        <Modal
          isOpen={imageCropper !== null}
          isClose={closeModal}
          className={'modal-contents_add w-784 lg:h-840 mb:h-840'}
        >
          <CropperModal
            isOpen={imageCropper !== null}
            width={imageCropper?.width}
            height={imageCropper?.height}
            src={imageCropper?.data}
            postImage={postImage}
            onClose={() => setCropImage(null)}
          />
        </Modal>
      )}
      {footer}
      <GlobalPDFDownloadSnackBarPortal />
      <Footer logo={!!presentation} />
    </section>
  );
}

type ColumnProps = {
  main: ReactElement;
  menu: ReactElement;
};

/**
 * ページ共有のColumn
 *
 * 2Columnのページで使用。1Columnも通した方がいい？
 *
 * main : lg:w-624
 * menu : lg:w-288
 */
function Column({ main, menu }: ColumnProps): React.ReactElement {
  return (
    <div className={cn('page_columns')}>
      <div className={cn('main')}>{main}</div>
      <div className={cn('menu')}>{menu}</div>
    </div>
  );
}

export { Wrapper, Column };

import { AxiosError } from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { UserItem } from '@/componentsDirect/Parts/Navigation/User/Item';
import { RootState } from '@/reduxAdmin';
import { setLogin } from '@/reduxAdmin/modules/adminAuth';
import { notificationError, setPathAfterLogin, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi } from '@/utils/api-client';
import { AdminUserResponse, ResponseError } from '@/utils/api-client/';

type TProps = {
  nodeRef: React.MutableRefObject<null>;
  userInfo: AdminUserResponse | null;
};

/**
 * TODO : メニューのリンク設定
 */
export const AdminMenu = ({ nodeRef, userInfo }: TProps): React.ReactElement => {
  const API = new ADMINApi();
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.admin_auth.isLogin);
  const history = useHistory();

  // const menu = [
  //   { title: '求職者CSV取り込み', url: '' },
  //   { title: '解析', url: '' },
  // ];

  const userIconThumbnail = userInfo?.user_info?.icon ? userInfo?.user_info.icon : ImgIcon;

  const onLogout: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dispatch(toggleLoading(true));
    API.getAdminLogout()
      .then(() => {
        dispatch(setLogin(false));
        dispatch(setPathAfterLogin(undefined));
        history.push('/admin/login');
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  // 未ログインでユーザー情報を取得してなければ何も表示しない
  if (!isLogin && !userInfo) {
    return <></>;
  }

  return (
    <div className="mbx-admin-menu" ref={nodeRef}>
      <section className="flex items-center h-112 pl-24 border-b border-gray-300">
        <div className="w-64 h-64">
          <img
            src={userIconThumbnail}
            alt={userInfo?.user_info?.name ? userInfo?.user_info.name : ''}
          />
        </div>
        <div className="ml-12">
          <p className="mbx-typography--heading_4">
            {userInfo?.user_info?.name ? userInfo?.user_info.name : ''}
          </p>
        </div>
      </section>
      {/* {menu?.map((m, index) => (
        <UserItem key={index} to={m.url}>
          {m.title}
        </UserItem>
      ))} */}
      <UserItem onClick={onLogout}>ログアウト</UserItem>
    </div>
  );
};

import { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { FormContainerDropdown, FormContainerTextfield } from '@/components/common/Form/';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { setApplication } from '@/reduxAdmin/modules/directAuth';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, ResponseError } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

type TInput = {
  company_name: string;
  when: string;
};

export const Application = ({ modalIsOpen }: { modalIsOpen: boolean }): React.ReactElement => {
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    company_name: YUP_VALIDATION.required,
    when: YUP_VALIDATION.required,
  });
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      company_name: '',
      when: '',
    },
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: TInput) => {
    const postParam = {
      company_name: data.company_name,
      when: Number(data.when),
    };
    dispatch(toggleLoading(true));
    try {
      await new DirectApi().apiV2DirectDemoRegistrationPut(postParam);
      dispatch(setApplication(2));

      // KARTEに渡す値は？
      // push({
      //   event: 'candidatePickup',
      //   actionType: 'candidate_pickup',
      //   actionName: `${rate}::${status}`,
      //   candidates: additionalData ? [additionalData] : [],
      // });
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  useEffect(() => {
    (async () => {
      const usersRes: AxiosResponse = await new DirectApi().getDirectUsers();
      if (usersRes.data.total) {
        setTotal(usersRes.data.total);
      }
    })();
  }, []);

  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body');
    if (modalIsOpen) {
      bodyEl[0].classList.add('overflow-hidden');
    } else {
      bodyEl[0].classList.remove('overflow-hidden');
      reset();
    }
  }, [modalIsOpen]);

  return (
    <ModalDefault isOpen={modalIsOpen} className="mbx-direct-users-detail-modal">
      <div className="w-784 p-80 text-center">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center">
            <h2 className="text-21_31 font-bold mb-24">MATCHBOXデモ画面にようこそ！</h2>
            <p className="text-14_23">
              デモ画面にて確認できるのはダミーの求職者情報です。
              <br />
              このデモ画面では、どのように求職者情報を見ることができるのかを、ぜひご覧ください。
            </p>
            <div className="w-full p-24 bg-red-100 rounded-4 my-24">
              <p className="text-17_32 font-bold">
                実際の画面で企業からのオファーを待つ求職者数は現在、
              </p>
              <p className="text-red-700 text-12_20 font-bold py-14">
                <span className="text-24_24 pr-8">{Number(total).toLocaleString()}</span>人
              </p>
              <p className="text-11_16">{`（${dayjs().format('YYYY年MM月DD日')}時点）`}</p>
            </div>
            <p className="text-11_16 mb-30">下記、項目を入力の上、デモ画面にお進みください。</p>
            <div className="mb-30">
              <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">
                会社名<span className="text-9_16 font-medium">（必須）</span>
              </h3>
              <FormContainerTextfield
                placeholder="例）株式会社マッチカンパニー"
                name="company_name"
                className="w-308"
              />
            </div>
            <div className="mb-24">
              <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">
                採用希望時期<span className="text-9_16 font-medium">（必須）</span>
              </h3>
              <FormContainerDropdown
                className="w-308"
                name="when"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.change_date}
              />
            </div>
            <div className="flex justify-between space-x-16 w-308">
              <BaseButton className="flex-1" size="m" theme="primary" type="submit">
                送信してデモ画面に進む
              </BaseButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </ModalDefault>
  );
};

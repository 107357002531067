import '@/styles/common/Admin/SearchResult/offer_status.scss';

import React from 'react';

import StarFillIcon from '@/assets/images/common/star_fill_blue.svg';
import StarOutlineIcon from '@/assets/images/common/star_outline_gray.svg';
import { Offer } from '@/utils/api-client';

type Props = {
  offer: Offer | undefined;
  onClickOfferPickup: React.MouseEventHandler<HTMLButtonElement>;
};

export function OfferStatus(props: Props): React.ReactElement {
  const rate = (value: number) => {
    switch (value) {
      case 1:
        return '低';
      case 2:
        return '中';
      case 3:
        return '高';
      default:
        return '';
    }
  };

  return (
    <section className="mbx-offer_status">
      {!props.offer && (
        <button
          className="mbx-offer_status__pickup-button opacity-20 pointer-events-none cursor-auto"
          onClick={(e) => {
            e.stopPropagation();
            props.onClickOfferPickup(e);
          }}
        >
          <img className="icon" src={StarOutlineIcon} alt="" />
          ピックアップする
        </button>
      )}

      {props.offer && props.offer.status === 0 && (
        <div className="mbx-offer_status__label mbx-offer_status__label--farewell">
          回答期限終了
        </div>
      )}

      {props.offer && props.offer.status === 1 && (
        <div className="mbx-offer_status__label mbx-offer_status__label--pickup">
          <img className="icon" src={StarFillIcon} alt="" />
          ピックアップ{rate(Number(props.offer.rate))}
        </div>
      )}

      {props.offer && props.offer.status === 2 && (
        <div className="mbx-offer_status__label mbx-offer_status__label--consultation">
          MWに相談中
        </div>
      )}

      {props.offer &&
        (props.offer.status === 3 || props.offer.status === 6 || props.offer.status === 7) && (
          <div className="mbx-offer_status__label mbx-offer_status__label--offer">オファー中</div>
        )}

      {props.offer && (props.offer.status === 4 || props.offer.status === 5) && (
        <div className="mbx-offer_status__label mbx-offer_status__label--farewell">見送り</div>
      )}
    </section>
  );
}

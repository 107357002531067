import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { BlockList } from '@/componentsAdmin/Company/DataBase/BlockList';
import { BlockModalContainer } from '@/componentsAdmin/Company/DataBase/ModalContainer';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, AdminCompany, BlockCompany, BlockCompanyPostParam, CompanyUser, ResponseError,
    ResponseSuccess, Status
} from '@/utils/api-client';

type TCompany = {
  company: AdminCompany;
  users: CompanyUser[];
};

type TInput = {
  name: string;
};

export const CompanyDataBase = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [company, setCompany] = useState<AdminCompany>();
  const [blockCompanies, setBlockCompanies] = useState<BlockCompany[]>([]);
  const [companies, setCompanies] = useState<BlockCompany[]>([]);
  const params: { company_id: string } = useParams();
  const methods = useForm<TInput>({
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    getBlockCompany();
  }, []);

  const getBlockCompany = async () => {
    const res = await new ADMINApi().getAdminCompany(params.company_id);
    const data = res.data as TCompany & Status;
    setCompany(data.company);
    if (data.company.block_company_id) {
      setBlockCompanies(data.company.block_company_id);
    }
    setPending(false);
  };

  const onSearch = async (data: TInput) => {
    if (data.name === '') return;
    type Response = {
      companies: BlockCompany[];
      total: number;
    };
    const param: BlockCompanyPostParam = {
      limit: 9999,
      offset: 0,
      name: data.name,
    };
    dispatch(toggleLoading(true));
    try {
      const res = await new ADMINApi().postAdminBlockCompanies(param);
      const data = res.data as Response & ResponseSuccess;
      setCompanies(data.companies);
      setModal(true);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const patchCompany = async (company: AdminCompany) => {
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().patchAdminCompany(params.company_id, company);
      getBlockCompany();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onSubmit = async (payload: BlockCompany[]) => {
    const copy = cloneDeep(company);
    if (copy?.block_company_id) {
      copy.block_company_id.push(...payload);
      await patchCompany(copy);
    }
    setModal(false);
    methods.setValue('name', '');
  };

  const onDelete = async (id: string) => {
    if (company?.block_company_id?.length === 1) {
      setWarningModal(true);
      return;
    }
    const copy = cloneDeep(company);
    if (copy?.block_company_id) {
      copy.block_company_id = copy.block_company_id.filter((c) => c.id !== id);
      await patchCompany(copy);
    }
  };

  return (
    <AdminPage header={<HeaderTitle title="企業DBとの紐付け" />}>
      <section>
        <BaseButton
          theme="link"
          iconName="Arrow_Left"
          size="s"
          href={`/admin/companies/${params.company_id}`}
          className="mt-16"
        >
          企業詳細へ戻る
        </BaseButton>
        <section className="w-624 m-auto mt-40">
          <FormProvider {...methods}>
            <form className="flex items-end" onSubmit={handleSubmit((data) => onSearch(data))}>
              <FormLayoutFieldset className="w-full">
                <FormLabel label="企業DBとの紐付け" type="small" />
                <FormContainerTextfield name="name" />
              </FormLayoutFieldset>
              <div className="w-104 ml-16 flex-shrink-0">
                <BaseButton theme="tertiary" size="s" className="w-full -mb-5">
                  企業検索
                </BaseButton>
              </div>
            </form>
          </FormProvider>
        </section>
        <section className="w-624 mt-48 m-auto">
          {!pending && <BlockList blockCompanies={blockCompanies} onDelete={onDelete} isDelete />}
        </section>
        <ModalDefault
          isOpen={modal}
          onClose={() => setModal(false)}
          className="rounded-modalContainer"
        >
          <BlockModalContainer
            companies={companies}
            blockCompanies={blockCompanies}
            onSubmit={onSubmit}
          />
        </ModalDefault>
        <ModalDefault
          isOpen={warningModal}
          onClose={() => setWarningModal(false)}
          className="rounded-modalContainer"
        >
          <section className="w-624 p-80">
            <p className="mbx-typography--body_1">
              必ずいずれかの企業との紐付けが必要です。
              <br />
              紐付けを解除する場合は、新しい企業との紐付けを追加してから 解除してください。
            </p>
            <div className="w-146 mt-24 m-auto">
              <BaseButton
                theme="secondary"
                size="m"
                className="w-full"
                onClick={() => setWarningModal(false)}
              >
                OK
              </BaseButton>
            </div>
          </section>
        </ModalDefault>
      </section>
    </AdminPage>
  );
};

import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import BaseButton from '@/components/common/Button/BaseButton';
import '@/styles/common/Portfolio/ImagePage/image_mobiles.scss';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { toggleLoading } from '@/redux/index';
import { useDispatch } from 'react-redux';
import {
  PortfolioApi,
  Mockup,
  Image,
  FileResponse,
  MockupMTypeEnum,
} from '@/utils/api-client/index';
import { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { delay } from '@/utils/delay';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type Props = {
  onBack: React.MouseEventHandler<HTMLDivElement>;
  name?: string;
  editable?: boolean;
  onClick?(): void;
  setUpdateType: React.Dispatch<React.SetStateAction<'file' | 'change' | undefined>>;
};

export function ImageMobiles({
  onClick,
  onBack,
  name,
  editable,
  setUpdateType,
}: Props): React.ReactElement {
  const [buffer, setBuffer] = useState<string | ArrayBuffer | null>(null);
  const [images, setImages] = useState<(Image | null)[]>([null, null, null]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const methods = useFormContext();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".gif"]
  });
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);
  //親Formのvalueを更新する
  const setValue = (images: (Image | null)[]) => {
    if (name) {
      const value = methods.getValues();
      const names = name.split('.');
      const content = value.p_contents[Number(names[1])];
      (async () => {
        setUpdateType('file');
        await delay(100);
        methods.setValue(name, {
          m_type: MockupMTypeEnum.Sp,
          m_contents: images,
          m_description: content.m_description
        } as Mockup);
      })();
    }
  };
  const onAddImage: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (images.length < 3) {
      const array = [...images];
      array.push(null);
      setImages(array);
      setValue(array);
    }
  };
  const onRemoveImage = (index: number) => {
    if (images.length > 1) {
      const array = [...images];
      array.splice(index, 1);
      setImages(array);
      setValue(array);
    }
  };

  const postImage = async (data: File) => {
    dispatch(toggleLoading(true));
    const res: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
    push({
      event: 'fileUpload',
      actionType: "file_upload",
      actionName: "画像",
    })
    setIsOpen(false);
    const img = [...images];
    img[index] = {
      f_id: res.data.f_id,
      f_url: res.data.f_url,
      f_thumbnail: res.data.f_thumbnail,
    };
    setImages(img);
    setValue(img);
    // 画像アップロード後の保存処理確認
    // バグ報告：画像を追加した時に2,3つ目のSPに画像が追加されない
    dispatch(toggleLoading(false));
  };

  const contentIndex = useMemo(() => {
    const index = name?.split('.')[1];
    return index ? parseInt(index) : undefined;
  }, [name]);

  useEffect(() => {
    const value = methods.getValues();
    if (contentIndex === undefined) return;
    const content = value.p_contents[contentIndex];
    if (content.m_type === 'sp') {
      setImages(content.m_contents);
    }
  }, []);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (contentIndex === undefined) return;
      const content = value.p_contents[contentIndex];
      if (content.m_type === 'sp') {
        //UI更新
        setImages(content.m_contents);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, contentIndex]);

  const onClickScreen = (index: number) => {
    if (!editable) return;
    setIndex(index);
    inputRef.current?.click();
  };

  const onChange = (file: File) => {
    if (!checkFileSize(file.size, 5)) return;

    setIsOpen(true);
    const reader = new FileReader();

    reader.onload = () => {
      setBuffer(reader.result);
    };

    // 画像の読み込み
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (!ref.current) return;
    setHeight(ref.current.clientWidth * (384 / 576));
  }, [ref]);

  useEffect(() => {
    if (acceptedFiles[0]) {
      onChange(acceptedFiles[0]);
    }
  }, [acceptedFiles[0]]);

  return (
    <>
      <div
        className="flex justify-center items-center bg-white mb-18 overflow-hidden"
        style={{ height }}
        ref={ref}
      >
        <input
          className="mbx-image_mobiles_input-file"
          type="file"
          ref={inputRef}
          title="file"
          name=""
          id=""
          {...getInputProps({multiple: false})}
        />
        <section className="mbx-image_mobiles">
          <div className="mbx-image_mobiles_inner">
            {editable ? (
              <>
                {images.map((image, index) => (
                  <div
                    key={index}
                    {...getRootProps({
                      className: 'mbx-image_mobiles_device',
                      onDrop: () => {
                        setIndex(index);
                      },
                    })}
                  >
                    {editable !== undefined && editable === true && (
                      <button
                        className="mbx-image_mobiles_close"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemoveImage(index);
                        }}
                      >
                        <div className="mbx-icon mbx-icon-Circle-large_Clear" />
                      </button>
                    )}

                    <button
                      className="mbx-image_mobiles_input"
                      onClick={() => onClickScreen(index)}
                    >
                      {image === null ? (
                        <div className="mbx-icon mbx-icon-Large32_Plus" />
                      ) : (
                        <img src={image.f_url} alt="" />
                      )}
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <>
                {images.map((image, index) => {
                  return (
                    <div key={index} className="mbx-image_mobiles_device">
                      <button className="mbx-image_mobiles_input">
                        <img src={image?.f_url} alt="" />
                      </button>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          {editable !== undefined && editable === true && (
            <button className="mbx-image_mobiles_add" onClick={onAddImage}>
              <div className="mbx-icon mbx-icon-Large32_Plus" />
            </button>
          )}
          {onClick && <div className="mbx-image_mobiles_cover" onClick={onClick} />}
        </section>
      </div>
      {editable !== undefined && editable === true && (
        <>
          <div className="flex justify-between mb-22">
            <p className="text-caption_2 text-gray-700 sm:w-1/2">
              横{IMAGE_SIZE.mobile.width}px × 縦{IMAGE_SIZE.mobile.height}px推奨、最大5MB
            </p>
            <BaseButton size="s" theme="link" iconName="Change" onClick={onBack}>
              他の見せ方に変更
            </BaseButton>
          </div>
          <CropperModal
            isOpen={isOpen}
            width={IMAGE_SIZE.mobile.width}
            height={IMAGE_SIZE.mobile.height}
            src={buffer}
            postImage={postImage}
            onClose={() => setIsOpen(false)}
          />
        </>
      )}
    </>
  );
}

import React, { ReactElement } from 'react';
import CirclePlaceholder from '@/components/common/OtherComponents/CirclePlaceholder';
import { Profile } from '@/utils/api-client';

type Props = {
  profile: Profile | undefined;
  direct?: boolean;
};

export function UserThumbNameJob({ profile, direct }: Props): ReactElement {
  return (
    <section className="profile-preview-profile">
      <div className="profile-preview-profile__thumb mkt_mask_items">
        <CirclePlaceholder
          src={
            profile?.icon && !direct
              ? profile?.icon
              : '/images/components/other_components/Circle-placeholder.png'
          }
        />
      </div>
      <div className="mbx-top-preview-profile__inner">
        <span className="mbx-typography--body_1 mkt_mask_items">{profile?.job_title}</span>
        <p className="mbx-typography--heading_2 mkt_mask_items">{profile?.name}</p>
      </div>
    </section>
  );
}

import '@/stylesAdmin/header.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';

import ImgLogo from '@/assets/images/common/admin_logo.svg';
import CompanyPlaceholder from '@/assets/images/common/company-logo-placeholder.png';
import ImgIcon from '@/assets/images/profile/company-placeholder.png';
import { AdminMenu } from '@/componentsDirect/Layout/Menu';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { RootState } from '@/reduxAdmin';
import { DirectApi, Offer, Reaction } from '@/utils/api-client';

type TProps = {
  demoId?: string;
  isErrorPage?: boolean;
};

/**
 * Direct用ヘッダー
 */
export const AdminHeader = ({
  demoId,
  isErrorPage,
}: TProps): React.ReactElement => {
  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const userInfo = useSelector((state: RootState) => state.direct_auth.userInfo);
  const [offers, setOffers] = useState<Offer[]>();

  const mq = useMBXMediaQuery();

  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = useRef(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const spIconRef = useRef<HTMLDivElement>(null);
  const [toggle, setToggle] = useState<boolean>(false);

  const onClick = () => {
    setToggle(!toggle);
  };

  const handleClickEvent = (e: { target: EventTarget | null }) => {
    let icon = null;
    if (iconRef.current) icon = iconRef.current;
    if (spIconRef.current) icon = spIconRef.current;
    if (!icon) return;
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target as Node) &&
      icon &&
      !icon.contains(e.target as Node)
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const res = await new DirectApi().getDirectOffers('99999', '0', '', '');
      setOffers(res.data.offers);
    })();
  }, []);

  const isBadge = useMemo(() => {
    const noActionOffers = offers?.filter((offer) => offer.status === 1);
    if (noActionOffers?.length === 0) return false;

    const reactions = noActionOffers?.map((o) => {
      return o.reactions;
    }) as Reaction[];

    return reactions?.some((r) => r?.matchbox_id !== userInfo?.user_info.matchbox_id);
  }, [offers]);

  const menu = [
    { badge: false, children: '求職者検索', url: `/direct/search/${demoId}${location.search}` },
    { badge: isBadge, children: '候補者管理', url: `/direct/candidates/${demoId}`, message: '自社にマッチしそうな求職者の管理や実際にオファーした候補者の管理を行います。お申し込み後ご利用できます。' },
  ];

  return (
    <header className="mbx-admin-header border-b border-gray-300">
      <div className="inner">
        <div className="flex items-center">
          <div className="w-140">
          {isErrorPage ? (
              <a href="https://matchbox.work/direct/">
                <img src={ImgLogo} />
              </a>
            ) : (
              <Link to={`/direct/search/${demoId}${location.search}`}>
                <img src={ImgLogo} />
              </Link>
          )}
          </div>
          {isLogin && (
            <>
              <p className="h-24 ml-24 border-r border-gray-300"></p>
              <div className="flex items-center ml-24">
                <div className="w-24 h-24">
                  <img
                    src={CompanyPlaceholder}
                    className="rounded-full"
                  />
                </div>
                <p className="mbx-typography--subhead_2 ml-6">株式会社マッチカンパニー</p>
              </div>
            </>
          )}
        </div>
        {isLogin && (
          <>
            {mq.lg ? (
              <>
                <ul className="flex mbx-typography--subhead_1 ml-auto mr-64">
                  {menu.map((item, index) => {
                    return (
                      <>
                        {item.message? (
                          <TooltipMessageMatchbou
                            className="-ml-12"
                            message={item.message}
                            position={['54px', '48px']}
                          >
                            <li
                              key={index}
                              className={cn('relative border-4 border-red-700 border-dotted py-4 px-10', {
                                ['ml-54']: index !== 0,
                              })}
                            >
                              <p className='opacity-20 pointer-events-none cursor-auto'>
                                <Link to={item.url}>{item.children}</Link>
                                {item.badge && (
                                  <p className="absolute top-0 -right-10 rounded-full w-6 h-6 bg-red-600"></p>
                                )}
                              </p>
                            </li>
                          </TooltipMessageMatchbou>
                        ) : (
                          <li
                            key={index}
                            className={cn('relative py-8 px-14', {
                              ['ml-54']: index !== 0,
                            })}
                          >
                            <Link to={item.url}>{item.children}</Link>
                            {item.badge && (
                              <p className="absolute top-0 -right-10 rounded-full w-6 h-6 bg-red-600"></p>
                            )}
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
                <div
                  className="w-32 h-32 mr-8 cursor-pointer mkt_mask_items"
                  onClick={onClick}
                  ref={iconRef}
                >
                  <img
                    className="rounded-full"
                    src={ImgIcon}
                    alt={''}
                  />
                </div>
              </>
            ) : (
              <div ref={spIconRef}>
                <ButtonIconButton
                  type="gray"
                  size="large"
                  iconName={toggle ? 'Menu-close' : 'Menu'}
                  onClick={onClick}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div ref={menuRef}>
        <CSSTransition in={toggle} timeout={550} nodeRef={nodeRef}>
          <AdminMenu nodeRef={nodeRef} />
        </CSSTransition>
      </div>
    </header>
  );
};

import '@/styles/pages/Portfolio/ProfileEdit.scss';

import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import * as Page from '@/components/common/Page';
import {
    HeaderImage, PortfolioHeaderEdit, PortfolioNavigation, SkillBox
} from '@/components/common/Portfolio/';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { usePolling } from '@/hooks/usePolling';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { notificationError, toggleLoading, userInfoForceUpdate } from '@/redux';
import { State } from '@/redux/state';
import {
    CareerHistory, CareerHistoryApi, Portfolio, PortfolioApi, Question, ResponseError, Status,
    UserApi, UserInfo, UserPatchRequest
} from '@/utils/api-client';

function ProfileEdit(): React.ReactElement {
  const user = useSelector((state: State) => state.user);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [career, setCareer] = useState<CareerHistory & Status & UserInfo>();
  const dispatch = useDispatch();
  const { push } = useContext(DataLayerContext);
  const mq = useMBXMediaQuery();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paramData, setParam, map, saveFrag, setBeforeUnload] = usePolling(
    PortfolioApi,
    'patchPortfolioEdit',
    undefined,
    1000
  );

  const placeholder =
    '例）○○大学を卒業後、株式会社△△に入社しました。デザインチームに所属し、Illustrator、Photoshopに関しては学生時代から10年以上の使用実績になります。前職在職中はECサイト立ち上げのチームメンバーに抜擢され、サイトのコンセプト作りから全体デザインまで作成しました。私はWebサイトはデザイン性と機能性のバランスが非常に重要だと考えております。「本当に求められるデザイン」を追求し、顧客にも会社にも貢献する所存です。';
  useEffect(() => {
    (async () => {
      if (!user?.user_id) return;
      const portfolio = await new PortfolioApi().getPortfolioEdit(user?.user_id);
      const career = await new CareerHistoryApi().getCareerHistory(user.user_id);
      setPortfolio(portfolio.data);
      setCareer(career.data);
    })();
  }, [user?.user_id]);

  useEffect(() => {
    if (portfolio?.profile) {
      methods.setValue('profile', portfolio.profile);
      if (!portfolio.profile.answers || portfolio.profile.answers.length === 0) {
        methods.setValue('profile.answers', [
          { question: 'わたしの好きなこと', answer: '' },
          { question: '最近の休日の過ごし方', answer: '' },
          { question: '好きな映画', answer: '' },
          { question: '好きな本', answer: '' },
          { question: '好きな音楽', answer: '' },
          { question: '好きな言葉', answer: '' },
          { question: '尊敬する人', answer: '' },
          { question: 'これまでの仕事で1番大変だったこと', answer: '' },
          { question: 'これまでの仕事で1番嬉しかったこと', answer: '' },
          { question: 'わたしが日々大切にしていること', answer: '' },
          { question: 'わたしを一言で表すと', answer: '' },
        ]);
      }
    }
  }, [portfolio]);

  // バナー画像変更
  const bannerImageUpdate = async (file: string) => {
    dispatch(toggleLoading(true));
    try {
      const f = await new PortfolioApi().postFiles(file);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      if (!portfolio?.profile) return;
      const copy: Portfolio = Object.assign(portfolio);
      if (copy.profile) {
        copy.profile.main_image.f_id = f.data.f_id;
        copy.profile.main_image.f_url = f.data.f_url;
        copy.profile.main_image.f_thumbnail = f.data.f_thumbnail;
      }
      patchPortfolio(copy);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // バナー画像削除
  const bannerImageDelete = async () => {
    dispatch(toggleLoading(true));
    try {
      if(!portfolio?.profile?.main_image.f_id) return;
      await new PortfolioApi().deleteFiles(portfolio.profile.main_image.f_id);
      const copy: Portfolio = Object.assign(portfolio);
      if (copy.profile) {
        copy.profile.main_image = {} as any;
      }
      await patchPortfolio(copy);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  //ユーザー画像変更
  const userImageUpdate = async (src: string | null) => {
    if (!user) return;

    // ユーザー情報編集APIの必須項目対応で便宜上ここでは不要な情報もリクエスト
    const data: UserPatchRequest = {
      name: user.name,
      furigana: user.furigana,
      birth_year: user.birth_year,
      birth_month: user.birth_month,
      birth_day: user.birth_day,
      icon: src as any,
      matchbox_id: user.matchbox_id as string,
    };

    dispatch(toggleLoading(true));
    try {
      const result = await new UserApi().patchUser(data);
      if (result.data.user_info) {
        dispatch(userInfoForceUpdate(result.data.user_info));
        push({
          event: 'fileUpload',
          actionType: 'file_upload',
          actionName: '画像',
        });
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const methods = useForm<Portfolio>();
  const { handleSubmit } = methods;

  const upDateIntroduction = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    methods.setValue('profile.introduction', e.target.value);
  };

  const onBlur = () => {
    setBeforeUnload(false);
    const value = methods.getValues();
    const param: Portfolio = Object.assign(value);
    const copy = cloneDeep(portfolio);
    if (copy && copy.profile) {
      copy.profile = param.profile;
      patchPortfolio(copy);
    }
  };

  const patchPortfolio = (payload: Portfolio) => {
    const id = user?.user_id;
    setParam({ userId: id, portfolio: payload });
  };

  const experiences = () => {
    if (!portfolio?.items) return;
    const box: Question[] = [];
    const pages = Array.from(portfolio?.items?.values()).map((item) => {
      return item.i_pages;
    });
    pages.forEach((page) => {
      page.forEach((p) => {
        if (p.p_page_layout === 'questions') {
          p.p_contents?.forEach((c) => {
            if ((c as Question).qa_id === 'b_v2_8') {
              box.push(c as Question);
            }
          });
        }
      });
    });
    const answersArray = box.map((experiences) => {
      return experiences.qa_answer;
    });
    const experiences: string[] = [];
    answersArray.forEach((answers) => {
      answers.forEach((a) => {
        experiences.push(a);
      });
    });
    return experiences.filter((x, s, self) => {
      return self.indexOf(x) === s;
    });
  };

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="profile-edit">
      <Page.Wrapper
        header={
          <>
            <div className={cn({"sticky top-0 z-20": !mq.sm})}>
              <HeaderTitle title={'ポートフォリオ'}>
                <div className="profile-edit-preview-wrapper" style={style}>
                  {portfolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                  )}
                  <div className="ml-16 w-140 sp_none">
                    {user && (
                      <BaseButton
                        theme="secondary"
                        size="40"
                        href={`/mypage/portfolio/${user.user_id}/profile`}
                        className="w-full whitespace-nowrap"
                      >
                        プレビュー
                      </BaseButton>
                    )}
                  </div>
                </div>
              </HeaderTitle>
            </div>
            <HeaderImage
              cameraIcon={true}
              imageSrc={
                portfolio?.profile?.main_image.f_url
                  ? portfolio.profile.main_image.f_url
                  : '/images/header-img_default.png'
              }
              onChange={bannerImageUpdate}
              onClick={bannerImageDelete}
            />
          </>
        }
        footer={
          <>
            {user && (
              <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area">
                <BaseButton
                  theme={'secondary'}
                  size={'m-for-sp'}
                  href={`/mypage/portfolio/${user.user_id}/profile`}
                >
                  プレビューする
                </BaseButton>
              </section>
            )}
          </>
        }
      >
        <>
          <PortfolioHeaderEdit
            imageSrc={user?.icon ? user?.icon : '/images/circle-placeholder.png'}
            onChange={userImageUpdate}
            portFolio={portfolio}
          />
          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${user?.user_id}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={1}
          />
          <Page.Column
            main={
              <>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit((data) => console.log(data))}>
                    <div className="introduction mt-48">
                      <p className="title mbx-typography--heading_4">自己紹介</p>
                      <div className="introduction-input mt-24">
                        <FormContainerTextArea
                          className={'text-area mkt_mask_items'}
                          name={'profile.introduction'}
                          maxLength={300}
                          placeholder={placeholder}
                          onChange={upDateIntroduction}
                          rows={6}
                          onBlur={() => onBlur()}
                          onFocus={() => setBeforeUnload(true)}
                        />
                      </div>
                    </div>
                    <div className="questionnaire mt-48 mb-96 md:mb-0">
                      <p className="title mbx-typography--heading_4">パーソナルアンケート</p>
                      {portfolio?.profile?.answers &&
                        portfolio?.profile?.answers.map((item, index) => {
                          return (
                            <div className="item mt-24" key={index}>
                              <p className="question mbx-typography--body_2">{item.question}</p>
                              <div className="answer mt-10">
                                <FormContainerTextfield
                                  name={`profile.answers.${index}.answer`}
                                  placeholder={'あなたの個性を伝えましょう'}
                                  className="mkt_mask_items"
                                  onBlur={() => onBlur()}
                                  onFocus={() => setBeforeUnload(true)}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="sm:mb-212"></div>
                  </form>
                </FormProvider>
              </>
            }
            menu={
              <>
                <div className={cn("skills pl-48 md:pl-0", mq.sm ? "mt-32" : "mt-48")}>
                  <p className="title mbx-typography--subhead_1">テクニカルスキル</p>
                  <p className="link">
                    <ButtonTextLink href={'/mypage/cv/edit#skill'}>職務経歴書</ButtonTextLink>
                    から編集できます
                  </p>
                  {career?.skills &&
                    career?.skills.map((item, index) => {
                      const level = item.level as 0 | 1 | 2 | 3 | 4 | 5;
                      const experience = item.experience as 1 | 2 | 3 | 4;
                      return (
                        <div className="skill mt-16" key={index}>
                          <SkillBox
                            skillName={item.name ? item.name : ''}
                            level={level}
                            experience={experience}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="experience pl-48 mt-48 md:pl-0 md:mb-96">
                  <p className="title mbx-typography--subhead_1">経験</p>
                  <p className="link">
                    <ButtonTextLink href={'/mypage/portfolio/edit'}>各作品の詳細</ButtonTextLink>
                    から編集できます
                  </p>
                  <p className="text mbx-typography--body_2 mt-16 mkt_mask_items">
                    {experiences()?.join(' / ')}
                  </p>
                </div>
              </>
            }
          />
        </>
      </Page.Wrapper>
    </div>
  );
}

export default ProfileEdit;

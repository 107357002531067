import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { notificationError, setPortfolio, toggleLoading } from '@/redux';
import { State } from '@/redux/state';
import { RootState } from '@/reduxAdmin/';
import { setPortfolio as setPortfolioToAdmin } from '@/reduxAdmin/modules/adminMaster';
import { ResponseError } from '@/utils/api-client/';
import {
    ADMINApi, CareerHistory, CareerHistoryApi, CareerHistorySkills, DirectApi, Portfolio,
    PortfolioApi, Question, Status, UserInfo
} from '@/utils/api-client/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePortfolioUser = (
  presentation: boolean | undefined,
  type: 'direct' | 'public' | 'admin' = 'public'
) => {
  const authUser: UserInfo | null = useSelector((state: State) => state.user);
  let portfolio: (Portfolio & Status) | undefined;
  switch (type) {
    case 'admin':
      portfolio = useSelector((state: RootState) => state.admin_master.portfolio);
      break;
    case 'direct':
      portfolio = useSelector((state: RootState) => state.admin_master.portfolio);
      break;
    default:
      portfolio = useSelector((state: State) => state.portfolio);
      break;
  }
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState<string | null | undefined>(null);
  const [career, setCareer] = useState<CareerHistory & Status & UserInfo>();
  const [skills, setSkills] = useState<CareerHistorySkills[]>();

  const history = useHistory();

  const userId = useMemo((): string | undefined => {
    if (presentation) {
      switch (type) {
        case 'public':
          return history.location.pathname.split('/')[2];
        default:
          return history.location.pathname.split('/')[3];
      }
    } else {
      return authUser?.user_id;
    }
  }, [authUser]);

  useEffect(() => {
    (async () => {
      if (!userId) return;
      if (presentation) {
        let res: AxiosResponse;
        switch (type) {
          case 'direct':
            res = await new DirectApi().getDirectCareerHistory(userId);
            if (res.data) {
              setCareer(res.data);
            }
            break;
          case 'admin':
            res = await new ADMINApi().getAdminCareerHistory(userId);
            if (res.data) {
              setCareer(res.data);
            }
            break;
          case 'public':
            res = await new CareerHistoryApi().getCareerHistorySkills(userId);
            setSkills(res.data.skills);
            break;
        }
      } else {
        const career = await new CareerHistoryApi().getCareerHistory(userId);
        if (career.data) {
          setCareer(career.data);
        }
      }
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      let portfolioResponse: AxiosResponse<Portfolio & Status>;
      if (!userId) return;

      try {
        dispatch(toggleLoading(true));
        if (presentation) {
          switch (type) {
            case 'admin':
              portfolioResponse = await new ADMINApi().getAdminPortfolio(userId);
              break;
            case 'direct':
              portfolioResponse = await new DirectApi().getDirectPortfolio(userId);
              break;
            default:
              portfolioResponse = await new PortfolioApi().getPortfolio(userId);
              break;
          }
        } else {
          portfolioResponse = await new PortfolioApi().getPortfolioEdit(userId);
        }
        setErrorCode(undefined);
        dispatch(setPortfolio(portfolioResponse.data));
        dispatch(setPortfolioToAdmin(portfolioResponse.data));
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) setErrorCode(e.response.data.error_code);
      } finally {
        dispatch(toggleLoading(false));
      }
    })();
  }, [userId]);

  const getPortfolioWithPassword = async (password: string) => {
    if (!userId) return;
    dispatch(toggleLoading(true));
    try {
      let portfolioResponse: AxiosResponse<Portfolio & Status>;
      switch (type) {
        case 'admin':
          portfolioResponse = await new ADMINApi().getAdminPortfolio(userId);
          break;
        case 'direct':
          portfolioResponse = await new DirectApi().getDirectPortfolio(userId, password);
          break;
        default:
          portfolioResponse = await new PortfolioApi().getPortfolio(userId, password);
          break;
      }
      setErrorCode(undefined);
      dispatch(setPortfolio(portfolioResponse.data));
      dispatch(setPortfolioToAdmin(portfolioResponse.data));
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
    dispatch(toggleLoading(false));
  };

  const productPath = useMemo(() => {
    if (presentation) {
      switch (type) {
        case 'public':
          return `/portfolio/${userId}`;
        case 'direct':
          return `/direct/portfolio/${userId}`;
        case 'admin':
          return `/admin/portfolio/${userId}`;
      }
    } else {
      return `/mypage/portfolio/${userId}`;
    }
  }, [presentation]);

  const profilePath = useMemo(() => {
    if (presentation) {
      switch (type) {
        case 'public':
          return `/portfolio/${userId}/profile`;
        case 'direct':
          return `/direct/portfolio/${userId}/profile`;
        case 'admin':
          return `/admin/portfolio/${userId}/profile`;
      }
    } else {
      return `/mypage/portfolio/${userId}/profile`;
    }
  }, [presentation]);

  const experiences: string[] | undefined = useMemo(() => {
    if (!portfolio?.items) return undefined;
    const box: Question[] = [];
    const pages = Array.from(portfolio?.items?.values()).map((item) => {
      return item.i_pages;
    });
    pages.forEach((page) => {
      page.forEach((p) => {
        if (p.p_page_layout === 'questions') {
          p.p_contents?.forEach((c) => {
            if ((c as Question).qa_id === 'b_v2_8') {
              box.push(c as Question);
            }
          });
        }
      });
    });
    const answersArray = box.map((experiences) => {
      return experiences.qa_answer;
    });
    const experiences: string[] = [];
    answersArray.forEach((answers) => {
      answers.forEach((a) => {
        experiences.push(a);
      });
    });
    return experiences.filter((x, s, self) => {
      return self.indexOf(x) === s;
    });
  }, [portfolio]);

  return {
    userId,
    portfolio,
    errorCode,
    productPath,
    profilePath,
    setPortfolio,
    getPortfolioWithPassword,
    experiences,
    career,
    skills,
  };
};

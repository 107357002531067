import '@/styles/common/Preview/preview.scss';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { ResumePreviewPage1 } from '@/components/Resume/Preview/Page1';
import { ResumePreviewPage2 } from '@/components/Resume/Preview/Page2';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import { usePageResumeFetch } from '@/hooks/usePageResume';
import {
    CareerSummaryModel, EducationData, MBResumeResponse, Qualification
} from '@/utils/api-client/api';

export type TFetchDataProps = {
  fetchData: MBResumeResponse;
};

type THistoryData = {
  educations1: EducationData[];
  educations2: EducationData[];
  careers1: CareerSummaryModel[];
  careers2: CareerSummaryModel[];
  qualifications: Qualification[];
};

/**
 * # 7-1-1 履歴書 プレビュー /mypage/resume/preview
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=426%3A42932
 * UI SP https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=685%3A35650
 * API https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-resume
 */
export const PagesResumePreview = (props: {
  admin?: boolean;
  presentation?: boolean;
}): React.ReactElement => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const type = props.admin ? 'admin' : 'public';
  let downloadEndpoint = '';
  switch (type) {
    case 'admin':
      downloadEndpoint = `/api/v2/admin/resume/${path[3]}/download`;
      break;
    default:
      downloadEndpoint = `/api/v2/resume/download`;
      break;
  }

  const { downloadPDF } = useDownloadPDF();
  const { push } = useContext(DataLayerContext);

  return (
    <Wrapper
      type="preview"
      presentation={props.presentation}
      header={
        <div className="sticky top-0 z-10">
          <HeaderTitle title="履歴書">
            <div className="flex justify-end space-x-24">
              <BaseButton
                theme="link"
                size="s"
                download="resume.pdf"
                forceExternal={true}
                onClick={() => {
                  push({
                    event: 'fileDownload',
                    actionType: 'file_download',
                    actionName: '履歴書',
                  });
                  downloadPDF();
                }}
                href={downloadEndpoint}
              >
                <i className="mbx-icon mbx-icon-Download" />
                PDF
              </BaseButton>
              {!props.presentation && (
                <BaseButton
                  className="mbx-top-preview-edit-button"
                  href="/mypage/resume/edit"
                  theme="secondary"
                  size="s"
                >
                  編集する
                </BaseButton>
              )}
            </div>
          </HeaderTitle>
        </div>
      }
    >
      <>
        <PageMainContent {...props} />
        <div className="sm:mb-170"></div>
      </>
    </Wrapper>
  );
};

const PageMainContent = (props: {
  admin?: boolean;
  presentation?: boolean;
}): React.ReactElement => {
  const type = props.admin ? 'admin' : 'public';

  const historyData: THistoryData = {
    educations1: [],
    educations2: [],
    careers1: [],
    careers2: [],
    qualifications: [],
  };

  /**
   * 職歴の入社と退社を分ける
   */
  const separateJoinAndLeave = (data: CareerSummaryModel[], careerLength: number) => {
    const careers = [];
    data.reverse();
    const leaveCount = data.filter((career) => career.c_leave_flag).length;
    for (let i = 0; careers.length < Math.min(careerLength, data.length + leaveCount); i++) {
      careers.push(data[i]);
      if (careers.length < careerLength && data[i].c_leave_flag) {
        careers.push(Object.assign({ isJoin: true }, careersData[i]));
      }
    }
    return careers.reverse();
  };

  const resumeData = usePageResumeFetch(type);

  const educationsData = resumeData?.educations ? Array.from(resumeData?.educations.values()) : [];
  const careersData = resumeData?.careers ? Array.from(resumeData?.careers.values()) : [];
  const qualificationsData = resumeData?.qualifications
    ? Array.from(resumeData?.qualifications.values())
    : [];
  // // テスト用にdummyデータを使う
  // const educationsData = dummyEducationsData;
  // const careersData = dummyCareersData;
  // const qualificationsData = dummyQualificationsData;

  if (educationsData.length >= 25) {
    // 学歴が25件以上の場合
    const educations = educationsData.slice(-25);
    historyData.educations1 = educations.splice(0, 16);
    historyData.educations2 = educations;
  } else if (educationsData.length >= 16) {
    // 学歴が16件以上の場合(2ページ目にまたぐ)
    historyData.educations1 = educationsData.splice(0, 16);
    historyData.educations2 = educationsData;
    const careerLength = 9 - historyData.educations2.length;
    const careers = separateJoinAndLeave(careersData, careerLength);
    historyData.careers2 = careers;
  } else {
    // 学歴が16件未満の場合(1ページ目に収まる)
    historyData.educations1 = educationsData;
    const careerLength = 25 - historyData.educations1.length;
    const careers = separateJoinAndLeave(careersData, careerLength);
    historyData.careers1 = careers.splice(0, 16 - historyData.educations1.length);
    historyData.careers2 = careers;
  }
  if (
    historyData.educations2.length + historyData.careers2.length === 0 &&
    historyData.educations1.length < 16
  ) {
    // 1ページ目に学歴、職歴がおさまる場合
    historyData.qualifications = qualificationsData.splice(-10);
  } else if (historyData.educations2.length + historyData.careers2.length < 9) {
    // 2ページ目にまたぐが資格・免許を表示する余地がある場合
    historyData.qualifications = qualificationsData.splice(
      historyData.educations2.length + historyData.careers2.length - 9
    );
  }

  const lastUpdateDate = resumeData?.user?.last_update_resume
    ? resumeData?.user?.last_update_resume.split(' ')[0]
    : resumeData?.user?.registration_date;
  const date = lastUpdateDate
    ? `${lastUpdateDate.split('-')[0]}年${lastUpdateDate.split('-')[1]}月${
        lastUpdateDate.split('-')[2]
      }日`
    : '';

  return (
    <div className="mbx-preview mbx-utils-stack-v--preview" id="PREVIEW_RESUME">
      <ResumePreviewPage1
        date={date}
        fetchData={resumeData}
        educations={historyData.educations1}
        careers={historyData.careers1}
        secondPageCareersItemLength={historyData.careers2.length}
      />
      <ResumePreviewPage2
        date={date}
        fetchData={resumeData}
        educations={historyData.educations2}
        careers={historyData.careers2}
        qualifications={historyData.qualifications}
        firstPageEducationsItemLength={historyData.educations1.length}
      />
    </div>
  );
};

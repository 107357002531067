import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { ProfilePreview } from '@/pages/Portfolio/';
import { CandidateData } from '@/types/DataLayerType';
import { DirectApi, LastLogin, MyNavi, UserResumeInfo } from '@/utils/api-client';
import { genCandidateData } from '@/utils/utils';

export const PortfolioPreviewProfile = (): React.ReactElement => {
  const matchboxId = useMemo(() => location.pathname.split('/')[3], [location]);
  const [userInfo, setUserInfo] = useState<any>();
  const { push } = React.useContext(DataLayerContext);
  const params: { demo_id: string } = useParams();

  const genUserData = (data: any): CandidateData | undefined => {
    if (!data) return undefined;
    const user: UserResumeInfo = data.user_info;
    const lastLogin: LastLogin[] = data.last_login;
    const registration: string = data.registration_date;
    const mc: MyNavi = data.mc;
    return genCandidateData(
      user.matchbox_id,
      user,
      lastLogin[0],
      registration,
      mc,
      data.user_admin_offers,
      data.user_admin_reactions
    );
  };

  useEffect(() => {
    (async () => {
      const userRes: AxiosResponse = await new DirectApi().getDirectUserMatchboxId(matchboxId);
      setUserInfo(userRes.data);
    })();
  }, []);

  useEffect(() => {
    if (!userInfo) return;
    const candidate = genUserData(userInfo);
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      candidates: candidate ? [candidate] : [],
    });
  }, [userInfo]);

  return <ProfilePreview presentation direct demoId={params.demo_id} />;
};

import '@/styles/common/Portfolio/portfolio_header_edit.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { CropperModal } from '@/components/common/Modal/Cropper';
import CirclePlaceholder from '@/components/common/OtherComponents/CirclePlaceholder';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { usePolling } from '@/hooks/usePolling';
import { Portfolio, PortfolioApi, Profile } from '@/utils/api-client/';

type Props = {
  portFolio: Portfolio | undefined;
  imageSrc?: string;
  onChange: (value: string | null) => void;
};

function PortfolioHeaderEdit(props: Props): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paramData, setParam, response, saveFrag, setBeforeUnload] = usePolling(
    PortfolioApi,
    'patchPortfolioEdit'
  );
  const [showModal, setShowModal] = useState(false);
  const [init, setInit] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState('');
  const { checkFileSize } = useFileLimit();

  const methods = useForm<Profile>({
    defaultValues: {
      name: '',
      job_title: '',
    },
  });
  const { watch } = methods;

  const setValue = (res: Profile) => {
    if (res.name) {
      methods.setValue('name', res.name);
    }

    if (res.job_title) {
      methods.setValue('job_title', res.job_title);
    }
  };
  useEffect(() => {
    if (!init) return;
    if (!props.portFolio) return;
    if (!props.portFolio.profile) return;
    setValue(props.portFolio.profile);
    setInit(false);
  }, [init, props.portFolio, window.location.pathname]);

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (!init) {
        if (!props.portFolio?.profile) return;
        const data = cloneDeep(props.portFolio);
        if (!data.profile) return;
        if (name === 'name' && value.name === null) {
          // xボタンクリック時
          data.profile.name = value.name;
          setParam({
            userId: props.portFolio?.profile?.user_id,
            portfolio: { profile: data.profile },
          });
        }
        if (name === 'job_title' && value.job_title === null) {
          // xボタンクリック時
          data.profile.job_title = value.job_title;
          setParam({
            userId: props.portFolio?.profile?.user_id,
            portfolio: { profile: data.profile },
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, init, props.portFolio]);

  const onBlur = () => {
    setBeforeUnload(false);
    const value = methods.getValues();
    const data = cloneDeep(props.portFolio);
    if (!data?.profile) return;
    data.profile.name = value.name;
    data.profile.job_title = value.job_title;
    setParam({
      userId: props.portFolio?.profile?.user_id,
      portfolio: { profile: data.profile },
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];
    if (!checkFileSize(imageFile.size, 5)) return;

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.addEventListener(
      'load',
      function () {
        setImageSrc(reader.result as string);
        setShowModal(true);
      },
      false
    );
  };

  const onClose = (): void => {
    setShowModal(false);
  };

  const onSave = (dataUri: string): void => {
    setShowModal(false);
    props.onChange(dataUri);
  };

  const onClick = (): void => {
    props.onChange(null);
  }

  return (
    <section className="portfolio-header_edit">
      <div className="inner">
        <div className="edit-area">
          <div className="user-img">
            <CropperModal
              isOpen={showModal}
              onClose={onClose}
              width={IMAGE_SIZE.pf_profile.width}
              height={IMAGE_SIZE.pf_profile.height}
              src={imageSrc}
              onSave={onSave}
            />
            <label htmlFor="file_user">
              <input id="file_user" type="file" accept=".jpg, .jpeg, .png, .gif" onChange={onChange}></input>
              <div className="camera-icon">
                <i className="mbx-icon mbx-icon-Camera"></i>
              </div>
            </label>
            {props.imageSrc && (
              <CirclePlaceholder src={props.imageSrc} className="mkt_mask_items" />
            )}
            <div className="pt-10 text-center relative z-10 flex justify-center">
              <p className="text-caption_2 text-gray-700 flex-shrink-0">横232px × 縦232px推奨、最大5MB</p>
            </div>
            {props.imageSrc?.startsWith('http') &&
              <div className="pt-5 text-center relative z-10">
                <div className="base-button isTextLink" onClick={onClick}>
                  <p className="button size-s theme-link flex items-center">
                    <i className="mbx-icon mbx-icon-Trash mr-2"></i>
                    <span className="font-bold label">画像を削除</span>
                  </p>
                </div>
              </div>
            }
          </div>
          <FormProvider {...methods}>
            <form>
              <div className="flex mbx-utils-stack-h--8 input-box">
                <FormLayoutFieldset className="flex-1 input-layout">
                  <FormLabel label="表示名" type="small">
                    <TooltipMessageMatchbou
                      className="-ml-12"
                      message="オファー機能ご利用時、「プロフィール画像」「表示名」は企業に開示されません。"
                      position={['0', '-94px']}
                    >
                      <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                    </TooltipMessageMatchbou>
                  </FormLabel>
                  <FormContainerTextfield
                    name="name"
                    placeholder="なまえ"
                    className="mkt_mask_items"
                    onBlur={() => onBlur()}
                    onFocus={() => setBeforeUnload(true)}
                  />
                </FormLayoutFieldset>
                <FormLayoutFieldset className="flex-1 input-layout">
                  <FormLabel label="肩書き" type="small" className="py-4"></FormLabel>
                  <FormContainerTextfield
                    name="job_title"
                    placeholder="例）Webデザイナー"
                    className="mkt_mask_items"
                    onBlur={() => onBlur()}
                    onFocus={() => setBeforeUnload(true)}
                  />
                </FormLayoutFieldset>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
}

export default PortfolioHeaderEdit;

import React from 'react';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';

export const Expired = (): React.ReactElement => {
  return (
    <AdminPage isErrorPage>
      <section>
        <h1 className="text-heading_2 font-bold mb-24 mt-104">
          お探しのMATCHBOXデモ画面は有効期限が切れています
        </h1>
        <p className="text-body_1 mb-40">
          お探しのMATCHBOXデモ画面は有効期限が切れているため現在利用することができません。
          <br />
          お手数ですが、以下よりお問い合わせください。
        </p>
        <BaseButton size="m" theme="secondary" type="button" href="https://matchbox.work/direct/#demo-entry">
          もう一度デモ画面を見てみる
        </BaseButton>
        <BaseButton size="m" theme="primary" type="button" href="https://survey.mynavi.jp/cre/?enq=fJlHCQO1mWY%3d" className="ml-8">
          <p className="w-180 text-center">お問い合わせ</p>

        </BaseButton>
      </section>
    </AdminPage>
  );
};

import { DirectUserResponse } from '@/utils/api-client/';
import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

export const reducers = {
  setLogin: (state: State, action: PayloadAction<boolean>): State => {
    // Directログイン情報 設定
    if (action.payload) {
      return { ...state, isLogin: action.payload };
    } else {
      return {
        ...state,
        isLogin: action.payload,
        userInfo: null,
      };
    }
  },
  isLoggedIn: (state: State): State => {
    return { ...state, isLoggedIn: true, isLoggedOut: false };
  },
  isLoggedOut: (state: State): State => {
    return { ...state, isLoggedOut: true, isLoggedIn: false };
  },
  setUserInfo: (state: State, action: PayloadAction<DirectUserResponse>): State => {
    // Directユーザー情報 設定
    // https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-direct-user
    return { ...state, userInfo: action.payload };
  },
  setApplication: (state: State, action: PayloadAction<number>): State => {
    return { ...state, applicationStep: action.payload };
  }
};

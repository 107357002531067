import { AxiosResponse } from 'axios';
import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useState, VFC } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { AlertModal } from '@/components/common/Modal/Alert';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { ImageGeneral, LayoutSelect, Organizer, SortableItem } from '@/components/common/Portfolio';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { toggleLoading } from '@/redux/index';
import { State } from '@/redux/state';
import {
    Embed, FileResponse, Image, Mockup, Portfolio, PortfolioApi, PortfolioItem, PortfolioPage,
    PortfolioPagePPageLayoutEnum
} from '@/utils/api-client/index';
import { delay } from '@/utils/delay';
import { updateImages } from '@/utils/updateQuestion';
import {
    closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';

type PortfolioImage = Image & { caption: string };
type FormType = {
  p_contents: (PortfolioImage | Mockup | Embed)[];
};

type ImageType = 'image' | 'mock';

export const PortfolioEditImages: VFC<{
  portfolio?: Portfolio;
  itemId: number;
  setParam: any;
  setBeforeUnload(flag: boolean): void;
}> = ({ portfolio, itemId, setParam, setBeforeUnload }) => {
  const [layout, setLayout] = useState<PortfolioPagePPageLayoutEnum>();
  //methods.watchした際にPATCHするかどうかを判断するフラグ
  const [updateType, setUpdateType] = useState<'file' | 'change' | undefined>(undefined);
  const [image, setImage] = useState<string | ArrayBuffer | null>();
  const [imageTypes, setImageTypes] = useState<ImageType[]>([]);
  const [init, setInit] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const dispatch = useDispatch();
  const mq = useMBXMediaQuery();
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);

  const methods = useForm<FormType>({
    defaultValues: {
      p_contents: [],
    },
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { fields, remove, append, move } = useFieldArray<FormType, `p_contents`, 'id'>({
    control: methods.control,
    name: `p_contents`,
    keyName: 'id',
  });

  const user = useSelector((state: State) => state.user);

  const recommendedSize = useMemo(() => {
    let size = {
      width: 0,
      height: 0,
    };
    switch (layout) {
      case PortfolioPagePPageLayoutEnum.StyleV2:
        size = IMAGE_SIZE.style_v2;
        break;
      case PortfolioPagePPageLayoutEnum.Style2:
        size = IMAGE_SIZE.style_2;
        break;
      case PortfolioPagePPageLayoutEnum.Style4:
        size = IMAGE_SIZE.style_4;
        break;
      case PortfolioPagePPageLayoutEnum.Style8:
        size = IMAGE_SIZE.style_8;
        break;
    }
    return `横${size.width}px × 縦${size.height}px推奨、最大5MB`;
  }, [layout]);

  const item = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio?.items) return undefined;
    return Array.from(portfolio?.items).find((item) => item.i_id === itemId);
  }, [portfolio]);

  const page = useMemo<PortfolioPage | undefined | null>(() => {
    if (!item) return null;
    const page: PortfolioPage | undefined = Array.from(item.i_pages).find(
      (page) => page.p_page_layout !== PortfolioPagePPageLayoutEnum.Questions
    );
    return page;
  }, [item]);

  //サーバーから返却されたresponseを元に画像ページのp_idを取得する
  const pId = useMemo<number | undefined>(() => {
    if (page) {
      return page.p_id;
    }
    if (!portfolio?.items) return undefined;
    const item: PortfolioItem | undefined = Array.from(portfolio.items).find(
      (item) => item.i_id === itemId
    );
    if (!item) return undefined;
    const i_page: PortfolioPage | undefined = Array.from(item.i_pages).find(
      (page) => page.p_page_layout !== PortfolioPagePPageLayoutEnum.Questions
    );
    if (!i_page) return undefined;
    return i_page.p_id;
  }, [portfolio, itemId, page]);

  //Form to API
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (init) return;
      //キーボード入力時のcaptionの変更は保存せず、onBlurイベントに任せる
      if (name?.includes('.caption') && type === 'change') return;
      if (name?.includes('.m_description') && type === 'change') return;
      if (portfolio && layout && (type === 'change' || updateType !== undefined)) {
        const pf: Portfolio | undefined = updateImages(
          portfolio,
          itemId,
          layout,
          value.p_contents,
          pId
        );
        setImageTypes(
          value.p_contents.map((content) =>
            (content as Mockup).m_type !== undefined ? 'mock' : 'image'
          )
        );
        setParam({
          userId: user?.user_id,
          portfolio: pf,
        });
        setUpdateType(undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, user, layout, portfolio, init, pId, updateType]);

  const onBlur = () => {
    setBeforeUnload(false);
    const value = methods.getValues();

    if (portfolio && layout) {
      const pf: Portfolio | undefined = updateImages(
        portfolio,
        itemId,
        layout,
        value.p_contents,
        pId
      );
      setParam({
        userId: user?.user_id,
        portfolio: pf,
      });
      setUpdateType(undefined);
    }
  };

  const set = (page: PortfolioPage) => {
    const imageTypes: ImageType[] = [];
    page.p_contents?.forEach((content, index) => {
      if ((content as Image).f_id !== undefined) {
        //Image
        const image = content as PortfolioImage;
        imageTypes.push('image');
        methods.setValue(`p_contents.${index}` as 'p_contents.0', {
          f_id: image.f_id,
          f_url: image.f_url,
          f_thumbnail: image.f_thumbnail,
          caption: image.caption,
        });
      } else if ((content as Mockup).m_type !== undefined) {
        //Mockup
        const mockup = content as Mockup;
        imageTypes.push('mock');
        methods.setValue(`p_contents.${index}` as 'p_contents.0', {
          m_id: mockup.m_id,
          m_type: mockup.m_type,
          m_contents: mockup.m_contents,
          m_description: mockup.m_description,
        });
      } else if ((content as Embed).embed !== undefined) {
        //Embed
        const embed = content as Embed;
        imageTypes.push('image');
        methods.setValue(`p_contents.${index}` as 'p_contents.0', {
          embed: embed.embed,
          caption: embed.caption,
          image: embed.image,
        });
      }
    });
    setImageTypes(imageTypes);
  };

  useEffect(() => {
    (async () => {
      if (!init) return;
      if (page !== null) {
        setLayout(page?.p_page_layout);
        await delay(100);
        if (page) set(page);
        await delay(100);
        setInit(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    const types: ImageType[] = ['image', 'image'];
    const contents: (PortfolioImage | Mockup | Embed)[] = Array(2)
      .fill(null)
      .map(() => ({
        f_id: '',
        f_url: '',
        f_thumbnail: '',
        caption: '',
      }));
    switch (layout) {
      case PortfolioPagePPageLayoutEnum.StyleV2:
        methods.setValue('p_contents', contents);
        setImageTypes(types);
        break;
      case PortfolioPagePPageLayoutEnum.Style2:
        methods.setValue(
          'p_contents',
          Array(2)
            .fill(null)
            .map(() => ({
              f_id: '',
              f_url: '',
              f_thumbnail: '',
              caption: '',
            }))
        );
        setImageTypes(
          Array(2)
            .fill(null)
            .map(() => 'image')
        );
        break;
      case PortfolioPagePPageLayoutEnum.Style4:
        methods.setValue(
          'p_contents',
          Array(4)
            .fill(null)
            .map(() => ({
              f_id: '',
              f_url: '',
              f_thumbnail: '',
              caption: '',
            }))
        );
        setImageTypes(
          Array(4)
            .fill(null)
            .map(() => 'image')
        );
        break;
      case PortfolioPagePPageLayoutEnum.Style8:
        methods.setValue(
          'p_contents',
          Array(8)
            .fill(null)
            .map(() => ({
              f_id: '',
              f_url: '',
              f_thumbnail: '',
              caption: '',
            }))
        );
        setImageTypes(
          Array(8)
            .fill(null)
            .map(() => 'image')
        );
        break;
      default:
        break;
    }
  }, [layout]);

  const imageHeight = useMemo<number>(() => {
    if(mq.lg) {
      if (layout === 'style-4') return 645;
      if (layout === 'style-8') return 446;
      return 1042;
    } else {
      if (layout === 'style-4') return 623;
      if (layout === 'style-8') return 437;
      return 994;
    }
  }, [layout]);

  const onRemove = (index: number) => {
    setIsAlert(true);
    setSelectedIndex(index);
  };

  const imageSize = useMemo<{ width: number; height: number }>(() => {
    if (layout === 'style-4') return IMAGE_SIZE.style_4;
    if (layout === 'style-8') return IMAGE_SIZE.style_8;
    if (layout === 'style-v-2') return IMAGE_SIZE.style_v2;
    return IMAGE_SIZE.style_2;
  }, [layout]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.id).indexOf(active.id);
      const newIndex = fields.map((field) => field.id).indexOf(over.id);
      setUpdateType('change');
      move(oldIndex, newIndex);
    }
  };

  const onLoadImage = (file: File, index: number) => {
    if (!checkFileSize(file.size, 5)) return;

    setIsOpen(true);
    setSelectedIndex(index);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };

    // 画像の読み込み
    reader.readAsDataURL(file);
  };

  const postImage = async (data: File) => {
    dispatch(toggleLoading(true));
    const res: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
    push({
      event: 'fileUpload',
      actionType: 'file_upload',
      actionName: '画像',
    });

    setIsOpen(false);
    if (selectedIndex !== undefined) {
      const value = methods.getValues();
      const p_content = value.p_contents[selectedIndex];
      setUpdateType('file');
      methods.setValue(`p_contents.${selectedIndex}` as 'p_contents.0', {
        f_id: res.data.f_id,
        f_url: res.data.f_url,
        f_thumbnail: res.data.f_thumbnail,
        caption: (p_content as PortfolioImage).caption,
      });
    }
    dispatch(toggleLoading(false));
  };

  const onChangeType = (type: 'image' | 'mock', index: number) => {
    const it = [...imageTypes];
    it[index] = type;
    setImageTypes(it);
  };

  //キャプションの名前がMockupとImageで異なるので変換する
  const convertedCaptionPropNames = useMemo(() => {
    return imageTypes.map((value) => {
      switch (value) {
        case 'image':
          return 'caption';
        case 'mock':
          return 'm_description';
      }
    });
  }, [imageTypes]);

  const styleUpdate = (style: 'style-v-2' | 'style-2' | 'style-4' | 'style-8') => {
    if (style === layout) return;
    let result = false;
    if (layout) {
      const click = window.confirm(
        '一度設定した画像・動画はレイアウトを変更すると削除されます。\r\nよろしいですか？'
      );
      result = click;
    }
    if (result || !layout) {
      setUpdateType('change');
      if (style === 'style-v-2') setLayout(PortfolioPagePPageLayoutEnum.StyleV2);
      if (style === 'style-2') setLayout(PortfolioPagePPageLayoutEnum.Style2);
      if (style === 'style-4') setLayout(PortfolioPagePPageLayoutEnum.Style4);
      if (style === 'style-8') setLayout(PortfolioPagePPageLayoutEnum.Style8);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p className="mbx-typography--body_1 font-body">
            この作品で他に見せたい画像があれば追加しましょう。数や比率によって4種類からレイアウトを選択できます。
          </p>
          <small className="mbx-typography--caption_1">
            *Aのレイアウトのみ、Youtube、Vimeo、SoundCloud、Slideshare、Sketchfabなどのリンクを埋め込んで表示することができます。
          </small>
          <div className="flex justify-between lg:my-40 md:my-40 sm:my-24 sm:space-x-10">
            <LayoutSelect
              type="a"
              selected={layout === PortfolioPagePPageLayoutEnum.StyleV2}
              onClick={() => {
                styleUpdate('style-v-2');
              }}
            />
            <LayoutSelect
              type="b"
              selected={layout === PortfolioPagePPageLayoutEnum.Style2}
              onClick={() => {
                styleUpdate('style-2');
              }}
            />
            <LayoutSelect
              type="c"
              selected={layout === PortfolioPagePPageLayoutEnum.Style4}
              onClick={() => {
                styleUpdate('style-4');
              }}
            />
            <LayoutSelect
              type="d"
              selected={layout === PortfolioPagePPageLayoutEnum.Style8}
              onClick={() => {
                styleUpdate('style-8');
              }}
            />
          </div>
          {mq.sm && (
            <p className="mbx-typography--body_1 font-body mb-16">
              画像をドラッグして並べ替えできます。
            </p>
          )}

          {layout === PortfolioPagePPageLayoutEnum.StyleV2 && (
            <DndContext
              sensors={mq.sm ? touchSensors : sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              autoScroll={false}
            >
              <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                <div className="sm:space-y-16 sm:mb-16">
                  {fields.map((field, index) => (
                    <SortableItem
                      key={field.id}
                      id={field.id}
                      name={`p_contents.${index}.${convertedCaptionPropNames[index]}` as const}
                      className=""
                      axis="vertical"
                      onRemove={() => onRemove(index)}
                      onBlurCaption={() => onBlur()}
                      setBeforeUnload={setBeforeUnload}
                      StyleV2={true}
                    >
                      <div className={cn({ ['h-384']: !mq.sm, ['mb-72']: !mq.sm })}>
                        <Organizer
                          name={`p_contents.${index}` as const}
                          captionName={
                            `p_contents.${index}.${convertedCaptionPropNames[index]}` as const
                          }
                          recommendedSize={recommendedSize}
                          onLoadImage={(result) => {
                            onLoadImage(result, index);
                          }}
                          postImage={postImage}
                          onChangeType={(type) => onChangeType(type, index)}
                          layout={layout}
                          setUpdateType={setUpdateType}
                          onBlurCaption={() => onBlur()}
                          setBeforeUnload={setBeforeUnload}
                        />
                      </div>
                    </SortableItem>
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          )}

          {layout && layout !== PortfolioPagePPageLayoutEnum.StyleV2 && (
            <DndContext
              sensors={mq.sm ? touchSensors : sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              autoScroll={false}
            >
              <SortableContext items={fields} strategy={rectSortingStrategy}>
                <div className="mb-16 lg:-mx-16 mb:-mx-16 flex-wrap grid-cols-2 grid sm:gap-16">
                  {fields.map((item, index) => (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      name={`p_contents.${index}.${convertedCaptionPropNames[index]}` as const}
                      className={`h-${imageHeight}`}
                      height={imageHeight}
                      axis="horizontal"
                      onRemove={() => onRemove(index)}
                      onBlurCaption={() => onBlur()}
                      setBeforeUnload={setBeforeUnload}
                    >
                      {mq.sm ? (
                        <Organizer
                          name={`p_contents.${index}` as const}
                          captionName={
                            `p_contents.${index}.${convertedCaptionPropNames[index]}` as const
                          }
                          recommendedSize={recommendedSize}
                          onLoadImage={(result) => {
                            onLoadImage(result, index);
                          }}
                          postImage={postImage}
                          onChangeType={(type) => onChangeType(type, index)}
                          layout={layout}
                          setUpdateType={setUpdateType}
                          onBlurCaption={() => onBlur()}
                          setBeforeUnload={setBeforeUnload}
                        />
                      ) : (
                        <ImageGeneral
                          name={`p_contents.${index}` as const}
                          onBack={() => {
                            /** */
                          }}
                          onLoadImage={(result) => {
                            onLoadImage(result, index);
                          }}
                          caption={recommendedSize}
                          editable={true}
                        />
                      )}
                    </SortableItem>
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </form>
      </FormProvider>
      <CropperModal
        isOpen={isOpen}
        width={imageSize.width}
        height={imageSize.height}
        src={image}
        postImage={postImage}
        onClose={() => setIsOpen(false)}
      />
      <AlertModal
        text="画像と説明文を削除しますか？"
        onClickText="削除する"
        isOpen={isAlert}
        onCancel={() => setIsAlert(false)}
        onClick={async () => {
          remove(selectedIndex);
          setUpdateType('change');
          await delay(100);
          append({
            f_id: '',
            f_url: '',
            f_thumbnail: '',
          });
          setIsAlert(false);
        }}
      />
    </>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { setUploadedImage, toggleImageCropper, toggleLoading } from '@/redux/index';
import { State } from '@/redux/state';

import { FileResponse, PortfolioApi } from '../utils/api-client/index';

export const useCropper = () => {
  const dispatch = useDispatch();
  const [res, setRes] = useState<any>();
  const uploadedImage = useSelector((state: State) => state.uploadedImage);
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    setRes(uploadedImage);
  }, [uploadedImage]);

  const setCropImage = (data: any, size?: { width: number; height: number }) => {
    setRes(null);
    dispatch(setUploadedImage(null));
    if (data === null || !checkFileSize(data.size, 5)) {
      dispatch(toggleImageCropper(null));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      dispatch(
        toggleImageCropper({
          data: reader.result,
          width: size?.width || 0,
          height: size?.height || 0,
        })
      );
    };

    // 画像の読み込み
    reader.readAsDataURL(data);
  };

  const postImage = async (data: any) => {
    dispatch(toggleLoading(true));
    try {
      const result: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      dispatch(setUploadedImage(result.data));
    } catch (error) {
      console.error(error);
    }
    dispatch(toggleImageCropper(null));
    dispatch(toggleLoading(false));
  };

  return [setCropImage, postImage, res];
};

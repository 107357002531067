import React from 'react';
import { Profile, ProfileAnswers } from '@/utils/api-client';

const LayoutA = ({ item }: { item: ProfileAnswers }): React.ReactElement => (
  <div>
    {item.answer && (
      <div className="questionnaire mt-24">
        <p className="question mbx-typography--body_1">
          <span>Q. </span>
          <span>{item.question}</span>
        </p>
        <p className="answer mbx-typography--body_1 mt-8">
          <span>A. </span>
          <span className="mkt_mask_items">{item.answer}</span>
        </p>
      </div>
    )}
  </div>
);

const LayoutB = ({ item }: { item: ProfileAnswers }): React.ReactElement => (
  <div>
    {item.answer && (
      <div className="questionnaire mt-24">
        <p className="question mbx-typography--heading_4">
          <span>{item.question}</span>
        </p>
        <p className="answer mbx-typography--body_1 mt-8">
          <span className="mkt_mask_items">{item.answer}</span>
        </p>
      </div>
    )}
  </div>
);

export const Introduction = ({
  profile,
  layout = 'a',
}: {
  profile: Profile | undefined;
  layout?: 'a' | 'b';
}): React.ReactElement => {
  return (
    <>
      {profile?.introduction && (
        <div className="introduction mt-48">
          <p className="title mbx-typography--heading_4">自己紹介</p>
          <p className="mbx-typography--body_1 mt-24 mkt_mask_items">{profile?.introduction}</p>
        </div>
      )}
      <div className="personal mt-48">
        {profile?.answers && profile?.answers?.filter(answer=>answer.answer !== "").length > 0 &&
          <>
            {layout === 'a' && <p className="title mbx-typography--heading_4">パーソナルアンケート</p>}
            {profile?.answers?.map((item, index) =>
              layout === 'a' ? <LayoutA item={item} key={index} /> : <LayoutB item={item} key={index} />
            )}
          </>
        }
      </div>
    </>
  );
};

import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { RootState } from '@/reduxAdmin/';
import { useSelector } from 'react-redux';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import {
    DirectSearchFormBasicInfo, DirectSearchFormReset, DirectSearchFormSectionDesired,
    DirectSearchFormSectionEmploymentStatus, DirectSearchFormSectionFreeWord,
    DirectSearchFormSectionOffer, DirectSearchFormSectionQualifications,
    DirectSearchFormSectionUpdates, DirectSearchFormSectionWorkExperience
} from '@/componentsDirect/Search';
import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';
import { useSearchCondition } from '@/hooksDirect/useSearchCondition';
import {
    useSearchConditionValidation, validationSchemaForPost
} from '@/hooksDirect/useSearchConditionValidation';
import { DirectSearchConditionModel } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Direct 求職者検索 入力フォーム
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 *
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
 */
export const DirectSearchForm = (): React.ReactElement => {
  const params: { demo_id: string } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { searchCondition, saveSearchCondition } = useSearchCondition();
  const { initValues, isChanged, searchConditionChangeDetection } = useSearchConditionValidation();

  const methods = useForm<DirectSearchConditionModel>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaForPost),
    defaultValues: initValues,
  });
  const { handleSubmit, reset, watch, control } = methods;
  const { isValid } = useFormState({ control });
  const { push } = React.useContext(DataLayerContext);
  const applicationStep = useSelector((state: RootState) => {
    return state.direct_auth.applicationStep;
  });

  /**
   * 再編集の際は保持しているデータをフォームに反映
   * ただし mc_experienced_job は useFieldArray で反映するので
   * コンポーネント側で対応
   */
  useEffect(() => {
    if (/reedit$/.test(location.search)) {
      if (searchCondition) {
        setDefaultValues(searchCondition);
      }
    }
  }, []);

  /**
   * 設定した条件をreduxに保持して結果ページに移動
   */
  const onSubmit: SubmitHandler<DirectSearchConditionModel> = () => {
    //検索結果は常に一緒になるようにするために検索条件をデフォルトに変更する
    saveSearchCondition(initValues);
    history.push(`/direct/search/result/${params.demo_id}`);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      // データを比較して変更検知
      searchConditionChangeDetection(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * データをRHFに値をsetValueする
   */
  const setDefaultValues = (res: DirectSearchConditionModel) => {
    (Object.keys(res) as (keyof DirectSearchConditionModel)[]).forEach((v) => {
      if (res?.[v] !== undefined || res?.[v] !== null) {
        if (v !== 'mc_experienced_job') {
          methods.setValue(v, res?.[v]);
        }
      }
    });
    if (watch('offer_status')?.includes(6 || 7)) {
      const status = watch('offer_status')?.filter((s) => {
        if (s !== 6 && s !== 7) {
          return true;
        }
      });
      methods.setValue('offer_status', status);
    }
    if (watch('offer_status')?.includes(5)) {
      const status = watch('offer_status')?.filter((s) => {
        if (s !== 5) {
          return true;
        }
      });
      methods.setValue('offer_status', status);
    }
  };

  const onReset = () => {
    push({
      event: 'resetConditions',
      actionType: 'reset_conditions',
      actionName: 'reset_conditions',
    });
    reset();
  };

  return (
    <AdminPage
      header={<HeaderTitle title="求職者検索" message="このページでは、自社にマッチした求職者を検索することができます。" />}
      sticky={<StickyButton isValid={isValid && applicationStep === 2} />}
      demoId={params.demo_id}
    >
      <>
        <FormProvider {...methods}>
          <div className="flex justify-end py-25">
            {isChanged && <DirectSearchFormReset reset={onReset} />}
            <p className="font-bold opacity-20 text-12_14 pointer-events-none cursor-auto">
              保存した条件を見る
            </p>
          </div>
          <section>
            <form
              className="pb-80 border-t border-gray-300"
              onSubmit={handleSubmit(onSubmit)}
              id="FORM"
            >
              <DirectSearchFormSectionFreeWord />
              <DirectSearchFormSectionOffer />
              <DirectSearchFormBasicInfo />
              <DirectSearchFormSectionEmploymentStatus />
              <DirectSearchFormSectionUpdates />
              <DirectSearchFormSectionWorkExperience />
              <DirectSearchFormSectionQualifications />
              <DirectSearchFormSectionDesired />
            </form>
          </section>
        </FormProvider>

      </>
    </AdminPage>
  );
};

type TPropsButton = {
  isValid: boolean;
};

const StickyButton = ({ isValid }: TPropsButton): React.ReactElement => {
  return (
    <div className="sticky bottom-90 z-30">
      <div className='absolute right-60 border-4 border-red-700 border-dotted py-4 px-10 w-224 h-78 bottom-24'>
        <TooltipMessageMatchbou
          className="-ml-12"
          message="このボタンをクリックすると求職者情報一覧ページに遷移します。"
          position={['-66px', '-108px']}
        >
            <BaseButton
              size="m"
              theme="primary"
              type="submit"
              iconName="Search"
              className="absolute right-16 bottom-10"
              disabled={!isValid}
              form="FORM"
            >
              この条件で検索する
            </BaseButton>
        </TooltipMessageMatchbou>
      </div>
    </div>
  );
};

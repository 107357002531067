import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import React, {
    ChangeEventHandler, MouseEventHandler, ReactNode, useEffect, useRef, useState
} from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormDropdownSelectionsBase } from '@/components/common/Form/Dropdown/Selections/Base';
import { FormFootnote } from '@/components/common/Form/Footnote';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { getOptionName } from '@/utils/string';

import type { BodyScrollOptions } from 'body-scroll-lock';
type TProps = {
  name: string;
  id?: string;
  placeholder?: string;
  disabledPlaceholder?: boolean;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  debugError?: boolean;
  selectList: Array<TFormOption>;
  defaultValue?: string | number;
};
type TSelectBoxProps = {
  children: ReactNode;
};

const SelectBox = ({ children }: TSelectBoxProps): React.ReactElement => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('selectOptionPortal') as HTMLElement
  );
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=332%3A7383
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 */
export const FormContainerDropdown = ({
  id,
  placeholder,
  disabledPlaceholder,
  name,
  className = '',
  readOnly = false,
  disabled = false,
  debugError = false,
  selectList,
  defaultValue,
}: TProps): React.ReactElement => {
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = 'mbx-formContainer mbx-formContainer--dropdown';
  const error = methods.formState.errors[name];
  const [showSelectList, setShow] = useState(false);
  const [pos, setPos] = useState({ width: '300px', top: '0px', bottom: 'auto', left: '0px' });
  const [value, setSelectValue] = useState(defaultValue?.toString()); // valueはnumberでも受け取れるが内部では文字列に変換する
  let showFlag = false;
  const isTouchDevice = () => window.ontouchstart === null;
  const selectBoxRef = useRef<HTMLDivElement>(null);
  let openSelect: HTMLSelectElement;
  const divSelectItemBoxRef = React.createRef<HTMLDivElement>(); // 擬似的に作る選択要素のDOM

  const register = methods.register(name);

  /**
   * Div要素で構築した選択要素をクリックして値が変更された際の処理
   */
  const onDivItemChangeValue = (value: string | undefined) => {
    _commonChangeValue(value);
  };

  /**
   * Select要素の選択要素をクリックして値が変更された際の処理
   */
  const onSelectItemChangeValue: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const afterValue = e.target.value;
    _commonChangeValue(afterValue);
  };

  /**
   * 値変更時の共通処理
   */
  const _commonChangeValue = (afterValue: string | undefined) => {
    closeSelectItemBox();
    if (!afterValue && afterValue !== '') {
      new Error('ターゲットが存在しません');
      return;
    }
    methods.setValue(name, afterValue, { shouldValidate: true });
    setSelectValue(afterValue);
  };

  /**
   * 選択要素ボックスを非表示
   */
  const closeSelectItemBox = (): void => {
    setTimeout(function () {
      setShow(false);
      if (!document.querySelector('.mbx-dropdown-wrapper')) clearAllBodyScrollLocks();
    }, 100);
  };

  /**
   * セレクト要素をクリックしたときの処理
   */
  const onClickSelectBox: MouseEventHandler<HTMLDivElement | HTMLSelectElement> = (e): void => {
    if (!isTouchDevice()) {
      const options: BodyScrollOptions = {
        reserveScrollBarGap: true,
      };
      disableBodyScroll(document.body, options);
      openSelect = e.target as HTMLSelectElement;
      const targetRect = openSelect.getBoundingClientRect();
      if (
        targetRect.top + window.pageYOffset + targetRect.height + 324 <
        document.body.clientHeight
      ) {
        setPos({
          width: targetRect.width + 'px',
          top: targetRect.bottom + 1 + 'px',
          bottom: 'auto',
          left: targetRect.left + 'px',
        });
      } else {
        setPos({
          width: targetRect.width + 'px',
          top: 'auto',
          bottom: window.innerHeight - targetRect.top + 1 + 'px',
          left: targetRect.left + 'px',
        });
      }
      setSelectValue(watchValue);
      setShow(!showSelectList);
      showFlag = !showFlag;
    }
  };

  /**
   * リサイズした時に位置を再計算する処理
   */
  const resizeAction = () => {
    if (showFlag) {
      const targetRect = openSelect.getBoundingClientRect();
      if (targetRect.top + window.pageYOffset + 300 < document.body.clientHeight) {
        setPos({
          width: targetRect.width + 'px',
          top: targetRect.bottom + 1 + 'px',
          bottom: 'auto',
          left: targetRect.left + 'px',
        });
      } else {
        setPos({
          width: targetRect.width + 'px',
          top: 'auto',
          bottom: window.innerHeight - targetRect.top + 1 + 'px',
          left: targetRect.left + 'px',
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeAction, {
      capture: false,
      passive: true,
    });
    resizeAction();
    return () => {
      window.removeEventListener('resize', resizeAction);
    };
  }, []);

  /**
   * 選択項目の表示フラグが変更されたら実行する処理
   */
  useEffect(() => {
    // 消える時は処理しない
    if (!showSelectList) return;

    // 仮想のアイテム選択ボックスにfocusを与える
    if (divSelectItemBoxRef.current) {
      divSelectItemBoxRef.current.focus();
    }

    const options = document.getElementById('selectOptionPortal') as HTMLElement;
    if (options.children[0] && selectBoxRef.current) {
      const { clientHeight } = options.children[0];
      const bottom = window.innerHeight - selectBoxRef.current?.getBoundingClientRect().bottom;
      if (bottom < clientHeight) {
        setPos({
          width: selectBoxRef.current?.getBoundingClientRect().width + 'px',
          top: 'auto',
          bottom: '10px',
          left: selectBoxRef.current?.getBoundingClientRect().left + 'px',
        });
      }
    }
  }, [showSelectList]);

  /**
   * RHFの値の変更を監視
   */
  useEffect(() => {
    if (value !== watchValue) {
      setSelectValue(watchValue);
    }
  }, [watchValue]);

  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--dropdown--readonly">
          {getOptionName(watchValue + '', selectList)}
        </div>
      ) : (
        <>
          {showSelectList && (
            <SelectBox>
              <div
                id={name}
                className={cn('mbx-dropdown-wrapper', { 'is-show': showSelectList })}
                style={pos}
                tabIndex={0}
                ref={divSelectItemBoxRef}
                onBlur={() => {
                  closeSelectItemBox();
                }}
              >
                <FormDropdownSelectionsBase
                  selectList={selectList}
                  itemClick={onDivItemChangeValue}
                  placeholder={placeholder}
                  value={value}
                  disabledPlaceholder={disabledPlaceholder}
                  parentRef={divSelectItemBoxRef}
                ></FormDropdownSelectionsBase>
              </div>
            </SelectBox>
          )}
          <div
            className={cn('mbx-formContainer--dropdown-wrapper', {
              'mbx-formContainer--error': error || debugError,
              'is-show': showSelectList,
            })}
            ref={selectBoxRef}
          >
            <select
              className={cn(defaultClass, { hidden: !isTouchDevice() })}
              id={id}
              ref={register.ref}
              name={register.name}
              defaultValue={defaultValue}
              disabled={disabled}
              onClick={onClickSelectBox}
              onChange={onSelectItemChangeValue}
              onBlur={() => closeSelectItemBox()}
            >
              {placeholder && (
                <option value="" className={cn({ hidden: !isTouchDevice() })}>
                  {placeholder}
                </option>
              )}
              {selectList.map((item) => {
                return (
                  <option
                    value={item.value}
                    key={item.value}
                    className={cn({ hidden: !isTouchDevice() })}
                  >
                    {item.children}
                  </option>
                );
              })}
            </select>
            <div className="mbx-formContainer--dropdown-arrow">
              <ButtonIconButton
                type="gray"
                iconName="Arrow_Down"
                hitArea="mini"
                focus={false}
              ></ButtonIconButton>
            </div>
            <div
              className={cn({
                hidden: isTouchDevice(),
              })}
            >
              <div
                className={cn('mbx-formContainer--dropdown-multiple-container')}
                onClick={onClickSelectBox}
              ></div>
            </div>
            <div
              className={cn({
                hidden: isTouchDevice(),
              })}
            >
              <div className="mbx-formContainer--dropdown-multiple-placeholder">
                {value ? <p>{getOptionName(value + '', selectList)}</p> : <p>{placeholder}</p>}
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            {debugError ? (
              <FormFootnote className="text-error-700">
                エラー表示サンプルエラー表示サンプルエラー表示サンプル
              </FormFootnote>
            ) : (
              <>
                {error && (
                  <FormFootnote className="text-error-700">
                    {error ? error.message : null}
                  </FormFootnote>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

import React, { useMemo, useState } from 'react';

import CirclePlaceholder from '@/assets/images/profile/circle-placeholder.svg';
import { UserModalSection } from '@/componentsAdmin/Company/Detail/UserModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { CompanyUser } from '@/utils/api-client';

type Props = {
  user: CompanyUser;
};

export const CompanyStaffList = (props: Props): React.ReactElement => {
  const [userModal, setUserModal] = useState<boolean>(false);
  const accountType = useMemo(() => {
    if (props.user.user_type === 'direct_manager') return '利用責任者';
    if (props.user.user_type === 'direct_user') return '一般利用者';
    if (props.user.user_type === 'direct_hr') return '採用担当者';
  }, [props.user]);
  return (
    <section className="flex items-center py-20 px-16 border-b border-gray-300">
      <div className="w-24 h-24">
        <img src={props.user.photo ? props.user.photo : CirclePlaceholder} alt="" />
      </div>
      <div className="w-168 ml-8">
        <p className="mbx-typography--subhead_2">{props.user.name}</p>
        <p className="text-10_16 text-gray-600 truncate">{props.user.email}</p>
      </div>
      <p className="w-56 ml-16 text-12_18">{props.user.matchbox_id}</p>
      <p className="w-140 ml-16 text-12_18 truncate">{props.user.section}</p>
      <p className="w-140 ml-16 text-12_18 truncate">{props.user.post}</p>
      <p className="w-72 ml-16 text-12_18">{accountType}</p>
      <div className="w-48 ml-16">
        <BaseButton
          theme="secondary"
          size="ss"
          className="w-full whitespace-nowrap"
          onClick={() => setUserModal(true)}
        >
          詳細
        </BaseButton>
      </div>
      <ModalDefault
        isOpen={userModal}
        onClose={() => setUserModal(false)}
        className="rounded-modalContainer"
      >
        <UserModalSection user={props.user} setUserModal={setUserModal} />
      </ModalDefault>
    </section>
  );
};

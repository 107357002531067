import '@/styles/common/Portfolio/ImagePage/sortable_item_sp.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DraggableWrapper } from '@/components/common/DraggableWrapper';
import { FormContainerTextArea, FormLabel } from '@/components/common/Form/';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem: React.VFC<{
  id: string;
  children: React.ReactElement;
  className: string;
  axis?: 'horizontal' | 'vertical';
  captionMaxLength?: number;
  name?: string;
  onRemove(): void;
  height?: number;
  onBlurCaption?(): void;
  setBeforeUnload(flag: boolean): void;
  StyleV2?: boolean;
}> = ({
  id,
  children,
  className,
  axis = 'vertical',
  captionMaxLength = 130,
  onRemove,
  name,
  height,
  onBlurCaption,
  setBeforeUnload,
  StyleV2,
}) => {
  const methods = useFormContext();
  const [removable, setRemovable] = useState<boolean>();
  const mq = useMBXMediaQuery();
  const { listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const contentIndex = useMemo<number | undefined>(() => {
    const index = name?.split('.')[1];
    return index ? parseInt(index) : undefined;
  }, [name]);

  useEffect(() => {
    const value = methods.getValues();
    if (contentIndex === undefined) return;
    const content = value.p_contents[contentIndex];
    if (content.f_id !== undefined && content.f_id !== '') {
      setRemovable(true);
    }
    if (content.embed !== undefined) {
      setRemovable(true);
    }
    if (content.m_type !== undefined) {
      setRemovable(true);
    }
  }, [contentIndex]);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (contentIndex === undefined) return;
      const content = value.p_contents[contentIndex];
      if (content?.f_id !== undefined && content?.f_id !== '') {
        setRemovable(true);
      }
      if (content?.embed !== undefined) {
        setRemovable(true);
      }
      if (content?.m_type !== undefined) {
        setRemovable(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, contentIndex]);

  return (
    <div ref={setNodeRef} style={style}>
      {mq.sm ? (
        <div className="mbx-sotable-item-sp" {...listeners}>
          {children}
          {removable && (
            <button className="mbx-sotable-item-sp_close" onClick={onRemove}>
              <div className="mbx-icon mbx-icon-Circle-large_Clear" />
            </button>
          )}
        </div>
      ) : (
        <DraggableWrapper
          className={cn('my-8', className, 'flex', 'flex-col', {
            ['mx-16']: axis === 'horizontal',
          })}
          bodyClassName="flex flex-1 flex-col"
          dragHandle="dragHandle"
          onRemove={onRemove}
          listeners={listeners}
          style={{ height }}
        >
          <div className="flex-1">{children}</div>
          <div className={cn({"mt-48": !StyleV2})}>
            <FormLabel label="説明文" type="small" />
            <FormContainerTextArea
              className=""
              rows={4}
              maxLength={captionMaxLength}
              name={name || ''}
              placeholder={`例）サイトリニューアル前後のデザイン。直感的に必要な情報を選択できるよう構成、配色にこだわった。（最大${captionMaxLength}文字）`}
              onBlur={() => {
                if (onBlurCaption) onBlurCaption();
              }}
              onFocus={() => setBeforeUnload(true)}
              scroll
            />
          </div>
        </DraggableWrapper>
      )}
    </div>
  );
};

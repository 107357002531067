import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Prefecture from '@/assets/json/prefecture.json';
import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { ProfileImageCircle } from '@/components/common/OtherComponents/ProfileImageCircle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import {
    notificationError, notificationSuccess, setDataLayer, setDataLayerChangedBy, toggleLoading,
    userInfoForceUpdate
} from '@/redux/index';
import { State } from '@/redux/state';
import { ResponseError, UserApi, UserPatchRequest } from '@/utils/api-client/api';
import { basicInfoFromUserInfo, progressFromBasicInfo } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';

export const SectionProfile = (): React.ReactElement => {
  const API = new UserApi();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const loading = useSelector((state: State) => state.loading);
  const { push } = useContext(DataLayerContext);

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    furigana: YUP_VALIDATION.furigana,
    tel: YUP_VALIDATION.tel_required,
    prefecture: YUP_VALIDATION.null,
    birth_year: YUP_VALIDATION.required,
    birth_month: YUP_VALIDATION.required,
    birth_day: YUP_VALIDATION.required,
  });

  const methods = useForm<UserPatchRequest>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name,
      furigana: user?.furigana,
      prefecture: user?.prefecture,
      tel: user?.tel,
      birth_year: user?.birth_year,
      birth_month: user?.birth_month,
      birth_day: user?.birth_day,
      icon: user?.icon,
      matchbox_id: user?.matchbox_id,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<UserPatchRequest> = (request) => {
    dispatch(toggleLoading(true));
    API.patchUser(request)
      .then((res) => {
        if (res.data.user_info) dispatch(userInfoForceUpdate(res.data.user_info));
        const user_info_response = res.data.user_info;
        const basicInfo = basicInfoFromUserInfo(user_info_response);
        if (
          progressFromBasicInfo(request, user) !==
          progressFromBasicInfo(basicInfo, user_info_response)
        ) {
          dispatch(setDataLayerChangedBy('mypage_profile'));
          dispatch(
            setDataLayer({
              userId: user_info_response?.matchbox_id,
              mbOfferStatus: user_info_response?.use_direct ? 'on' : 'off',
              profileCompleteness: `${progressFromBasicInfo(basicInfo, user_info_response)}`,
            })
          );
        }
        dispatch(notificationSuccess('基本情報の保存が完了しました。'));
        push({
          event: 'profileUpdate',
          actionType: 'profile_update',
          actionName: 'profile_update',
        });
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-104 sm:mt-88 mbx-utils-stack-v--16">
            <div className="sm:mb-24">
              <ProfileImageCircle
                name="icon"
                iconName="Person"
                animeClass="mbx-anime-img--none"
                src={user?.icon}
              />
            </div>
            <FormLayoutFieldset>
              <FormLabel label="氏名" type="small">
                <TooltipMessageMatchbou
                  className="-ml-12"
                  message="履歴書・職務経歴書に反映されるため、「本名」で登録してください。オファー機能ご利用時は、企業に開示されません。"
                  position={['0', '-94px']}
                >
                  <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                </TooltipMessageMatchbou>
              </FormLabel>
              <FormContainerTextfield
                className="mkt_mask_items"
                name="name"
                placeholder="例）町田　太郎"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="ふりがな" type="small">
                <TooltipMessageMatchbou
                  className="-ml-12"
                  message="オファー機能ご利用時、「ふりがな」は企業に開示されません。"
                  position={['0', '-94px']}
                >
                  <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                </TooltipMessageMatchbou>
              </FormLabel>
              <FormContainerTextfield
                className="mkt_mask_items"
                name="furigana"
                placeholder="例）まちだ　たろう"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="生年月日" type="small">
                <TooltipMessageMatchbou
                  className="-ml-12"
                  message="オファー機能ご利用時、「生年月日」は企業に開示されません。"
                  position={['0', '-94px']}
                >
                  <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                </TooltipMessageMatchbou>
              </FormLabel>
              <div className="flex mbx-utils-stack-h--8 flex-auto">
                <FormContainerTextfield
                  name="birth_year"
                  placeholder="YYYY"
                  className="w-dateForm mkt_mask_items"
                  type="number"
                ></FormContainerTextfield>
                <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
                <FormContainerTextfield
                  name="birth_month"
                  placeholder="MM"
                  className="w-dateForm mkt_mask_items"
                  type="number"
                ></FormContainerTextfield>
                <FormLayoutUnitDivider>月</FormLayoutUnitDivider>
                <FormContainerTextfield
                  name="birth_day"
                  placeholder="DD"
                  className="w-dateForm mkt_mask_items"
                  type="number"
                ></FormContainerTextfield>
                <FormLayoutUnitDivider>日</FormLayoutUnitDivider>
              </div>
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="お住まいの都道府県" type="small"></FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="prefecture"
                placeholder="選択してください"
                selectList={Prefecture}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="電話番号" type="small">
                <TooltipMessageMatchbou
                  className="-ml-12"
                  message="オファー機能ご利用時、「電話番号」は企業に開示されません。"
                  position={['0', '-94px']}
                >
                  <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                </TooltipMessageMatchbou>
              </FormLabel>
              <FormContainerTextfield
                className="mkt_mask_items"
                name="tel"
                type="number"
                placeholder="例）09012345678"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>
          <section className="register-btn  m-auto mt-48 sm:w-full">
            <BaseButton size="m" theme="primary" className="btn" disabled={loading} type="submit">
              変更を保存
            </BaseButton>
          </section>
        </form>
      </FormProvider>
    </>
  );
};

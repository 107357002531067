import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import InvitationModal from '@/components/common/Direct/InvitationModal';
import Step1 from '@/components/common/Direct/Step1';
import Step2 from '@/components/common/Direct/Step2';
import Step3 from '@/components/common/Direct/Step3';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type tStep = {
  step: string;
};

function DirectRequest(): React.ReactElement {
  const mq = useMBXMediaQuery();
  const history = useHistory();

  const [modal, setModal] = useState<boolean>(false);
  const [step, setStep] = useState({
    step1: true,
    step2: false,
    step3: false,
  });

  useEffect(() => {
    if (!history.location.state && !location.search) return;
    let accessStep = '';
    if(history.location.state) {
      const state = history.location.state as tStep;
      accessStep = state.step;
    } else {
      const param = location.search.slice(1).split('&');
      if(param.indexOf('step2') !== -1) accessStep = 'step2';
    }
    if(accessStep === 'step2') {
      setStep({
        step1: false,
        step2: true,
        step3: false,
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <section>
      <Page.Wrapper
        disableDataLayerPush
        header={
          <HeaderTitle title="オファー機能">
            <div className="flex justify-end">
              {mq.sm ? (
                <ButtonIconButton
                  size="default"
                  iconName="Help"
                  type="blue"
                  onClick={() => setModal(true)}
                />
              ) : (
                <BaseButton size="s" theme="link" iconName="Help" onClick={() => setModal(true)}>
                  オファー機能とは
                </BaseButton>
              )}
            </div>
          </HeaderTitle>
        }
      >
        <>
          {step.step1 && <Step1 setStep={setStep} />}
          {step.step2 && <Step2 setStep={setStep} />}
          {step.step3 && <Step3 />}
          <InvitationModal isOpen={modal} isClose={setModal} />
        </>
      </Page.Wrapper>
    </section>
  );
}

export default DirectRequest;

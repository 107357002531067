import React, { useState } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import * as CH from '@/components/CareerHistory';
import {
  FormContainerTextfield,
  FormContainerTextArea,
  FormLayoutUnitDivider,
  FormLabel,
} from '@/components/common/Form/';
import { BaseButton } from '@/components/common/Button/';
import { TProjectFormValues } from '@/types/Pages/CareerHistory';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TFieldProps = {
  index: number;
  parentIndex: number;
  item: FieldArrayWithId<TProjectFormValues, `careers.${number}.c_projects`, 'uid'>;
  onRemove?(): void;
  onBlur?(
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  onFocus?(e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void;
};

export const DraggableProject: React.VFC<TFieldProps> = ({
  item,
  onRemove,
  index,
  parentIndex,
  onBlur,
  onFocus,
}) => {
  const mq = useMBXMediaQuery();
  const { register } = useFormContext();
  const [open, setOpen] = useState<boolean>(true);
  const { listeners, transform, transition, setNodeRef } = useSortable({ id: item.uid });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const placeholder = {
    structure:`例）
・プロデューサー1名
・テクニカルディレクター1名
・アシスタントディレクター1名
・UI/UXデザイナー1名（本人）
・Webデザイナー1名
・フロントエンドエンジニア2名
・システムエンジニア1名`,
    tools:`例）
・Adobe XD
・Sketch
・Figma
・Photoshop
・Illustrator
・HTML
・CSS
・JavaScript
・PHP`,
  }

  return (
    <div style={style} ref={setNodeRef}>
      <CH.DraggableSection
        className=""
        title="業務内容"
        nested={true}
        onRemove={onRemove}
        listeners={listeners}
      >
        <>
          <input type="hidden" {...register(`careers.0.c_projects.${index}.id`)} />
          <div className="space-y-8 lg:mb-22 md:mb-22 sm:mb-16">
            <FormLabel label="概要" type="small" />
            <FormContainerTextfield
              className="mkt_mask_items"
              name={`careers.${parentIndex}.c_projects.${index}.name`}
              placeholder="例）制作進行管理ツールの開発"
              onBlur={(e) => {
                if (onBlur) onBlur(`careers.${parentIndex}.c_projects.${index}.name`, e);
              }}
              onFocus={onFocus}
            />
          </div>

          <div className={cn({ hidden: !open })}>
            <div className="space-y-8 mb-22">
              <FormLabel label="期間" type="small" />
              <div className="lg:flex md:flex lg:space-x-8 md:space-x-8 sm:space-y-8">
                <div className="flex space-x-8">
                  <FormContainerTextfield
                    defaultValue={``}
                    name={`careers.${parentIndex}.c_projects.${index}.start_date_year`}
                    placeholder="yyyy"
                    className="w-80"
                    onBlur={(e) => {
                      if (onBlur)
                        onBlur(`careers.${parentIndex}.c_projects.${index}.start_date_year`, e);
                    }}
                    onFocus={onFocus}
                  />
                  <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
                  <FormContainerTextfield
                    defaultValue={``}
                    name={`careers.${parentIndex}.c_projects.${index}.start_date_month`}
                    placeholder="mm"
                    className="w-80"
                    onBlur={(e) => {
                      if (onBlur)
                        onBlur(`careers.${parentIndex}.c_projects.${index}.start_date_month`, e);
                    }}
                    onFocus={onFocus}
                  />
                  <FormLayoutUnitDivider>月 〜</FormLayoutUnitDivider>
                </div>
                <div className="flex space-x-8">
                  <FormContainerTextfield
                    defaultValue={``}
                    name={`careers.${parentIndex}.c_projects.${index}.end_date_year`}
                    placeholder="yyyy"
                    className="w-80"
                    onBlur={(e) => {
                      if (onBlur)
                        onBlur(`careers.${parentIndex}.c_projects.${index}.end_date_year`, e);
                    }}
                    onFocus={onFocus}
                  />
                  <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
                  <FormContainerTextfield
                    defaultValue={``}
                    name={`careers.${parentIndex}.c_projects.${index}.end_date_month`}
                    placeholder="mm"
                    className="w-80"
                    onBlur={(e) => {
                      if (onBlur)
                        onBlur(`careers.${parentIndex}.c_projects.${index}.end_date_month`, e);
                    }}
                    onFocus={onFocus}
                  />
                  <FormLayoutUnitDivider>月</FormLayoutUnitDivider>
                </div>
              </div>
            </div>
            <div className="space-y-8 mb-12">
              <FormLabel label="詳細" type="small" />
              <FormContainerTextArea
                className="mkt_mask_items"
                rows={6}
                maxLength={300}
                name={`careers.${parentIndex}.c_projects.${index}.detail`}
                placeholder="例）【業務内容】ゲーム企画、設計、開発ディレクション、及びスケジュール、予算、工数管理。【担当業務・取り組み】入社し会社に慣れることをメインで当プロジェクトにアサイン。（最大300文字）。"
                onBlur={(e) => {
                  if (onBlur) onBlur(`careers.${parentIndex}.c_projects.${index}.detail`, e);
                }}
                onFocus={onFocus}
              />
            </div>
            <div className="space-y-8 mb-12">
              <FormLabel label="役割・チーム体制" type="small" />
              <FormContainerTextArea
                className="mkt_mask_items"
                rows={3}
                maxLength={150}
                name={`careers.${parentIndex}.c_projects.${index}.structure`}
                placeholder={placeholder?.structure}
                onBlur={(e) => {
                  if (onBlur) onBlur(`careers.${parentIndex}.c_projects.${index}.structure`, e);
                }}
                onFocus={onFocus}
              />
            </div>
            <div className="space-y-8 mb-12">
              <FormLabel label="使用言語・ツール" type="small" />
              <FormContainerTextArea
                className="mkt_mask_items"
                rows={3}
                maxLength={150}
                name={`careers.${parentIndex}.c_projects.${index}.tools`}
                placeholder={placeholder?.tools}
                onBlur={(e) => {
                  if (onBlur) onBlur(`careers.${parentIndex}.c_projects.${index}.tools`, e);
                }}
                onFocus={onFocus}
              />
            </div>
          </div>

          <BaseButton
            iconName="Arrow_Down"
            size="m"
            theme="quaternary"
            className={cn('toggle-button', { open, ['w-full']: mq.sm })}
            onClick={() => setOpen(!open)}
          >
            {open ? '業務内容を閉じる' : '詳細を入力する'}
          </BaseButton>
        </>
      </CH.DraggableSection>
    </div>
  );
};

import cn from 'classnames';
import React, { useState } from 'react';

import MatchImg from '@/images/navi-match.png';

type TProps = {
  position?: Array<string>;
  className?: string;
  message: React.ReactNode;
  children?: React.ReactNode;
  widthClassName?: string;
};

/**
 * Tooltip/Message/Matchbou
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=493%3A4422
 *
 * - positionはchildrenの要素の左上からの[left, top]の値
 *
 */
export const TooltipMessageMatchbou = ({
  position = ['0', '-94px'],
  className = '',
  message = '',
  children = '',
  widthClassName = 'w-288',
}: TProps): React.ReactElement => {
  const [hover, setHover] = useState(false);
  const itemHover = (flag: boolean): void => {
    setHover(flag);
  };

  return (
    <>
      <div
        className={className}
        onPointerEnter={() => itemHover(true)}
        onPointerLeave={() => itemHover(false)}
      >
        <div
          className={cn('mbx-tooltip mbx-tooltip--matchbou mbx-tooltip--pc', widthClassName, {
            'mbx-tooltip--hover': hover,
          })}
          style={{ transform: 'translate(' + position[0] + ',' + position[1] + ')' }}
        >
          <i className="mbx-tooltip-icon">
            <img src={MatchImg} alt="" />
          </i>
          <p className="mbx-tooltip-message">{message}</p>
        </div>
        {children && children}
      </div>
    </>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';

export function AdminIndex(): React.ReactElement {
  return (
    <AdminPage header={<HeaderTitle title="Direct ページリスト" />}>
      <table className="my-24 index text-11_12">
        <tr>
          <th>ページ</th>
          <th>ステータス</th>
          <th>備考</th>
          <th>更新日</th>
        </tr>
        <tr className="">
          <td>
            <Link to="/admin/login">ログイン</Link>
          </td>
          <td>済</td>
          <td>ログイン自体はテストユーザーで可能（ログイン後がまだ）</td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/admin/users/C99999999">求職者詳細</Link>
          </td>
          <td>済</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <Link to="/admin/users/C99999999/notes">求職者詳細（社内メモ）</Link>
          </td>
          <td>済</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <Link to="/admin/users/C99999999/account">求職者詳細（アカウント操作）</Link>
          </td>
          <td>済</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <Link to="/admin/users/C99999999/offers">オファー履歴</Link>
          </td>
          <td>済</td>
          <td></td>
          <td>オファーのownerが企業ユーザー情報になっている。API仕様では企業情報</td>
        </tr>
        <tr>
          <td>
            <Link to="/admin/users/C99999999/pickups">ピックアップ履歴</Link>
          </td>
          <td>済</td>
          <td></td>
          <td>オファーのownerが企業ユーザー情報になっている。API仕様では企業情報</td>
        </tr>
      </table>
    </AdminPage>
  );
}

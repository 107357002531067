import '@/styles/common/Admin/UserDetail/status_clients.scss';
import '@/styles/common/Admin/tooltip.scss';

import React, { useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { TooltipMessageMatchbou } from '@/componentsDirect/common/Tooltip/Matchbou';
import { Offer, AdminCompany } from '@/utils/api-client';

type Props = {
  onClick(): void;
  onClickViewDetail: () => void;
  offer: Offer | undefined;
  authorEmail: string | undefined;
};

export const StatusClients: React.VFC<Props> = ({
  offer,
  authorEmail,
  onClick,
  onClickViewDetail,
}) => {
  const ownOffer = useMemo(() => {
    if (!offer?.senter) return false;
    const senter = offer?.senter as AdminCompany & { email: string };
    return senter.email === authorEmail ? true : false;
  }, [offer]);

  const statusTitle = useMemo(() => {
    if (!offer) return <></>;

    switch (offer?.status) {
      case 1:
        return (
          <>
            <i className="mbx-icon mbx-icon-star-fill" />
            ピックアップ済
          </>
        );
      case 2:
        return <>マイナビワークスに相談中</>;
      case 3:
        return <>一次面接オファー中</>;
      case 4:
        return <>見送り</>;
      case 5:
        return <>見送り</>;
      case 6:
        return <>一次面接オファー中</>;
      case 7:
        return <>一次面接オファー中</>;
      case 8:
        return <>カジュアル面談オファー中</>;
      case 9:
        return <>カジュアル面談オファー中</>;
      case 10:
        return <>見送り</>;
      case 11:
        return <>カジュアル面談オファー中</>;
      case 0:
        return <>回答期限終了</>;
      default:
        return <></>;
    }
  }, [offer]);

  const rate = useMemo(() => {
    if (!offer?.rate) return 'マッチ度';

    switch (offer?.rate) {
      case 1:
        return '低';
      case 2:
        return '中';
      case 3:
        return '高';
    }
  }, [offer]);

  return (
    <section className="mbx-status_clients">
      <h3 className="mbx-status_clients__title">
        {offer?.status === 0 || offer?.status ? (
          <>ステータス</>
        ) : (
          <>
            この求職者が気になったら
            <i className="mbx-icon mbx-icon-Info" />
          </>
        )}
        <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
          気になる候補者をピックアップして、「みんなの意見」を聞きましょう。ピックアップした求職者は、候補者管理のページにリストアップされます。特に気になる候補者には「マッチ度」で「高」を設定し、わかりやすく管理しましょう。
        </div>
      </h3>
      {offer?.status ? (
        <Link to={`/direct/candidates/${offer?.id}`} >
          <button
            className={cn(`mbx-status_clients__action-done status-${offer?.status}`)}
          >
            <span className="mbx-status_clients__action-done-status">{statusTitle}</span>
            <span className="mbx-status_clients__action-done-rate">
              <span>{rate}</span>
              <i className="mbx-icon mbx-icon-Arrow_Down" />
            </span>
          </button>
        </Link>
      ) : offer?.status === 0 ? (
        <Link to={`/direct/candidates/${offer?.id}`} >
          <span className={`mbx-status_clients__action status-${offer?.status}`}>{statusTitle}</span>
        </Link>
      ) : (
        <TooltipMessageMatchbou
          className="-ml-12"
          message="クリックすると、求職者へオファーするための画面に遷移します。お申し込み後ご利用できます。"
          position={['0', '-98px']}
        >
          <div className="border-4 border-red-700 border-dotted py-4 px-10">
            <button className="mbx-status_clients__action opacity-20 pointer-events-none cursor-auto" onClick={onClick}>
              <i className="mbx-icon mbx-icon-star-stroke" />
              ピックアップする
            </button>
          </div>
        </TooltipMessageMatchbou>
      )}
      {ownOffer && offer?.status === 1 && (
        <div className="mbx-status_clients__detail">
          <p onClick={onClick}>ピックアップを解除する</p>
        </div>
      )}
      {ownOffer && offer?.status !== undefined && [0, 4, 5, 10].includes(offer?.status) && (
        <div className="mbx-status_clients__detail">
          <p onClick={onClick}>アクションをリセットする</p>
        </div>
      )}
    </section>
  );
};

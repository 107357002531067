import React from 'react';
import { useParams } from 'react-router-dom';

import { DetailsPreview } from '@/pages/Portfolio/';

export const PortfolioPreviewDetail = (): React.ReactElement => {
  const params: { demo_id: string } = useParams();

  return <DetailsPreview presentation direct demoId={params.demo_id} />;
};

import '@/styles/pages/Top/Top.scss';
import '@/styles/pages/Event/Event.scss';

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import ImgUserIcon from '@/assets/images/profile/circle-placeholder.svg';
import BaseButton from '@/components/common/Button/BaseButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import Pager from '@/components/common/Navigation/Page/Pager';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { eventList } from '@/definition/EVENT_LIST';
import { ConfigContext } from '@/utils/config';
import { State } from '@/redux/state';
import { delay } from '@/utils/delay';

type EventProps = {
  id: string,
  title: string[],
  target: string,
  img: string,
  eventDate: string,
  openingDate: string,
  deadlineDate: string,
  type?: string,
  single?: boolean, //右の列を空白にしたい場合にtrue
}

export const Event = (): React.ReactElement => {
  const [page, setPage] = useState<number>(0);
  const [list, setList] = useState<EventProps[]>(eventList);
  const [displayList, setDisplayList] = useState<EventProps[]>([]);
  //1ページに表示する件数
  const PAGE_LIMIT = 12;
  const total = list.length;
  const pageLength = Math.ceil(list.length / PAGE_LIMIT);

  const mq = useMBXMediaQuery();
  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);

  const config = useContext(ConfigContext);

  const menu = useMemo(() => {
    if (isAuth) {
      return config.menu;
    } else {
      return [
        {
          title: 'ログイン',
          url: '/login',
        },
      ];
    }
  }, [isAuth]);

  const headerMenu = useMemo(() => {
    if (isAuth) {
      return config.headerMenu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const userIcon = useMemo(() => {
    if (!user) return ImgUserIcon;
    return user?.icon;
  }, [user]);

  const badge = useMemo(() => {
    if (isAuth) {
      return true;
    } else {
      return false;
    }
  }, [isAuth]);

  const children = useMemo(() => {
    if (!isAuth) {
      return <BaseButton theme="primary" size="40" className="header-btn" href="/entry">
        MATCHBOXをはじめる
      </BaseButton>;
    }
  }, [isAuth]);

  const size = useMemo(() => {
    if (mq.lg) {
      return "lg";
    }
    if (mq.md) {
      return "md";
    }
    if (mq.sm) {
      return "sm";
    }
    return ""
  }, [mq]);

  useEffect(() => {
    window.scrollTo(0, 0);

    delay(50).then(() => {
      if (location.hash) {
        const hash = location.hash;
        location.hash = "";
        location.hash = hash;
      }
    });
  }, []);

  useEffect(() => {
    const copy = cloneDeep(list);
    const futureEevnt: EventProps[] = [];
    const nowEevnt: EventProps[] = [];
    const pastEevnt: EventProps[] = [];
    const date = new Date();
    const thisMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

    copy.forEach(c => {
      const split = c.openingDate.split("-");
      const cMonth = `${split[0]}-${split[1]}`;
      if (cMonth > thisMonth) {
        futureEevnt.unshift(c);
      } else if(cMonth === thisMonth) {
        nowEevnt.unshift(c);
      } else {
        if (c.type) delete c.type;
        if (c.single) delete c.single;
        pastEevnt.push(c);
      }
    });
    // 今月、来月開催はtype順にsortする
    if(futureEevnt.length) {
      futureEevnt.sort(function (a, b) {
        if (a.type && b.type) return (a.type > b.type ? 1 : -1);
        return -1;
      });
      let futureEventType = "";
      futureEevnt.forEach((e) => {
        if(e.type && futureEventType !== e.type) {
          futureEventType = e.type;
          return;
        };
        if(futureEventType === e.type) delete e.type;
      });
    }
    nowEevnt.sort(function (a, b) {
      if (a.type && b.type) return (a.type > b.type ? 1 : -1);
      return -1;
    });
    let nowEventType = "";
    nowEevnt.forEach((e) => {
      if(e.type && nowEventType !== e.type) {
        nowEventType = e.type;
        return;
      };
      if(nowEventType === e.type) delete e.type;
    });
    // 過去開催は開催日順にsortする
    pastEevnt.sort(function (a, b) {
      return (a.openingDate < b.openingDate ? 1 : -1);
    });

    const event = [...futureEevnt, ...nowEevnt, ...pastEevnt];
    const min = page * PAGE_LIMIT;
    const max = (page + 1) * PAGE_LIMIT - 1;
    const filterList = event.filter((e, i) => min <= i && i <= max);
    setDisplayList(filterList);
  }, [page]);

  const deadlineCheck = (date: string) => {
    const yyyymmdd = date.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    return deadlineDate > now ? true : false;
  }

  const statusWord = (date: string) => {
    const yyyymmdd = date.split(' ')[0];
    const acceptanceDeadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const deadlineDate = new Date(date.replace(/-/g, "/"));
    const now = new Date;
    if (acceptanceDeadlineDate > now) {
      return '受付中'
    } else {
      return now > acceptanceDeadlineDate && deadlineDate > now ? '受付終了' : '開催終了';
    }
  }

  return (
    <section className={`event page ${isAuth ? "event-auth" : ""}`}>
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'page_view',
        }}
      >
        <Header
          menu={menu}
          headerMenu={headerMenu}
          userIcon={userIcon}
          badge={badge}
          isAuth={isAuth}
        >
          {children}
        </Header>
        <section className={`max-w-980 m-auto md:px-16 md:mt-80 sm:px-16 mb-120 sm:mb-188 sm:mt-55 ${!isAuth ? "mt-80" : ""}`}>
          <section className="event-contents">
            <div className="event-list_mv mb-60 sm:mb-20 sm:-mx-16">
              <div className="outset p-40 sm:p-16 sm:py-24">
                <h1 className="text-24_24 sm:text-18_24 font-bold mb-25">イベント情報</h1>
                <p className="text-14_21 mb-25">クリエイティブ業界に特化したイベント情報を多数開催しています。<br />基本オンライン、参加無料です。</p>
                <p className="text-14_21">過去のイベント動画のアーカイブは<ButtonTextLink href="/portfolio/P92466765">こちら</ButtonTextLink></p>
              </div>
            </div>
            <section>
              {displayList.map((item, index) => (
                <>
                  <div key={index} id={item.type ? item.type : ""}>
                    <a href={`/event/${item.id}`}>
                      <div className="event-list my-30 sm:my-15">
                        <div className="event-thunmb">
                          <img className="w-auto mx-auto border border-gray-300" src={item.img} width="690" height="362" alt="" />
                        </div>
                        <div className="event-overview mt-30 sm:mt-12">
                          <p className="data text-16_16 sm:text-12_40 font-bold mb-24 sm:mb-12"><span className="event-data">{item.eventDate}</span>
                            <span className={`text-14_14 sm:text-12_40 status ${deadlineCheck(item.deadlineDate) ? "open" : ""}`}>{statusWord(item.deadlineDate)}</span>
                          </p>
                          <h2 className="text-18_28 sm:text-12_40 font-bold sm:font- mb-24 sm:mb-8">
                            {item.title.map((character) => (
                              character.includes('br') ? (
                                !character.includes(size) ? <br /> : ""
                              ) : (
                                character
                              )
                            ))}
                          </h2>
                          <p className="text-14_14 sm:text-12_40 target">対象者: {item.target}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  {item.single && (
                    <div></div>
                  )}
                </>
              ))}
            </section>
            <div className="event-list_pagination flex mt-42 sm:mt-34">
              <p className="text-12_40 pr-42 sm:pr-16">
                {page === 0 ? page + 1 : page * PAGE_LIMIT + 1}〜
                {page === pageLength - 1 ? total : (page + 1) * PAGE_LIMIT} / {total}件
              </p>
              {pageLength && (
                <Pager page={page} pageLength={pageLength} setPage={setPage} />
              )}
            </div>
          </section>
        </section>
        <Footer logo={false} />
      </DataLayerPushContainer>
    </section>
  );
};

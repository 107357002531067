import { AdminSearchConditionModel } from '@/utils/api-client';
import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

/**
 * 仕様
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
 */
export const reducers = {
  setSearchCondition: (state: State, action: PayloadAction<AdminSearchConditionModel>): State => {
    return { ...state, condition: action.payload };
  },
};

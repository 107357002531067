import React from 'react';

import {
    FormCombinationsFromToTextfield
} from '@/componentsDirect/Parts/Form/Combinations/FromToTextfield';
import { FormCombinationsFromToDatepicker }from '@/componentsDirect/Parts/Form/Combinations/FromToDatepicker';
import {
    FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

/**
 * 検索条件フォーム オファー状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionOffer = (): React.ReactElement => {
  return (
    <UserCondition title="オファー状況">
      <>
        <div className="flex flex-auto mb-22">
          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="選考ステータス" type="small"></FormLabel>
            <FormContainerDropdownMultiple
              name="offer_status"
              placeholder="選択してください（複数選択可）"
              selectList={DIRECT_FORM_OPTIONS.directSearchStatus}
              className="mkt_non_mask_items"
            />
          </FormLayoutFieldset>
          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="マッチ度" type="small"></FormLabel>
            <FormContainerDropdownMultiple
              name="rate"
              placeholder="選択してください（複数選択可）"
              selectList={DIRECT_FORM_OPTIONS.offer_mw_status}
              className="mkt_non_mask_items"
            />
          </FormLayoutFieldset>
        </div>

        <FormCombinationsFromToDatepicker
          className="pl-24"
          name="offer_update_at"
          label="選考ステータス最終更新日"
          fieldWidthClass="w-126"
          placeholder="yyyy/mm/dd"
        />
      </>
    </UserCondition>
  );
};

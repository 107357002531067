import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    CompanyGroupParam, CompanyGroupSummary, DirectApi, DirectCompanyGroupResponse, InlineObject10,
    ResponseError
} from '@/utils/api-client/api';

type TReturn = {
  companyUserGroupId: DirectCompanyGroupResponse | undefined;
  companyUserGroups: Array<CompanyGroupSummary> | undefined;
  getCompanyUserGroups: () => Promise<void>;
  getCompanyUserGroupId: (id: string) => Promise<void>;
  pathCompanyUserGroups: (request: InlineObject10) => Promise<void>;
  patchCompanyUserGroupId: (idPathParam: string, request: CompanyGroupParam) => void;
  postCompanyUserGroup: (request: { name: string }) => Promise<void>;
  deleteCompanyUserGroupId: (idPathParam: string) => Promise<void>;
};

/**
 * 企業ユーザーグループ関連処理
 */
export const useCompanyUserGroups = (): TReturn => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const API = new DirectApi();
  const dispatch = useDispatch();
  const [companyUserGroups, setCompanyUserGroups] = useState<Array<CompanyGroupSummary>>();
  const [companyUserGroupId, setCompanyUserGroupId] = useState<DirectCompanyGroupResponse>();

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * グループ一覧情報取得
   */
  const getCompanyUserGroups = () => {
    dispatch(toggleLoading(true));
    return new Promise<void>(() => {
      setCompanyUserGroups([]);
      dispatch(toggleLoading(false));
    });
    // return API.getDirectCompanyGroups()
    //   .then((res) => {
    //     setCompanyUserGroups(res.data.groups);
    //   })
    //   .catch((error: AxiosError<ResponseError>) => {
    //     if (error.response) dispatch(notificationError(error.response.data.error_message));
    //   })
    //   .finally(() => {
    //     dispatch(toggleLoading(false));
    //   });
  };

  /**
   * グループ情報取得
   */
  const getCompanyUserGroupId = (id: string) => {
    dispatch(toggleLoading(true));
    return API.getDirectCompanyGroupId(id)
      .then((res) => {
        setCompanyUserGroupId(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * グループ情報編集
   */
  const patchCompanyUserGroupId = (idPathParam: string, request: CompanyGroupParam) => {
    dispatch(toggleLoading(true));
    API.patchDirectCompanyGroupCompanyGroupId(idPathParam, request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_group));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * グループ並び替え
   */
  const pathCompanyUserGroups = (request: InlineObject10) => {
    dispatch(toggleLoading(true));
    return API.patchDirectCompanyGroups(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_group));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * グループ追加
   */
  const postCompanyUserGroup = (request: { name: string }) => {
    dispatch(toggleLoading(true));
    return API.postDirectCompanyGroup(request)
      .then((res) => {
        console.log(res);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  /**
   * グループ削除
   */
  const deleteCompanyUserGroupId = (idPathParam: string) => {
    dispatch(toggleLoading(true));
    return API.deleteDirectCompanyGroupCompanyGroupId(idPathParam)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.deleted_user));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  return {
    companyUserGroupId,
    companyUserGroups,
    getCompanyUserGroups,
    getCompanyUserGroupId,
    pathCompanyUserGroups,
    patchCompanyUserGroupId,
    postCompanyUserGroup,
    deleteCompanyUserGroupId,
  };
};

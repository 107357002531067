import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import * as CH from '@/components/CareerHistory';
import BaseButton from '@/components/common/Button/BaseButton';
import {
    FormCheckboxItem, FormContainerDropdown, FormContainerTextArea, FormContainerTextfield,
    FormLabel, FormLayoutUnitDivider
} from '@/components/common/Form';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { career } from '@/hooks/usePageCareerHistory';
import { TCareerFormValues } from '@/types/Pages/CareerHistory';
import { Career as CareerType, Project } from '@/utils/api-client';

type TFieldProps = {
  index: number;
  item: FieldArrayWithId<TCareerFormValues, 'careers', 'id'>;
  patchCareer(param: CareerType): Promise<CareerType | undefined>;
  patchCareerImmediately(param: CareerType): Promise<CareerType | undefined>;
  foldable?: boolean;
  onBlur?(
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  onFocus?(e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void;
};

export const Career: React.VFC<TFieldProps> = ({
  item,
  index,
  patchCareer,
  patchCareerImmediately,
  foldable,
  onBlur,
  onFocus,
}) => {
  const { patch, removeProject } = career();
  const { setValue, register, getValues } = useFormContext();

  const [currentlyInOffice, setCurrentlyInOffice] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSortProjects = (item: any, result: any) => {
    const param = { ...item };
    patch({
      ...param,
      c_projects: result,
    });
  };

  const appendProject = async () => {
    const oldItem = getValues();
    const oldCareer: CareerType = oldItem.careers[index];
    const emptyProject: Project = {
      tools: '',
      structure: '',
      start_date: null,
      end_date: null,
      detail: '',
      name: '',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newCareer: any = await patchCareerImmediately({
      c_id: item.c_id,
      c_employment_status: item.c_employment_status,
      c_projects: [emptyProject],
    } as CareerType);

    //既存のプロジェクトに無いもの（新しく追加されたもの）を抽出
    const newProject: Project[] = newCareer.c_projects.filter(
      (project: Project) => !oldCareer.c_projects?.map((project) => project.id).includes(project.id)
    );

    let newProjects: Project[] | undefined = oldCareer.c_projects;
    if (newProjects) newProjects = [...newProjects, ...newProject];

    //新しいプロジェクトを末尾に加えてPATCHする
    await patchCareer({
      c_id: item.c_id,
      c_employment_status: item.c_employment_status,
      c_projects: newProjects,
    } as CareerType);

    setValue(`careers.${index}.c_projects`, newProjects);
  };

  useEffect(() => {
    setCurrentlyInOffice(!item.c_leave_flag);
  }, [item]);

  return (
    <>
      <input type="hidden" {...register(`careers.${index}.c_id`)} />
      <div className="space-y-8 mb-22">
        <FormLabel label="会社名" type="small" />
        <FormContainerTextfield
          className="mkt_mask_items"
          name={`careers.${index}.c_company_name`}
          placeholder="例）株式会社マイナビデザイン"
          onBlur={(e) => {
            if (onBlur) onBlur(`careers.${index}.c_company_name`, e);
          }}
          onFocus={onFocus}
        />
      </div>

      {/* 在籍期間 */}
      <div className="space-y-8 mb-22">
        <FormLabel label="在籍期間" type="small" />
        <FormCheckboxItem
          label="現在この会社で働いています"
          checked={currentlyInOffice}
          onChange={(e) => {
            setCurrentlyInOffice(e.target.checked);
            setValue(`careers.${index}.c_leave_flag`, !e.target.checked);
          }}
        />
        <div className="lg:flex md:flex lg:space-x-8 md:space-x-8 sm:space-y-8">
          <div className="flex space-x-8">
            <FormContainerTextfield
              type="number"
              name={`careers.${index}.c_join_date_year`}
              placeholder="yyyy"
              className="w-80"
              onBlur={(e) => {
                if (onBlur) onBlur(`careers.${index}.c_join_date_year`, e);
              }}
              onFocus={onFocus}
            />
            <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
            <FormContainerTextfield
              type="number"
              name={`careers.${index}.c_join_date_month`}
              placeholder="mm"
              className="w-80"
              onBlur={(e) => {
                if (onBlur) onBlur(`careers.${index}.c_join_date_month`, e);
              }}
              onFocus={onFocus}
            />
            <FormLayoutUnitDivider>
              <>月 〜 {currentlyInOffice && <> 現在</>}</>
            </FormLayoutUnitDivider>
          </div>

          {!currentlyInOffice && (
            <div className="flex space-x-8">
              <FormContainerTextfield
                type="number"
                name={`careers.${index}.c_leave_date_year`}
                placeholder="yyyy"
                className="w-80"
                onBlur={(e) => {
                  if (onBlur) onBlur(`careers.${index}.c_leave_date_year`, e);
                }}
                onFocus={onFocus}
              />
              <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
              <FormContainerTextfield
                type="number"
                name={`careers.${index}.c_leave_date_month`}
                placeholder="mm"
                className="w-80"
                onBlur={(e) => {
                  if (onBlur) onBlur(`careers.${index}.c_leave_date_month`, e);
                }}
                onFocus={onFocus}
              />
              <FormLayoutUnitDivider>月</FormLayoutUnitDivider>
            </div>
          )}
        </div>
      </div>

      <div className={cn({ hidden: foldable && !open })}>
        <div className="space-y-8 mb-22">
          <FormLabel label="部署名" type="small" />
          <FormContainerTextfield
            className="mkt_mask_items"
            name={`careers.${index}.c_section`}
            placeholder="例）インターネットメディア事業部"
            onBlur={(e) => {
              if (onBlur) onBlur(`careers.${index}.c_section`, e);
            }}
            onFocus={onFocus}
          />
        </div>
        <div className="space-y-8 mb-22">
          <FormLabel label="役職名" type="small" />
          <FormContainerTextfield
            className="mkt_mask_items"
            name={`careers.${index}.c_post`}
            placeholder="例）プロデューサー"
            onBlur={(e) => {
              if (onBlur) onBlur(`careers.${index}.c_post`, e);
            }}
            onFocus={onFocus}
          />
        </div>
        <div className="space-y-8 mb-22">
          <FormLabel label="事業内容" type="small" />
          <FormContainerTextfield
            className="mkt_mask_items"
            name={`careers.${index}.c_detail`}
            placeholder="例）ゲーム開発、インターネットメディア企画、EC運営"
            onBlur={(e) => {
              if (onBlur) onBlur(`careers.${index}.c_detail`, e);
            }}
            onFocus={onFocus}
          />
        </div>
        <div className="space-y-8 mb-22">
          <FormLabel label="従業員数" type="small" />
          <div className="flex space-x-8">
            <FormContainerTextfield
              type="number"
              className="w-150 mkt_mask_items"
              name={`careers.${index}.c_employee_count`}
              placeholder="例）1000"
              onBlur={(e) => {
                if (onBlur) onBlur(`careers.${index}.c_employee_count`, e);
              }}
              onFocus={onFocus}
            />
            <FormLayoutUnitDivider className="w-auto">名</FormLayoutUnitDivider>
          </div>
        </div>
        <div className="space-y-8 mb-22">
          <FormLabel label="資本金" type="small" />
          <div className="flex space-x-8">
            <FormContainerTextfield
              type="number"
              className="w-150 mkt_mask_items"
              name={`careers.${index}.c_capital`}
              placeholder="例）25000"
              onBlur={(e) => {
                if (onBlur) onBlur(`careers.${index}.c_capital`, e);
              }}
              onFocus={onFocus}
            />
            <FormLayoutUnitDivider className="w-auto">万円</FormLayoutUnitDivider>
          </div>
        </div>
        <div className="space-y-8 mb-22">
          <FormLabel label="雇用形態" type="small" />
          <FormContainerDropdown
            className="mkt_mask_items"
            name={`careers.${index}.c_employment_status`}
            placeholder="選択してください"
            selectList={FORM_OPTIONS.employment_contract_type}
          />
        </div>

        <CH.Projects
          index={index}
          onSort={(result) => onSortProjects(item, result)}
          onAppend={() => appendProject()}
          onRemove={(id) => removeProject(id)}
          onBlur={onBlur}
          onFocus={onFocus}
        />

        <div className="space-y-8 mb-22">
          <FormLabel label="転職・退職理由" type="small">
            <TooltipMessageMatchbou
              className="-ml-12"
              message="オファー機能ご利用時、「転職・退職理由」は企業に開示されません。"
              position={['0', '-94px']}
            >
              <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
            </TooltipMessageMatchbou>
          </FormLabel>
          <FormContainerTextArea
            className="mkt_mask_items"
            rows={3}
            maxLength={150}
            name={`careers.${index}.c_leave_reason`}
            placeholder="例）一身上の都合により退職。"
            onBlur={(e) => {
              if (onBlur) onBlur(`careers.${index}.c_leave_reason`, e);
            }}
            onFocus={onFocus}
          />
        </div>
      </div>

      {foldable && (
        <BaseButton
          iconName="Arrow_Down"
          size="m"
          theme="tertiary"
          className={cn('toggle-button', { open })}
          onClick={() => setOpen(!open)}
        >
          {open ? '職歴を閉じる' : '詳細を入力する'}
        </BaseButton>
      )}
    </>
  );
};

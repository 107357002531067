import '@/styles/pages/Top/Top.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
// import VisibilitySensor from 'react-visibility-sensor';
import Header from '@/components/common/Header';
// import Modal from '@/components/common/Modal';
import { Footer } from '@/components/common/Top/Footer';
import TopModal from '@/components/common/Top/TopModal';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { items } from '@/utils/top';

function Top(): React.ReactElement {
  const [recruitSlideNum, setRecruitSlideNum] = useState<number>(0);
  // const [logoList, setLogoList] = useState<string[][]>();
  // const [logoActive, setLogoActive] = useState<boolean>(false);
  // const [logoCount, setLogoCount] = useState<number>(0);
  // const [onSwipe, setOnSwipe] = useState<boolean>(false);
  const [recruitVoiceMore, setRecruitVoiceMore] = useState<boolean>(false);
  const [modalFirst, setModalFirst] = useState<boolean>(false);
  const [modalSecond, setModalSecond] = useState<boolean>(false);
  const recruitSliderRef = useRef<Slider>(null);
  // const portfolioSliderRef = useRef<Slider>(null);
  // const [onMove, setOnMove] = useState<boolean>(false);
  const mq = useMBXMediaQuery();
  const mainSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
  };
  const recruitSettings = {
    dots: false,
    infinite: false,
    arrow: false,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    beforeChange: (current: number, next: number) => {
      setRecruitSlideNum(next);
    },
  };
  const { push } = useContext(DataLayerContext);

  // const portfolioSettings = {
  //   dots: false,
  //   arrow: false,
  //   variableWidth: true,
  //   centerMode: true,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: true,
  //         onSwipe: () => {
  //           setOnSwipe(true);
  //         },
  //       },
  //     },
  //   ],
  // };

  // const arrayChunk = ([...array], size = 1) => {
  //   return array.reduce(
  //     (acc, value, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]),
  //     []
  //   );
  // };

  // const delay = (second: number) => {
  //   return new Promise((resolve) => setTimeout(resolve, second));
  // };

  const scrollFade = () => {
    const fadeInTarget = document.querySelectorAll('.fade-in');
    for (let i = 0; i < fadeInTarget.length; i++) {
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + 150) {
        fadeInTarget[i].classList.add('fade-in_in');
      }
    }
  };

  useEffect(() => {
    // if (mq.sm) setLogoList(arrayChunk(items.companyLogo, 3));
    // else setLogoList(arrayChunk(items.companyLogo, 7));
    window.addEventListener('scroll', () => {
      scrollFade();
    });
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
    });

    return () => {
      window.removeEventListener('scroll', scrollFade);
    };
  }, []);

  // const fingerMove = (isVisible: boolean) => {
  //   if (!isVisible) return;
  //   if (onMove) return;
  //   setOnMove(true);
  // };

  // const countUp = async () => {
  //   if (!logoList) return;
  //   for (let i = 0; i < logoList.length; i++) {
  //     await delay(2000);
  //     setLogoCount(i);
  //     await delay(1000);
  //     setLogoActive(true);
  //     await delay(6000);
  //     setLogoActive(false);
  //   }
  // };

  // useEffect(() => {
  //   if (logoList) {
  //     countUp();
  //   }
  //   return () => {
  //     //
  //   };
  // }, [logoList]);

  // useEffect(() => {
  //   if (logoList) {
  //     if (logoList.length - 1 == logoCount) {
  //       countUp();
  //     }
  //   }
  //   return () => {
  //     //
  //   };
  // }, [logoCount]);

  return (
    <section className="top">
      <Header
        menu={[
          {
            title: 'ログイン',
            url: '/login',
          },
        ]}
        headerMenu={[
          {
            title: 'ログイン',
            url: '/login',
          },
        ]}
        badge={false}
      >
        <BaseButton theme="primary" size="40" className="header-btn" href="/entry">
          MATCHBOXをはじめる
        </BaseButton>
      </Header>
      <section className="main-bnr">
        <Slider {...mainSettings}>
          {items.mainBnr.map((item, index) => {
            return (
              <div
                className="main-slide"
                key={index}
                style={{ backgroundColor: item.backgroundColor }}
              >
                <h3 dangerouslySetInnerHTML={{ __html: item.text }}></h3>
                {mq.sm ? (
                  <img src={item.spImage} alt={item.text} />
                ) : (
                  <img src={item.img} alt={item.text} />
                )}
                {mq.sm && (
                  <BaseButton theme="primary" size="m" className="btn" href="/entry">
                    MATCHBOXをはじめる
                  </BaseButton>
                )}
              </div>
            );
          })}
        </Slider>
        <div className="mynaviworks-logo">
          <img src={mq.sm ? "../images/Top/mynaviworks-logo.svg" : "../images/Top/mynaviworks-kv-logo.svg"} alt="" />
        </div>
      </section>
      <section className="top-list">
        <a href="/info/0003">
          <div className="list"><img src="../images/Top/top-list_image01.png" alt="" />
            <p className="list-title">キャリアインタビュー</p>
            <p className="list-text">現役のクリエイターへキャリアや転職活動について聞きました。</p>
          </div>
        </a>
        <Link to="/event/">
          <div className="list"><img src="../images/Top/top-list_image02.png" alt="" />
            <p className="list-title">イベント情報</p>
            <p className="list-text">セミナーやワークショップを随時開催中。<br />ぜひご参加ください。</p>
          </div>
        </Link>
        <a href="/report/">
          <div className="list"><img src="../images/Top/top-list_image03.png" alt="" />
            <p className="list-title">レポート</p>
            <p className="list-text">セミナーレポートを中心に、クリエイターの皆さまへ役立つ情報をお届け。</p>
          </div>
        </a>
      </section>
      <section
        className="introduction"
        style={{
          backgroundImage: mq.sm ? 'url(images/Top/bg_sky-sp.png)' : 'url(images/Top/bg-sky.png)',
        }}
      >
        <div className="introduction-top">
          <div className="wrap">
            <p className="introduction-title">
              採用のプロならではの
              <br />
              視点で生まれました。
            </p>
            <p className="introduction-text">
              誰でもかんたんにポートフォリオが{mq.sm && <br />}つくれます。
              <br />
              作品は美しくレイアウト。
              <br />
              さらに採用担当者の知りたい情報も
              <br />
              しっかり入力することができます。
            </p>
            <p className="introduction-btn" onClick={() => setModalFirst(true)}>
              さらに詳しく
            </p>
          </div>
          <div className="introduction-images">
            <div className="fade-in fade-in_right">
              <img src="images/Top/lead01.png" alt="" className="lead-img01" />
            </div>
            <div className="fade-in fade-in_left">
              <img src="images/Top/lead02.png" alt="" className="lead-img02" />
            </div>
          </div>
        </div>
        <div className="introduction-bottom">
          <div className="introduction-images">
            <div className="fade-in fade-in_left">
              <img src="images/Top/lead03.png" alt="" className="lead-img03" />
            </div>
            <div className="fade-in fade-in_right">
              <img src="images/Top/lead04.png" alt="" className="lead-img04" />
            </div>
          </div>
          <div className="wrap">
            <p className="introduction-title">
              ポートフォリオができたら、
              <br />
              企業と出会えるチャンスも。
            </p>
            <p className="introduction-text">
              ポートフォリオや職務経歴書を作成し、
              <br />
              「オファー機能」にエントリーすると
              <br />
              あなたの作品を見て気になった企業から
              <br />
              面接オファーを受け取ることができます。
            </p>
            <p className="introduction-btn" onClick={() => setModalSecond(true)}>
              さらに詳しく
            </p>
            {/* 仮入れコンテンツ */}
            <div className="logo-link">
              <p>取引企業</p>
              <p>
                <a
                  href="https://mynavi-creator.jp/service/introduction-of-clients"
                  target="_blank"
                  rel="noreferrer"
                >
                  取引企業の一覧をみる<i className="mbx-icon mbx-icon-External_link-thin"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="logo-list">
          <p className="text">取引企業</p>
          <div className="list">
            <ul>
              {logoList &&
                logoList[logoCount]?.map((item: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className={cn(logoActive ? 'logo-in' : 'logo-out')}
                      style={{ transitionDelay: `${index * 2}00ms` }}
                    >
                      <img src={item} alt="" />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div> */}
      </section>
      <section className="start">
        <p>すべての機能は無料。{mq.sm && <br />}まずは登録してみましょう。</p>
        <BaseButton theme="primary" size="m" href="/entry">
          MATCHBOXをはじめる
        </BaseButton>
      </section>
      <section
        className="recruit-voice"
        style={{
          backgroundImage: mq.sm
            ? 'url(images/Top/voice-bg_SP.png)'
            : 'url(images/Top/voice-bg.png)',
        }}
      >
        <div className="recruit-voice_inner">
          <div className="wrap">
            <p className="recruit-voice_title">採用担当者の声</p>
            <p className="recruit-voice_subtitle">HR Interview</p>
            <div className="recruit-voice_description">
              ポートフォリオでは何を見ているの？
              <br />
              成果物以外に知りたいことは？
              <br />
              魅力的に思えるものはどこが違う？
              <br />
              デザイナーの採用担当者に
              <br />
              リアルな視点を聞きました。
            </div>
          </div>
          {!mq.sm && (
            <div className="slide-control">
              <img
                src="images/Top/slide-prev.png"
                alt=""
                className={cn(recruitSlideNum === 0 && 'disabled')}
                onClick={() => recruitSliderRef.current?.slickPrev()}
              />
              <img
                src="images/Top/slide-next.png"
                alt=""
                className={cn(recruitSlideNum === items.recruitVoice.length - 3 && 'disabled')}
                onClick={() => recruitSliderRef.current?.slickNext()}
              />
            </div>
          )}
          {!mq.sm ? (
            <div className="recruit-voice_slider">
              <Slider {...recruitSettings} ref={recruitSliderRef}>
                {items.recruitVoice.map((item, index: number) => {
                  return (
                    <a href={item.src} key={index} target="_blank" rel="noreferrer">
                      <div className="slide-item">
                        <div className="item">
                          <img src={item.img} alt="" />
                          <div className="slide-text">
                            <p className="name">{item.name}</p>
                            <p className="text">{item.text}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div className="recruit-voice_list">
              {items.recruitVoice.map((item, index: number) => {
                return (
                  <a
                    href={item.src}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className={cn('item', index > 2 && !recruitVoiceMore && 'item-hidden')}
                  >
                    <div>
                      <img src={item.img} alt="" />
                      <div className="item-text">
                        <p className="name">{item.name}</p>
                        <p className="text">{item.text}</p>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
          {mq.sm && (
            <div className="list-more" onClick={() => setRecruitVoiceMore(!recruitVoiceMore)}>
              <div className="click-area">
                <span>{recruitVoiceMore ? '閉じる' : 'もっと見る'}</span>
                <img src="images/Top/more.svg" alt="" className={cn(recruitVoiceMore && 'close')} />
              </div>
            </div>
          )}
        </div>
      </section>
      {/* <section className="everyone-portfolio">
        <p className="everyone-portfolio_title">みんなのポートフォリオ</p>
        <p className="everyone-portfolio_subtitle">For Your Inspiration</p>
        <div className="portfolio-slider">
          {mq.sm && (
            <div className={cn('finger', onSwipe && 'finger-none')}>
              <div className="finger-contents">
                <img src="images/Top/left.svg" alt="" className="finger-arrow" />
                <VisibilitySensor onChange={fingerMove}>
                  <img
                    src="images/Top/finger.svg"
                    alt=""
                    className={cn('finger-icon', onMove && 'finger-move')}
                  />
                </VisibilitySensor>
                <img src="images/Top/right.svg" alt="" className="finger-arrow" />
              </div>
            </div>
          )}
          {!mq.sm && (
            <div className="portfolio-slider_control">
              <img
                src="images/Top/slide-prev.png"
                alt=""
                onClick={() => portfolioSliderRef.current?.slickPrev()}
              />
              <img
                src="images/Top/slide-next.png"
                alt=""
                onClick={() => portfolioSliderRef.current?.slickNext()}
              />
            </div>
          )}
          <div className="slide-items">
            <Slider {...portfolioSettings} ref={portfolioSliderRef}>
              {items.everyonePortfolio?.map((item, index) => {
                return (
                  <div className="slide-item" key={index}>
                    <div className="bnr-area">
                      <img src={item.img} alt="" />
                    </div>
                    <p className="title">{item.title ? item.title : '無題'}</p>
                    <div className="user-info">
                      <img src={item.userIcon} alt="" />
                      <div>
                        <p className="name">{item.userName}</p>
                        <p className="job">{item.job}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section> */}
      <section className="start" style={{ backgroundColor: '#f7f7f7' }}>
        <p>さあ、あなたもポートフォリオをつくりましょう。</p>
        <BaseButton theme="primary" size="m" href="/entry">
          MATCHBOXをはじめる
        </BaseButton>
      </section>
      <section className="questions">
        <div className="questions-inner">
          <p className="questions-title">よくあるご質問</p>
          <p className="questions-subtitle">FAQ</p>
          <div className="questions-contents">
            {items.questions.map((item, index) => {
              return (
                <div className="content" key={index}>
                  <p className="q-num">Q{index + 1}</p>
                  <p className="question" dangerouslySetInnerHTML={{ __html: item.question }}></p>
                  <p className="answer" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="more">
          <i className="mbx-icon mbx-icon-Circle-large_Arrow-right"></i>
          <Link to="/faq">
            <p>もっと見る</p>
          </Link>
        </div>
      </section>
      <Footer />
      <TopModal modal={modalFirst} setModal={setModalFirst} contentsNumber={1} />
      <TopModal modal={modalSecond} setModal={setModalSecond} contentsNumber={2} />
    </section>
  );
}

export default Top;

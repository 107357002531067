import '@/stylesAdmin/header.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ImgLogoMw from '@/assets/images/common/admin_logo_mw_black.png';
import ImgLogo from '@/assets/images/common/admin_logo_gray.svg';
import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { AdminMenu } from '@/componentsAdmin/Layout/Menu';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { RootState } from '@/reduxAdmin';
import { ADMINApi, Offer } from '@/utils/api-client';

/**
 * https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/937
 * TODO : メニューのリンク設定
 */
export const AdminHeader = (): React.ReactElement => {
  const isLogin = useSelector((state: RootState) => state.admin_auth.isLogin);
  const userInfo = useSelector((state: RootState) => state.admin_auth.userInfo);
  const userIconThumbnail = userInfo?.user_info?.icon ? userInfo?.user_info.icon : ImgIcon;

  const mq = useMBXMediaQuery();

  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = useRef(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const spIconRef = useRef<HTMLDivElement>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [offers, setOffers] = useState<Offer[]>();

  const onClick = () => {
    setToggle(!toggle);
  };

  const handleClickEvent = (e: { target: EventTarget | null }) => {
    let icon = null;
    if (iconRef.current) icon = iconRef.current;
    if (spIconRef.current) icon = spIconRef.current;
    if (!icon) return;
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target as Node) &&
      icon &&
      !icon.contains(e.target as Node)
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  useEffect(() => {
    if (!offers) return;
    (async () => {
      const res = await new ADMINApi().getAdminOffers('99999', '0', '', '', [6, 7]);
      setOffers(res.data.offers);
    })();
  }, []);

  const isBadge = useMemo(() => {
    return offers?.some((offer) => offer.mw_status === undefined || offer.mw_status === 1);
  }, [offers]);

  const menu = [
    { badge: false, children: '求職者検索', url: '/admin/search' },
    { badge: false, children: '企業検索', url: '/admin/companies/search' },
    { badge: isBadge, children: 'マッチング履歴', url: '/admin/offers' },
  ];

  return (
    <header className="mbx-admin-header border-b border-gray-300">
      <div className="inner">
        <div className="flex items-center">
          <div className="w-140">
            <Link to="/admin/search">
              <img src={ImgLogo} />
            </Link>
          </div>
          <p className="h-24 ml-24 border-r border-gray-300"></p>
          {isLogin && (
            <div className="flex items-center ml-24">
              {/* API取得予定 */}
              <div className="w-24 h-24">
                <img
                  src={ImgLogoMw}
                />
              </div>
              <p className="mbx-typography--subhead_2 ml-6">{'株式会社マイナビワークス'}</p>
            </div>
          )}
        </div>
        {isLogin && (
          <>
            {mq.lg ? (
              <>
                <ul className="flex mbx-typography--subhead_1">
                  {menu.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={cn('relative', {
                          ['ml-64']: index !== 0,
                          ['text-red-700']: item.url === location.pathname,
                        })}
                      >
                        <Link to={item.url}>{item.children}</Link>
                        {item.badge && (
                          <p className="absolute top-0 -right-10 rounded-full w-6 h-6 bg-red-600"></p>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="w-32 h-32 mr-8 cursor-pointer" onClick={onClick} ref={iconRef}>
                  <img src={userIconThumbnail} alt="" />
                </div>
              </>
            ) : (
              <div ref={spIconRef}>
                <ButtonIconButton
                  type="gray"
                  size="large"
                  iconName={toggle ? 'Menu-close' : 'Menu'}
                  onClick={onClick}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div ref={menuRef}>
        <CSSTransition in={toggle} timeout={550} nodeRef={nodeRef}>
          <AdminMenu nodeRef={nodeRef} userInfo={userInfo} />
        </CSSTransition>
      </div>
    </header>
  );
};

import React, { useState ,useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed16 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if(isAuth){
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0016");
    if(!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g,"/")} 12:00:00`);
    const now = new Date;
    if(now > deadlineDate)setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className='event-contents'>
      <div className='event-detail'>
        < div className='md:-mx-16 sm:-mx-16'>
          < img className={style.img} src="../images/Top/event/event_0016_mv.jpg" width="978" height="504" alt="" />
        </div >
        <h1 className='mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center'>1/24（火）20:00 - <br />
          悩み多きWebディレクター必見！<br />キャリアの壁を超えるヒント<br />― 講義&nbsp;&amp;&nbsp;トークセッション ―</h1>
        { deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          < div className='mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left'>
            < ul className='text-16_28 sm:text-14_21'>
              < li className='flex mb-4'>
                <span> <i className='mbx-naviInpage-item-icon mbx-icon mbx-icon-Done'></i></span ><span>キャリアアップに悩んでいるWebディレクター</span>
              </li>
              <li className='flex mb-4'>
                <span> <i className='mbx-naviInpage-item-icon mbx-icon mbx-icon-Done'></i></span ><span>現役Webディレクターのマインドやスキルについての話を聞いてみたい方</span>
              </li>
              <li className='flex mb-4'>
                <span> <i className='mbx-naviInpage-item-icon mbx-icon mbx-icon-Done'></i></span ><span>自分以外のディレクターのキャリアについて話を聞きたい方</span>
              </li>
              <li className='flex mb-4'>
                <span> <i className='mbx-naviInpage-item-icon mbx-icon mbx-icon-Done'></i></span ><span>話を聞いて悩みをぶつけてみたい方</span>
              </li>
              <li className='flex'>
                <span> <i className='mbx-naviInpage-item-icon mbx-icon mbx-icon-Done'></i></span ><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          < h3 className='text-18_24 sm:text-14_21 font-bold'>イベント内容</h3>
          < div className='mt-30 sm:mt-16 mb-30'>
            < img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374" alt="" />
          </div >
          <p className='text-16_28 sm:text-14_21 mt-30'>
            ディレクターとしてのキャリアの中で、壁にぶち当たる瞬間はどなたにも1度はあるのではないでしょうか。その先には成長とキャリアの前進があるのだとは思うのですが、その壁の乗り越え方は人それぞれかと思います。私たちもセミナー行っている中で、参加者の皆さまからのお声として「自分以外のWebディレクターの話を聞いてみたい！」というご要望をいくつもいただいておりました。<br />
            <br />
            そこで今回、林純薬工業株式会社 試薬化成品部 デジタルマーケティンググループの田中奈々さん、大西淳一さんをお迎えし、ディレクターキャリアの中で立ちはだかった壁と乗り越えた経験をお話いただきます。化学メーカーとして事業会社のWebディレクターとしてのご経験はもちろん、お二人のこれまでのキャリアの制作会社でのご経験もお話いただけますので、様々な方向から経験談を聞くことができます。<br />
            現在Webディレクターの皆さまの「あるある！」「私も同じことで悩んでいる！」という共感必至のセミナーになっています。皆さんの視界が次の日から少しでも晴れればいいなと思い企画いたしました。当日は、質疑応答のお時間も設けますので、セミナーの内容踏まえてご質問なども是非お待ちしております。<br />
            <br />
            興味がある方は是非、お早めにご応募ください。
          </p>
        </section>
        <section className='mt-80 sm:mt-55'>
          < h3 className='text-18_24 sm:text-14_21 font-bold'>参加者の声</h3>
          < div className='mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left'>
            < ul className='text-16_28 sm:text-14_21'>
              < li className='flex mb-4'>
                <span>・</span > <span>自分以外のディレクターの悩みと、どうやって抜け出したのか実例を聞けてよかった。<br />（28歳 制作会社 Webディレクター5年目）</span>
              </li >
              <li className='flex mb-4'>
                <span>・</span><span>Webディレクターとして働いている人からの話が聞けてとても参考になった。<br />
                  （27歳 Webディレクター4年目）</span>
              </li>
              <li className='flex mb-4'>
                <span>・</span > <span>質疑応答で聞きたいことが聞けて、思考がクリアになった。 <br />（30歳 事業会社 Webディレクター）</span>
              </li>
            </ul >
          </div >
        </section >
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className='instructor-photo'>
              < img src="../images/Top/event/event_0016_prof-02.jpg" alt="" />
            </div >
            <dl className='instructor-prof sm:mt-16'>
              <dt className='instructor-name text-16_28 font-bold'>
                田中 奈々（NANA TANAKA）
              </dt >
              <dd className='instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12'>
                林純薬工業株式会社<br />
                試薬化成品部 デジタルマーケティンググループ<br />
                グループ長補佐
              </dd>
              <dd className='instructor-career text-16_28 sm:text-14_21'>
                プロモーション企画から動画配信までを担当するクリエーター兼ディレクターとして、出版社やデザイン会社での経験を積む。結婚後、フリーランスに転向し、制作活動だけでなく、大阪市の芸術活動促進事業の講師、子育てママを対象としたマーケティング学習支援サポーターとして活躍。<br />
                子育てを機に制作会社に復職し、大手電子メーカー企業のWebディレクターとして企画や進行管理を担当。現在は、林純薬工業株式会社に入社し、法人向けECサイトを中心としたセールスプロモーションから、BizOps業務まで幅広く携わる。
              </dd >
            </dl >
          </div >
          <div className='event-instructor box-line sm:p-16'>
            <div className='instructor-photo'>
              <img src="../images/Top/event/event_0016_prof-01.jpg" alt="" />
            </div>
            <dl className='instructor-prof sm:mt-16'>
              <dt className='instructor-name text-16_28 font-bold'>
                大西 淳一（JUNICHI ONISHI）
              </dt >
              <dd className='instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12'>
                林純薬工業株式会社<br />
                試薬化成品部 デジタルマーケティンググループ<br />
                アシスタントマネージャー
              </dd>
              <dd className='instructor-career text-16_28 sm:text-14_21'>
                大学卒業後、販売職、営業職を経て、Web業界へ転職。制作会社2社でWebディレクターとして勤務し、サイト制作に留まらずアプリ制作やSNS運用といった幅広い案件に携わった後、メーカーでのECチームリーダー、外資系企業でのデータ分析業務を経験。<br />
                現職では、自社運営のECサイトのリニューアルにおけるPMを務めながら、現サイトの分析改善やグループの体制整備、メンバー育成等にも携わっている。大阪府出身。
              </dd >
            </dl >
          </div >
        </section >
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>当日スケジュール</h2>
          <table className='index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12'>
            <tbody>
              <tr>
                <th scope="row" className='bg-gray-50 font-normal whitespace-nowrap' rowSpan={0}>
                  <span className='sm:block'>20：00</span><span className=' sm:block'>～</span><span className='sm:block'>21：20</span>
                </th>
                <td>
                  <ul className='text-16_28 sm:text-14_21'>
                    <li className='flex'><span>・</span><span>自己紹介</span></li>
                    <li className='flex'><span>・</span><span>制作会社編①：未経験からウェブディレクターになった時の苦労話</span></li>
                    <li className='flex'><span>・</span><span>制作会社編②：ウェブディレクター3年目 言われなければ気づけなかった視点の話</span></li>
                    <li className='flex'><span>・</span><span>事業会社編①：Webディレクションだけをしているわけではない話</span></li>
                    <li className='flex'><span>・</span><span>事業会社編②：事業会社のディレクターとして求められた3のこと</span></li>
                    <li className='flex'><span>・</span><span>パネルディスカッション：「ディレクターは今後も必要とされるか？」</span></li>
                  </ul >
                </td >
              </tr >
            </tbody >
          </table >
        </section >
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mb-64 sm:mb-48'>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left'>開催概要</h2>
          < table className='summary text-16_28 sm:text-14_21'>
            < tbody >
              <tr>
                <th scope="row" className='font-normal text-left'>開催日時</th>
                <td>2023/1/24（火）20:00～<br />
                  [入室開始] 19:50～</td>
              </tr>
              <tr>
                <th scope="row" className='font-normal text-left'>開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr >
              <tr>
                <th scope="row" className='font-normal text-left'>参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className='font-normal text-left'>対象者</th>
                <td>今後のキャリア形成について考えているWebディレクター</td>
              </tr >
              <tr>
                <th scope="row" className='font-normal text-left'>応募手順</th>
                <td>
                  <dl>
                    <dt className='mb-18 sm:mb-8'>1.参加申し込み</dt>
                    <dd className='mb-38 sm:mb-16'>MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className='textLink"
href = "https://matchbox.work/entry'>こちら</a>から）</dd>
                    <dt className='mb-18 sm:mb-8'>2.ご連絡</dt>
                    <dd className='mb-38 sm:mb-16'>マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className='mb-18 sm:mb-8'>3.開催当日</dt>
                    <dd> 当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          < p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p >
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};

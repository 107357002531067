import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationSnackBar } from '@/components/common/Notification/SnackBar';
import { notificationSuccess } from '@/redux/index';
import { State } from '@/redux/state';

/**
 * global stateの値検知で表示する汎用通知
 *
 * 仕様 :
 * - メッセージが表示されてから4秒後に自動非表示
 */
export const GlobalNotificationSuccessPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);
  const dispatch = useDispatch();

  const successMessage: string | null = useSelector((state: State) => state.successMessage);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // メッセージが表示されてから4秒後に自動非表示
    if (successMessage) {
      _.delay(onClick, 4000);
    }
  }, [successMessage]);

  const onClick = () => {
    dispatch(notificationSuccess(null));
  };
  return (
    <div className="sticky bottom-0 z-300">
      <NotificationSnackBar onClick={onClick} show={!!successMessage}>
        {successMessage}
      </NotificationSnackBar>
    </div>
  );
};

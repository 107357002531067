import '@/styles/pages/Account/Register.scss';

import { AxiosError } from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import PasswordForm from '@/components/common/Account/PasswordForm';
import RegisterSection from '@/components/common/Account/RegisterSection';
import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import {
    auth, notificationError, setDataLayerChangedBy, toggleLoading, userInfo
} from '@/redux/index';
import { State } from '@/redux/state';
import { RequestPassword, RequestTokenCheck, ResponseError, UserApi } from '@/utils/api-client';

type IFormInputs = {
  email: string;
  name: string;
  furigana: string;
  yyyy: string;
  mm: string;
  dd: string;
  tel: string;
  mc_preferred_change_date: string;
};

type IPasswords = {
  password: string;
  passwordAgain: string;
};

type Flow = {
  step1: boolean;
  step2: boolean;
  step3: boolean;
};

function Register(): React.ReactElement {
  const defaultValues = {
    name: '',
    furigana: '',
    email: '',
    yyyy: '',
    mm: '',
    dd: '',
    tel: '',
    mc_preferred_change_date: '',
  };
  const defaultFlow = {
    step1: false,
    step2: false,
    step3: false,
  };
  const [registerInput, setRegisterInput] = useState<IFormInputs>(defaultValues);
  const [pending, setPending] = useState<boolean>(false);
  const [flow, setFlow] = useState<Flow>(defaultFlow);
  const [parameter, setParameter] = useState<string>('');
  const [password, setPassword] = useState<IPasswords>({
    password: '',
    passwordAgain: '',
  });
  const dispatch = useDispatch();
  const loading = useSelector((state: State) => state.loading);
  const history = useHistory();
  const { push } = useContext(DataLayerContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const parameter = window.location.search.substring(1);
      if (!parameter) {
        setFlow({
          step1: true,
          step2: false,
          step3: false,
        });
        return;
      }
      setPending(true);
      setParameter(parameter);

      const token: RequestTokenCheck = {
        token: parameter,
      };
      try {
        await new UserApi().postApiV2UserRegistrationValidateToken(token);
        setFlow({
          step1: false,
          step2: false,
          step3: true,
        });
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        setPending(false);
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [flow]);

  const userEntryId = () => {
    let id = '';
    const date = new Date();
    id += ('' + date.getFullYear()).slice(-2);
    id += ('0' + (date.getMonth() + 1)).slice(-2);
    id += ('0' + date.getDate()).slice(-2);
    id += date.getHours();
    id += date.getMinutes();
    id += date.getSeconds();
    id += ('' + date.getTime()).slice(0, 2);
    id += _.random(1, 9);
    return id;
  };

  const accountCreate = async () => {
    if (loading) return;
    const payload = {
      mc_preferred_change_date: Number(registerInput.mc_preferred_change_date),
      user_entry_id: userEntryId(),
      use_direct: false,
      use_matchbox: true,
      user_type: 'user',
      email: registerInput.email,
      tel: registerInput.tel,
      name: registerInput.name,
      furigana: registerInput.furigana,
      birth_year: Number(registerInput.yyyy),
      birth_month: Number(registerInput.mm),
      birth_day: Number(registerInput.dd),
    };

    const data = [...Object.values(payload)];
    dispatch(toggleLoading(true));
    try {
      await new UserApi().postUserRegistration(...(data as [number | undefined]));
      setFlow({ step1: false, step2: true, step3: false });
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const registerComplete = async () => {
    if (loading) return;
    dispatch(toggleLoading(true));
    const postPassData: RequestPassword = {
      token: parameter,
      password: password.password,
      re_password: password.passwordAgain,
    };
    try {
      await new UserApi().postApiV2UserRegistrationPassword(postPassData);
      const user = await new UserApi().getUser();
      dispatch(setDataLayerChangedBy('register_complete'));
      dispatch(auth(true));

      if (user.data?.user_info) {
        dispatch(userInfo(user.data?.user_info));
      }

      push({
        event: 'entryComplete',
        actionType: 'entry_complete',
        actionName: 'entry_complete',
      });
      history.push('/mypage');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="register">
      <Page.Wrapper
        header={
          <>
            <HeaderTitle title={'アカウント登録'}>
              <></>
            </HeaderTitle>
          </>
        }
        disableDataLayerPush
      >
        <>
          {!pending && (
            <section>
              {flow.step1 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_1',
                  }}
                >
                  <RegisterSection
                    setRegisterInput={setRegisterInput}
                    onClick={accountCreate}
                    disabled={loading}
                  />
                </DataLayerPushContainer>
              )}
              {flow.step2 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_2',
                  }}
                >
                  <section className="mail-text w-624 m-auto text-center sm:w-full">
                    <p className="title mbx-typography--heading_2 mt-48 sm:mbx-typography--heading_3">
                      仮登録ありがとうございます
                    </p>
                    <p className="mbx-typography--body_1 mt-24">
                      ご登録いただいたメールアドレスをご確認ください。
                      メール内のリンクをクリックし、パスワードを設定の上、会員登録を完了してください。
                      なお、リンクの有効期限は発行後、24時間です。
                    </p>
                    <BaseButton size={'m'} theme={'link'} href={'/login'} className={'mt-64'}>
                      ログイン画面へ
                    </BaseButton>
                  </section>
                </DataLayerPushContainer>
              )}
              {flow.step3 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_3',
                  }}
                >
                  <PasswordForm
                    buttonText={'会員登録を完了'}
                    setPassword={setPassword}
                    onClick={registerComplete}
                    disabled={loading}
                  >
                    パスワードを設定の上、会員登録を完了してください。
                  </PasswordForm>
                </DataLayerPushContainer>
              )}
            </section>
          )}
        </>
      </Page.Wrapper>
    </section>
  );
}

export default Register;

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { AuthAdmin } from '@/componentsAdmin/Global/Auth';
import { AdminRoute } from '@/componentsDirect/Route/AdminRoute';
import {
    AdminCompany, AdminConditions, AdminConditionsEdit, AdminDirectConditions,
    AdminDirectConditionsEdit, AdminDirectSearchForm, AdminDirectSearchResult, AdminError,
    AdminIndex, AdminLogin, AdminSearchForm, AdminSearchResult, AdminUser, AdminUserOffers,
    CandidatesDetail, CandidatesTop, CareerHistoryPreview, CompanyDataBase, CompanyMain, CompanyNew,
    OffersTop, PortfolioPreview, PortfolioPreviewDetail, PortfolioPreviewProfile, ResumePreview
} from '@/pagesAdmin/';
import { store } from '@/reduxAdmin';

/**
 * 管理者用ページ ルート設定
 */
export const IndexAdmin = (): React.ReactElement => {
  // 指定パス以外のアクセスなら何も表示しないでアーリーリターン
  if (!/^\/admin\//.test(location.pathname)) return <></>;

  // ページ遷移時にページトップに戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <AuthAdmin>
        <BrowserRouter>
          {/** admin以下の場合に表示 */}
          <Route path="/admin">
            <Switch>
              {/** Index */}
              <Route exact path="/admin/index" component={AdminIndex} />

              {/** Users 求職者詳細 */}
              <AdminRoute exact path="/admin/users/:matchbox_id" component={AdminUser} />
              <AdminRoute exact path="/admin/users/:matchbox_id/notes" component={AdminUser} />
              <AdminRoute exact path="/admin/users/:matchbox_id/account" component={AdminUser} />
              <AdminRoute
                exact
                path="/admin/users/:matchbox_id/offers"
                component={AdminUserOffers}
              />
              <AdminRoute
                exact
                path="/admin/users/:matchbox_id/pickups"
                component={AdminUserOffers}
              />

              {/** Search */}
              <AdminRoute exact path="/admin/search" component={AdminSearchForm} />
              <AdminRoute exact path="/admin/search/result" component={AdminSearchResult} />
              <AdminRoute exact path="/admin/direct/search" component={AdminDirectSearchForm} />
              <AdminRoute
                exact
                path="/admin/direct/search/result"
                component={AdminDirectSearchResult}
              />

              {/** Conditions */}
              <AdminRoute exact path="/admin/conditions/:id(\d+)" component={AdminConditionsEdit} />
              <AdminRoute
                exact
                path="/admin/direct/conditions/:id(\d+)"
                component={AdminDirectConditionsEdit}
              />
              <AdminRoute exact path="/admin/conditions" component={AdminConditions} />
              <AdminRoute exact path="/admin/direct/conditions" component={AdminDirectConditions} />

              {/** Company Search */}
              <AdminRoute exact path="/admin/companies/search" component={AdminCompany} />
              <AdminRoute exact path="/admin/companies/search/result" component={AdminCompany} />

              {/** Companies */}
              <AdminRoute
                exact
                path="/admin/companies/:company_id/candidates/"
                component={CandidatesTop}
              />
              <AdminRoute
                exact
                path="/admin/companies/:company_id/candidates/:offer_id"
                component={CandidatesDetail}
              />
              <AdminRoute
                exact
                path="/admin/companies/:company_id/database"
                component={CompanyDataBase}
              />

              {/* 企業詳細/社内メモ/企業アカウント操作はCompanyMainでルーティング */}
              <AdminRoute path="/admin/companies/:company_id" component={CompanyMain} />

              {/* Company */}
              <AdminRoute exact path="/admin/company/new" component={CompanyNew} />

              {/* マッチング履歴 */}
              <AdminRoute exact path="/admin/offers" component={OffersTop} />

              {/** Portfolio Preview */}
              <AdminRoute exact path="/admin/portfolio/:matchbox_id" component={PortfolioPreview} />
              <AdminRoute
                exact
                path="/admin/portfolio/:matchbox_id/profile"
                component={PortfolioPreviewProfile}
              />
              <AdminRoute
                exact
                path="/admin/portfolio/:matchbox_id/:work_id"
                component={PortfolioPreviewDetail}
              />

              {/** Career History Preview */}
              <AdminRoute exact path="/admin/cv/:matchbox_id" component={CareerHistoryPreview} />

              {/** Resume Preview */}
              <AdminRoute exact path="/admin/resume/:matchbox_id" component={ResumePreview} />

              {/** Auth */}
              <AdminRoute publicRoute exact path="/admin/login" component={AdminLogin} />

              {/** General */}
              <Route component={AdminError} />
            </Switch>
          </Route>
        </BrowserRouter>
      </AuthAdmin>
    </Provider>
  );
};

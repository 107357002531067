import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin, setApplication } from '@/reduxAdmin/modules/directAuth';
import { Redirect, Route } from 'react-router-dom';
import { Expired } from '@/pagesDirect/Expired';
import { useUser } from '@/hooksDirect/useUser';

import { RootState } from '@/reduxAdmin';
import { DirectApi, ResponseError } from '@/utils/api-client/';

type TProps = {
  path: string;
  exact?: boolean;
  component: React.ComponentType;
};

/**
 * ログイン状態でuser.user_typeがdirect_*の場合に企業ページを表示
 * 未ログインの場合はログインページへ
 * またログインページはサーバー側でIP制限されているので一般ユーザーには表示されない。
 *
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#tag/user_info_model
 */
export function DirectRoute({
  path,
  exact = false,
  component,
}: TProps): React.ReactElement {
  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const applicationStep = useSelector((state: RootState) => {
    return state.direct_auth.applicationStep;
  });
  const dispatch = useDispatch();
  const { getCompanyUser } = useUser();
  const API = new DirectApi();
  const page = isLogin ? component : Expired;

  useEffect(() => {
    (async () => {
      try {
          const token = window.location.pathname.split('/').reverse()[0];
          const demoToken = await API.apiV2DirectCheckTokenGet(token);
          // TODO api.tsがレスポンスにstepが含まれる前の状態なので更新されたasを削除する
          const data = demoToken.data as {result: string, step: number};
          dispatch(setLogin(true));
          dispatch(setApplication(data.step));
          // KARTEの接客サービスの表示を制御するイベントタグ
          if(data.step === 2) {
            krt('send', 'showCTA', {display: true});
          } else {
            krt('send', 'showCTA', {display: false});
          };
        } catch (e) {
          // 有効期限切れ
          dispatch(setLogin(false));
          // セッションをクリアする
          API.apiV2DirectDemoRegistrationDelete();
          krt('send', 'showCTA', {display: false});
        }
    })();
  }, [path, applicationStep]);

  // undefの場合以外のisLoginの値が切り替わるたびに処理する
  useEffect(() => {
    if (isLogin === undefined) return;
    getCompanyUser();
  }, [isLogin]);

  // ページ遷移時にページトップに戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (isLogin === undefined) return <></>;

  return (
    <Route path={path} exact={exact} component={page}></Route>
  );
}
